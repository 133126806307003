import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
import WaFloatButton from './WAFloatButton';
import app from '../firebase';
const db = app.firestore();
export default function NuevoDemoTag() {

    // States
    const [state, setState] = useState({})
    const [showConfirmDemo, setShowConfirmDemo] = useState(false)
    const [demoTagData, setDemoTagData] = useState({})
    // Handlers
    const handleChangeText = (name, value) => {
        setState({ ...state, [name]: value })
    }

    // Functions
    const timenow = () => {
        var now = new Date()
        return now.toLocaleDateString()
    }

    const saveDemoTag = async () => {

        let generatedUUID = uuidv4().toString().slice(0, 6)

        let idTag = generatedUUID

        state["tagId"] = idTag
        state["isActive"] = true
        state["created_at"] = timenow()


        await db.collection("demoTags").add(state).then((docRef) => {
            setDemoTagData({
                "tagId": idTag,
            })
            setShowConfirmDemo(true)
        })

    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="container minHeight-100 p-4 d-flex justify-content-center align-items-center flex-column new-tag-bg">
            <WaFloatButton></WaFloatButton>
            {
                showConfirmDemo == false ?
                    <>

                        <div className="my-4 bg-light shadow w-50 mw-auto text-center p-2">
                            <span className='display-6 fw-bold text-warning'>¡Genial, comencemos!</span>
                        </div>

                        <div className="shadow w-50 mw-auto p-4 bg-light">
                            <div className="form-group my-2">
                                <label>¿Cuál es tu nombre?</label>
                                <input type="text" onChange={(e) => handleChangeText("usuarioNombre", e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group my-2">
                                <label>¿Cuál es el nombre de tu mascota?</label>
                                <input type="text" onChange={(e) => handleChangeText("nombreMascota", e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group my-2">
                                <label>¿A qué numero deberemos contactarte?</label>
                                <input type="text" onChange={(e) => handleChangeText("telefono", e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group my-2">
                                <label>¿A qué correo te deben llegar las alertas?</label>
                                <input type="email" onChange={(e) => handleChangeText("correo", e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group my-2">
                                <label>Recompenza ofrecida para quien te ayude a encontrar a <strong>{state.nombreMascota == undefined ? "" : state.nombreMascota}</strong></label>
                                <input type="text" onChange={(e) => handleChangeText("recompenza", e.target.value)} className="form-control" />
                            </div>
                            <div className="form-group my-2">
                                <label>Por último {state.usuarioNombre == undefined ? "" : state.usuarioNombre + ","} ¿Cuánto quieres a <strong>{state.nombreMascota == undefined ? "" : state.nombreMascota}</strong></label>
                                <select className='form-select' onChange={(e) => handleChangeText("cuantoQuieresMascota", e.target.value)}>
                                    <option value="">----</option>
                                    <option value="Poco">Poco</option>
                                    <option value="Mucho">Mucho</option>
                                    <option value="Muchísimo">Muchísimo</option>
                                    <option value="Infinito">Infinito</option>
                                </select>
                            </div>

                            <div className="my-4">
                                <button className='w-100 btn btn-full-rounded btn-warning text-dark fw-bold' onClick={() => saveDemoTag()}>Crear TagDemo</button>
                            </div>
                            <div className="my-4">
                                <span className="text-dark fs-10">Al crear tu <strong>TagDemo</strong> estás aceptando nuestro <a href="/privacidad" className='text-dark'>Aviso de Privacidad</a>, asimismo nuestros  <a href="/terminos">Términos y Condiciones</a></span>
                            </div>
                            <div className="my-4 text-center">
                                <Link to="/" className="text-dark fs-6 text-decoration-underline">Regresar</Link>
                            </div>
                        </div>
                    </>
                    :
                    <div></div>
            }





            {
                showConfirmDemo == true ?

                    <div className="shadow w-50 mw-auto p-4 bg-light m-4">
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="happy-dog-img"></div>
                        </div>
                        <div className="form-group my-2 text-center">
                            <span className='fw-bold text-dark fs-2'>¡Listo, este es tu TagDemo!</span>
                            <div className="my-4 text-center">
                                <Link target={"_blank"} to={`/demoPet/${demoTagData.tagId}`} className='btn btn-full-rounded btn-warning fw-bold text-dark fs-2'>Ver tag ➤</Link>
                            </div>
                            <div className="my-4 text-center">
                                <Link to="/" className="text-dark fs-6 text-decoration-underline">Volver a IDPETSQR</Link>
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
            }

        </div>
    )
}
