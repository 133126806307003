/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
import FileUploader from '../FileUploader';
const db = app.firestore();


const AllergyForm = () => {

    const [alergia, setAlergia] = useState('');
    const [reaccion, setReaccion] = useState('');
    const [ultimaActualizacion, setUltimaActualizacion] = useState('');
    const [remedio, setRemedio] = useState('');
    const [notasAlergias, setNotasAlergias] = useState('');


    const savePet = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj)


        pet_obj["alergia"] = alergia;
        pet_obj["reaccion"] = reaccion;
        pet_obj["notasAlergias"] = notasAlergias;
        pet_obj["ultimaActualizacion"] = ultimaActualizacion;
        pet_obj["remedio"] = remedio;


        localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
        window.location.href = "/tag_reward_form";


    }
    return (


        <div className='new-tag-bg container-fluid minHeight-100 justify-content-center d-flex align-items-center'>
            <div className='card h-100 w-auto shadow p-4'>
                <div className='container'>
                    <div className="d-flex justify-content-center my-4">
                        <span className='text-dark fw-bold display-6'>
                            Alergias
                        </span>
                    </div>

                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Alergia' onChange={(e) => setAlergia(e.target.value)}></input>

                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Reacción' onChange={(e) => setReaccion(e.target.value)} ></input>


                    <label>Última actualización</label>
                    <input type="date" class=" form-control mb-2 mt-2" onChange={(e) => setUltimaActualizacion(e.target.value)} ></input>


                    <label>Remedio</label>
                    <input type="text" class=" form-control mb-2 mt-2" onChange={(e) => setRemedio(e.target.value)}></input>


                    <textarea type="text" class=" form-control mb-2 mt-2" placeholder='Notas' onChange={(e) => setNotasAlergias(e.target.value)} ></textarea>


                </div>

                <div className='d-flex align-items-center flex-column justify-content-center'>

                    <button onClick={() => savePet()} className="btn btn-warning fw-bold text-light btn-full-rounded w-50 my-4">Siguiente</button>
                    <Link to={"/tag_reward_form"} className="linkView">Omitir</Link>

                </div>
            </div>
        </div>
    );



}


export default AllergyForm;