/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';

// Importing sweet alert
import Swal from 'sweetalert2';


import FilterResults from 'react-filter-search';


import firebase from 'firebase/compat/app';

import app from '../../firebase';
const db = app.firestore();

export default function CentroMensajes() {

    const fieldValue = firebase.firestore.FieldValue;


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false
    })



    const [state, setState] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [messages, setMessages] = useState([])
    const [users, setUsers] = useState([])
    const [visibleSentMessage, setVisibleSentMessage] = useState(false)
    const [messageParams, setMessageParams] = useState("")
    const [currentMessageUser, setCurrentMessageUser] = useState({})
    const [showModalMessages, setShowModalMessages] = useState(false)
    const [currentMessage, setCurrentMessage] = useState({})
    const [parametro, setParametro] = useState('');

    const [messageState, setMessageState] = useState({})

    // Handlers
    const handleChangeText = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const handleMessageTextChange = (name, value) => {
        setMessageState({ ...messageState, [name]: value })
    }

    const handleSearchInputChange = e => {
        setMessageParams(e.target.value)
    }

    const handleSelectChange = (myStringifyObject) => {
        const userObject = JSON.parse(myStringifyObject);
        setCurrentMessageUser(userObject)
        console.log(userObject)
    }

    const hanldeCurrentMessageModal = (message) => {
        setCurrentMessage(message)
        setShowModalMessages(true)
    }
    const handleSearchUserInputChange = e => {
        setParametro(e.target.value)
    }

    // Ends handlers

    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }
    const sendMessage = () => {

        state["sent_at"] = timenow()

        state["userId"] = currentMessageUser.userId
        state["user"] = `${currentMessageUser.userName} (${currentMessageUser.userEmail})`

        db.collection("messages").add(state).then((response) => {
            Toast.fire({
                icon: 'success',
                title: 'Mensaje enviado correctamente'
            })
            setVisibleSentMessage(false)
        }).catch((error) => {
            Toast.fire({
                icon: 'error',
                title: 'Error al enviar mensaje, intenta nuevamente'
            })
            window.location.reload()
        })
    }

    // Functions messages
    const sendResponse = () => {
        messageState["sent_at"] = timenow()
        messageState["userType"] = "admin"
        db.collection('messages').doc(currentMessage.id).update({ responses: fieldValue.arrayUnion(messageState) }, { merge: true }).then(() => {
            db.collection("messages").doc(currentMessage.id).update({
                "hasResponseAdmin": true,
                "hasResponseClient": false
            }).then(() => {
                Toast.fire({
                    icon: 'success',
                    title: 'Mensaje enviado correctamente'
                })
                setShowModalMessages(false)
            })
        }).catch((error) => {
            Toast.fire({
                icon: 'error',
                title: 'No se pudo enviar el mensaje, intente nuevamente'
            })
        });
    }

    const deleteMessage = (id) => {


        if (window.confirm("¿Estás seguro de eliminar este mensaje?")) {
            db.collection("messages").doc(id).delete().then(() => {
                Toast.fire({
                    icon: 'success',
                    title: 'Mensaje eliminado correctamente'
                })
            }).catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: 'No se pudo eliminar el mensaje, intente nuevamente'
                })
            });
        } else {
            return false
        }

    }


    useEffect(() => {

        db.collection("messages")
            .orderBy("sent_at", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []

                querySnapshot.docs.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });
                setMessages(docs)
                setIsLoading(false)
            })

        db.collection("usuarios").onSnapshot((querySnapshot) => {
            const docs = []
            querySnapshot.docs.forEach(doc => {
                docs.push({ ...doc.data(), id: doc.id })
            })
            setUsers(docs)
        })
    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="my-4">
                    <Link to="/ad/dashboard" className='text-decoration-none text-dark fs-5'>◄ Regresar</Link>
                </div>
                <div className="my-4">
                    <span className='display-5'>Centro de mensajes</span>
                </div>

                <div className="my-4">
                    <button className='btn btn-dark' onClick={() => setVisibleSentMessage(true)}>Nuevo mensaje</button>
                </div>

                {
                    visibleSentMessage == true ?

                        <div className="fade-div container-fluid border my-4 shadow">
                            <div className="row row-cols-2 py-4">
                                <div className="col col-md-6">
                                    <div className="form-group my-4">
                                        <label>Selecciona el usuario</label>
                                        <div className='my-2'>
                                            <input placeholder="Buscar usuario" value={parametro} onChange={handleSearchUserInputChange} className="form-control inputBuscarPedido"></input>
                                        </div>



                                        <select className='form-select' onChange={(e) =>
                                            handleSelectChange(e.target.value)
                                        }>
                                            <option value="" selected>---</option>
                                            {
                                                <FilterResults
                                                    value={parametro}
                                                    data={users}
                                                    renderResults={results => (

                                                        results.length == 0 ?
                                                            <option value="" selected>---</option>
                                                            :
                                                            results.map((user) => (
                                                                <option value={JSON.stringify(user)}> {user.userName} ({user.userEmail})</option>
                                                            ))
                                                    )}
                                                />
                                            }

                                        </select>
                                    </div>

                                </div>
                                <div className="col col-md-6">
                                    <div className="form-group my-4">
                                        <label>Escribe el mensaje</label>
                                        <input onChange={(e) => handleChangeText("message", e.target.value)} type="text" placeholder='Mensaje' className='form-control' />
                                    </div>

                                    <div className="form-group my-4 d-flex flex-row-reverse">
                                        <button className='btn btn-dark m-2 w-50' onClick={() => sendMessage()}>Enviar</button>
                                        <button className='btn btn-secondary m-2 w-50' onClick={() => setVisibleSentMessage(false)}>Cancelar</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        :
                        <div></div>
                }




                {
                    isLoading == true ?
                        <div className="spinner-border text-warning" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>


                        :


                        <div className="table-responsive border shadow p-4">

                            <div>
                                <input placeholder="Buscar mensajes" value={messageParams} onChange={handleSearchInputChange} className="shadow form-control my-4"></input>
                            </div>
                            <FilterResults
                                value={messageParams}
                                data={messages}
                                renderResults={results => (

                                    <table className='p-2'>
                                        <thead>
                                            <tr>
                                                <th scope="col">Enviado</th>
                                                <th scope="col">Usuario</th>
                                                <th scope="col">Mensaje</th>
                                                <th scope="col">Respuesta</th>
                                                <th scope="col">Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                results.length == 0 ?
                                                    <span>Sin mensajes</span>

                                                    :

                                                    results.map((message, index) => (
                                                        <tr key={index} className="border">
                                                            <td>{message.sent_at}</td>
                                                            <td>{message.user}</td>
                                                            <td>{message.message}</td>
                                                            <td>
                                                                <button className='btn btn-outline-dark  my-2 w-100 btn-sm' onClick={() => hanldeCurrentMessageModal(message)}>Ver

                                                                    {
                                                                        message.hasResponseClient == true ?
                                                                            <span class="badge rounded-pill bg-danger">+1</span>

                                                                            :
                                                                            ""
                                                                    }

                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className='btn btn-outline-danger w-100 my-2 btn-sm' onClick={() => deleteMessage(message.id)}>Eliminar</button>
                                                                {/* </td>te amo muchisimo mi amorcito enojon  */}
                                                            </td>
                                                        </tr>
                                                    ))

                                            }

                                        </tbody>
                                    </table>

                                )}
                            />



                        </div>

                }


            </div>
            {
                showModalMessages == true ?
                    <div className="container-full-space fade-div">
                        <div className="table-responsive m-modal-body w-100">

                            <div className="m-2 d-flex flex-row-reverse" onClick={() => setShowModalMessages(false)}>
                                <span className='fs-4 c-pointer text-center'>x</span>
                            </div>


                            <div className="my-4 w-100 h-auto d-flex flex-column-reverse">

                                <div className="table-responsive">
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            <div className="p-2">
                                                <div className="my-2">
                                                </div>


                                                {
                                                    currentMessage.userType == "client" ?

                                                        <div className="response-client my-4 d-flex flex-row-reverse">
                                                            <div className='p-1'>
                                                                <p className='text-align-right'>{currentMessage.message}</p>
                                                                <p className='fs-12 gray-light-text text-align-right'>{currentMessage.sent_at}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="response-admin my-2 p-2">
                                                            <p>{currentMessage.message}</p>
                                                            <p className='fs-12 text-dark'>{currentMessage.sent_at}</p>
                                                        </div>
                                                }

                                                {
                                                    currentMessage.responses !== undefined ?

                                                        currentMessage.responses.map((response, index) => (

                                                            response.userType == "client" ?
                                                                <div className="response-client my-4 d-flex flex-row-reverse">
                                                                    <div className='p-1'>
                                                                        <p className='text-align-right'>{response.response}</p>
                                                                        <p className='fs-12 gray-light-text text-align-right'>{response.sent_at}</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="response-admin my-4 p-2">
                                                                    <p>{response.response}</p>
                                                                    <p className='fs-12 text-dark'>{response.sent_at}</p>
                                                                </div>

                                                        ))
                                                        : ""
                                                }


                                            </div>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div className="p-2 w-100">

                                <div className="my-4 d-flex">
                                    <input type="text" placeholder='Mensaje' onChange={(e) => handleMessageTextChange("response", e.target.value)} className="m-1 form-control" />
                                    <button onClick={() => sendResponse()} className="m-1 btn btn-full-rounded bg-warning">➤</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    false
            }

        </>
    )
}
