/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';

import FilterResults from 'react-filter-search';

// Importing sweet alert
import Swal from 'sweetalert2';

import app from '../../firebase';
const db = app.firestore();


export default function Productos() {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false
    })



    const [productos, setProductos] = useState([])
    const [parametro, setParametro] = useState('');
    const [state, setState] = useState({})


    const handleInputChage = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const handleSearchInputChange = e => {
        setParametro(e.target.value)
    }

    // Funcitons
    const saveProduct = (e) => {
        e.preventDefault()
        state["created_at"] = timenow()
        db.collection("productos").add(state).then(() => {
            Toast.fire({
                icon: 'success',
                title: 'Producto registrado'
            })

            document.getElementById("formNuevoProducto").reset()
        }).catch((error) => {
            Toast.fire({
                icon: 'error',
                title: 'Error al registrar producto: ' + error
            })
        })
    }
    const deleteProduct = (productId) => {

        if (window.confirm("¿Eliminar producto?")) {
            db.collection("productos").doc(productId).delete().then(() => {
                Toast.fire({
                    icon: 'success',
                    title: 'Producto eliminado'
                })
            }).catch((error) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Error al eliminar el producto: ' + error
                })
            })
        } else {
            console.log(false)
        }

    }

    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }

    useEffect(() => {
        db.collection("productos").orderBy("created_at", "asc").onSnapshot((querySnapshot) => {
            const docs = []
            querySnapshot.docs.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id })
            })
            setProductos(docs)
        })
    }, [])



    return (
        <div className="container-fluid">
            <div className='d-flex justify-content-between align-items-center'>
                <div className="my-4">
                    <div>
                        <Link to="/ad/dashboard" className='text-dark fs-6'>Regresar</Link>
                    </div>
                    <span className='text-dark fw-bold display-6'>Idpets QR | Productos</span>
                </div>
            </div>


            {/* Modal registrar nuevo producto */}
            <div className="fade-div d-flex justify-content-center">

                <form id="formNuevoProducto" className='row align-self-center shadow w-80 p-2'>

                    <div className="col">
                        <div className="form-group">
                            <label>Nombre</label>
                            <input type="text" onChange={(e) => handleInputChage("nombre", e.target.value)} className="form-control" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label>Url de imagen</label>
                            <input type="text" onChange={(e) => handleInputChage("urlImagen", e.target.value)} className="form-control" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label>Link de compra</label>
                            <input type="text" onChange={(e) => handleInputChage("urlCompra", e.target.value)} className="form-control" />
                        </div>
                    </div>
                    <div className="w-100 my-2">
                        <div className="d-flex flex-row-reverse">
                            <button className='btn btn-primary btn-full-rounded' onClick={saveProduct}>Registrar</button>
                        </div>
                    </div>
                </form>
            </div>

            {/* Fin modal registrar nuevo producto */}

            <div className='mt-4 d-flex justify-content-center'>
                <div className='d-flex flex-column justify-content-center shadow w-80'>
                    <div className='my-4'>
                        <div className='p-2'>
                            <div className="my-2 d-flex align-items-center justify-content-between">
                                <span className='text-dark fw-bold fs-4'>Tags registrados</span>
                            </div>
                            <div className='my-2'>
                                <input placeholder="Buscar tagId" value={parametro} onChange={handleSearchInputChange} className="form-control inputBuscarPedido"></input>
                            </div>
                        </div>

                        <div className="p-4 table-responsive table-container-h-50 w-100">

                            <FilterResults
                                value={parametro}
                                data={productos}
                                renderResults={results => (

                                    <div className="table-responsive hideScrollBar d-flex justify-content-center">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Fecha de creación</th>
                                                    <th>Nombre de producto</th>
                                                    <th>Url de imagen</th>
                                                    <th>Link de compra</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    results.map((producto, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">{producto.created_at}</th>
                                                            <td className='text-break overflow-wrap'>{producto.nombre}</td>
                                                            <td><a className='linkView' target="_blank" href={producto.urlImagen}>Ver</a></td>
                                                            <td><a className='linkView' target="_blank" href={producto.urlCompra}>Ver</a></td>
                                                            <td><button class="btn btn-danger w-100 btn-full-rounded" onClick={() => deleteProduct(producto.id)}>Eliminar</button></td>
                                                        </tr>
                                                    ))
                                                }


                                            </tbody>
                                        </table>
                                    </div>


                                )}
                            />
                        </div>

                    </div>
                </div>
            </div>





        </div>
    )
}
