/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../Navigation.css';


/**Transitions */

import app from '../firebase';
const db = app.firestore();






const ResetPassword = () => {



    let navigate = useNavigate()
    const [emailUser, setEmailUser] = useState('')

    const handleReset = () => {

        if (emailUser != "") {
            app.auth().sendPasswordResetEmail(emailUser).then((response) => {
                alert("Correo de recuperación enviado");

                window.location.reload()
            }).catch((error) => {

                if (error.code == "auth/user-not-found") {
                    alert("El usuario que ingresaste no existe verifica que esté escrito el correo correctamente")
                } else {
                    alert("Hubo un error interno, prueba nuevamente más tarde");
                }
                console.log(error.code)
            })
        }
    }

    return (


        <>
            <div className='row' id='row-pc'>

                <div className="col col-6 background_reset"></div>
                <div className="col col-6 login-section">

                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='m-4'>
                            <h2 className='fw-bold'>Recuperar contraseña</h2>
                            <div className='mt-4'>
                                <div className='form-group'>
                                    <label className='mt-3'>Ingresa tu correo y te enviaremos las instrucciones para recuperar acceso a IDPETSQR</label>
                                    <input type="email" class="form-control mb-2 mt-2" placeholder='Correo electrónico' onChange={(e) => setEmailUser(e.target.value)} ></input>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row flex-column justify-content-center container-group mb-3'>

                            <div className=" w-100 d-flex justify-content-center">
                                <button onClick={() => handleReset()} style={{ width: "300px" }} className="btn btn-warning fw-bold my-4">Enviar</button>
                            </div>

                            <div className=" w-100 d-flex justify-content-center">
                                <Link to={"/signup"} className="linkView mt-2">¿No tienes cuenta? Crea una aquí</Link>
                            </div>


                            <div className=" w-100 d-flex justify-content-center">
                                <Link to={"/"} className="linkView mt-2" style={{ color: "#00D9C0" }}>Volver al inicio</Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div id="row-mb">
                <div className='row container d-flex new-tag-bg minHeight-100 align-items-center justify-content-center'>
                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='my-2'>
                            <h2 className='fw-bold my-3'>Recuperar contraseña</h2>
                            <div className='mt-4'>
                                <div className='form-group'>
                                    <label className='mt-3'>Ingresa tu correo electrónico</label>
                                    <input type="email" class="form-control mb-2 mt-2" placeholder='Correo electrónico' onChange={(e) => setEmailUser(e.target.value)} ></input>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                            <button onClick={() => handleReset()} className="btn btn-warning fw-bold my-4">Enviar</button>

                            <div className="text-center">
                                <div className='my-2'>
                                    <Link to={"/signup"} className="linkView mt-2">¿No tienes cuenta? Crea una aquí</Link>
                                </div>
                                <div className='my-2'>
                                    <Link to={"/"} className="linkView mt-2">Volver al inicio</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );



}


export default ResetPassword;