import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../index.css'

export default function Parking() {
    return (
        <div className="main-container">
            <div className='d-flex flex-row justify-content-center mt-4'>
                <img className='img-feature' src="https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/archivos%2FWhatsApp%20Image%202022-04-25%20at%209.46.14%20PM.jpeg?alt=media&token=31c371e6-db33-4dd6-8319-053bd391004c" />
            </div>
        </div>

    )
}
