import React, { useEffect, useState } from 'react'
import app from '../../firebase';
import { Link } from 'react-router-dom';
import FilterResults from 'react-filter-search';
import TableSortTags from '../TableSortTags';

const db = app.firestore();


export default function Tags() {
    const [loading, setLoading] = useState(false)
    const [parametro, setParametro] = useState('');
    const [tagsIds, setTagsIds] = useState([])
    const [activeTags, setActiveTags] = useState([])
    const [inactiveTags, setInactiveTags] = useState([])
    const [optionsTags, setOptionsTags] = useState([])


    const saveIndividualTag = async () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const formattedToday = dd + '/' + mm + '/' + yyyy;

        let idTag = prompt("Ingrese el tag ID");

        // Verificar si ya existe un documento con el tagId proporcionado
        const tagExists = await db.collection("tags").where("tagId", "==", idTag).get();

        if (idTag) {
            if (tagExists.docs.length > 0) {
                alert("¡El tagId ya está registrado!");
            } else {
                if (window.confirm("¿Registrar el tag?")) {
                    await db.collection("tags").add({
                        "crated_at": formattedToday,
                        "petId": "",
                        "tagId": idTag,
                        "isActive": false
                    }).then(() => {
                        alert("Tag guardado correctamente");
                    });
                }
            }
        }

    };


    const [limit, setLimit] = useState(10);




    useEffect(() => {
        setLoading(true)
        let localTagsIds = []
        const unsubscribe = db.collection('tags')
            .orderBy('crated_at', 'asc')
            .orderBy('isActive', 'desc')
            .onSnapshot((querySnapshot) => {


                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });
                setTagsIds(docs)
                localTagsIds = docs
                const activeTagsCount = docs.filter(doc => doc.isActive === true).length;
                const inactiveTagsCount = docs.filter(doc => doc.isActive === false).length;
                setActiveTags(activeTagsCount);
                setInactiveTags(inactiveTagsCount);
                setLoading(false)

                const adaptedData = docs.map(item => ({
                    value: item.id,
                    label: item.tagId
                }));

                setOptionsTags(adaptedData);
            });


        return () => unsubscribe(); // Detener la suscripción cuando el componente se desmonte

    }, []);
    return (
        <div className='container p-4'>
            <div>

                <div className="my-4">
                    <Link to="/ad/dashboard" className='bg-dark text-decoration-none text-white bg-white p-2 fs-5'>◄ Regresar</Link>
                </div>
                <div className="my-4">
                    <span className='display-5 fw-bold'>Tags</span>
                </div>

                <div className='mt-4 d-flex justify-content-center'>
                    <div className='d-flex flex-column justify-content-center shadow w-80'>
                        <div className='my-4'>
                            <div className='p-2'>
                                <span className='text-dark fw-bold fs-4'>Listado</span>
                                <div className="d-flex">
                                    <div className="m-4">
                                        <span className='text-dark fw-bold fs-6'>Tags Activos: <span className='green rounded p-1'>{activeTags}</span></span>
                                    </div>
                                    <div className="m-4">
                                        <span className='text-dark fw-bold fs-6'>Tags inactivos: <span className='text-light bg-danger rounded p-1'>{inactiveTags}</span></span>
                                    </div>
                                    <div className="m-4">
                                        <span className='text-dark fw-bold fs-6'>Tags generados: <span className='text-light bg-primary rounded p-1'>{tagsIds.length}</span></span>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end px-4">
                                    <button onClick={() => saveIndividualTag()} className='btn bg-dark text-decoration-none rounded-0 text-white bg-white fs-5'>
                                        Nuevo tag
                                    </button>
                                </div>
                            </div>

                            {
                                loading == true ?
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :


                                    <div className="p-4 table-responsive table-container-h-auto w-100">
                                        <TableSortTags limit={limit} tags={tagsIds} />
                                    </div>
                            }








                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
