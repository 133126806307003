/**React imports */
import React, { useEffect, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';

import { v4 as uuidv4 } from 'uuid';


/**Transitions */

import app from '../../firebase';
const db = app.firestore();


const AddTags = () => {
    // Navigation router
    let navigate = useNavigate();


    // Add tags lote


    const generateUuid = () => {
        let generatedUUID = uuidv4().toString().slice(0, 6)
        return generatedUUID;
    }
    const [numeroTags, setNumeroTags] = useState(0)

    const saveTags = async () => {
        if (numeroTags > 0) {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            alert("Almacenando tags, por favor, espere");
            for (let i = 0; i < numeroTags; i++) {
                await db.collection("tags").add({
                    "crated_at": today,
                    "petId": "",
                    "tagId": generateUuid(),
                    "isActive": false
                })
            }

            alert("Tags guardados");
            window.location.href = "/ad/dashboard";
        }else{
            alert("Necesitas agregar al menos 1 tag")
        }
    }




    useEffect(() => {
        let adminIdAuth = sessionStorage.getItem('adminId')
        if (!adminIdAuth) {
            navigate('/ad/login')
        }
    }, [])
    return (


        <div className='new-tag-bg container-fluid minHeight-100 justify-content-center d-flex align-items-center'>
            <div className='card h-50 shadow p-4'>
                <div className="my-4 text-center">
                    <h4 className='fw-bold'>Agregar tags por lote</h4>
                </div>
                <div className='form-group'>
                    <label className='mt-3'>¿Cuántos tags necesitas crear? <span className='fieldRequired'>*</span></label>
                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => setNumeroTags(e.target.value)}></input>
                </div>

                <div className="my-4">
                    <button className='btn btn-dark w-100 btn-full-rounded fw-bold' onClick={() => saveTags()}>Crear <span className='text-warning'>{numeroTags == "" ? 0 : numeroTags}</span> tags</button>
                </div>
            </div>
        </div>
    );



}


export default AddTags;