import React from 'react'
import { Link } from 'react-router-dom'

import logoIdPets from '../../assets/010_logoIdpets.png'
export default function NotFound() {
    return (
        <center>
            <div>
                <dd>
                    <span className='titulo'>No hay registro de este tag</span>
                </dd>
                <dd>
                    <img src={logoIdPets} className="rotateImage" alt="" />
                </dd>
                <Link to={'/'} className='btn btn-info btn-not-found'>Volver al inicio</Link>
            </div>
        </center>
    )
}
