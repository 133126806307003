/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';


/**Transitions */

import app from '../../firebase';
const db = app.firestore();







const Pet = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState([]);

    const getOrders = async () => {

        db.collection('orders')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });
                setOrders(docs)
            });
        setIsLoading(false);

    }


    useEffect(() => {
        getOrders()
    }, [])


    return (
        <>
            <Link to={`/cl/pet_details/${props.tagId}`} style={{ background: "#fff" }} className='btn d-flex justify-content-center flex-column btn-container'>
                <div style={{ backgroundImage: `url(${props.imgUrl ? props.imgUrl: "https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2F_30dc433e-92a5-48d4-bc38-e3c1a8a7d30a%202.png?alt=media&token=d90b99b6-d303-488f-8a2b-8647baec5fe9"})`, backgroundPosition: "center", backgroundSize: "cover" }} className="shadow pet-circle-image"></div>
                <div className="align-center my-4">
                    <span className='bg-gray-50 p-1 rounded-2xl pet-circle-name mx-2'>
                        {props.petName ? props.petName : "Mi mascota"}
                        {props.plan !== "Demo" ?
                            <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fcrown-minimalistic-svgrepo-com.svg?alt=media&token=f30f2791-aa2c-4b82-a703-fe253e66c991" />
                            :
                            null
                        }
                    </span>
                </div>
            </Link>
        </>
    );



}


export default Pet;