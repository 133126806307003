import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate, useParams, Link } from 'react-router-dom';
// Firebase
import app from '../firebase';
import WaFloatButton from './WAFloatButton';
const db = app.firestore();

export default function DemoTagCard() {

    const [petData, setPetData] = useState({})
    const [mensajeRecompenza, setMensajeRecompenza] = useState("")
    const params = useParams()

    const [isLoading, setIsLoading] = useState(true)

    const idTag = params.idPet

    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }

    const returnIsActiveTag = (date) => {

        let words = date.split("/")
        let nextDay = Number(words[0]) + 1
        let month = words[1]
        let year = words[2]

        const expireDate = `${nextDay}/${month}/${year}`

        //If results == true has expired
        return date == expireDate

    }

    useEffect(() => {



        db.collection('demoTags').where("tagId", "==", params.idPet).onSnapshot((querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
                setPetData(doc.data())
                setIsLoading(false)
                navigator.geolocation.getCurrentPosition((pos) => {

                    db.collection("mail")
                        .add({
                            to: doc.data().correo,
                            message: {
                                subject: "SE HA LOCALIZADO A" + doc.data().nombreMascota,
                                text: "LECTURA DE QR",
                                html: `
                                <p>Se ha registrado la localización de: ${doc.data().nombreMascota}</p>
                                <p>&nbsp;</p>
                                <p style="padding-left: 40px;">Ubicación: https://www.google.com/maps/place/${pos.coords.latitude},${pos.coords.longitude}</p>
                                <p style="padding-left: 40px;">Fecha de lectura de QR: ${timenow()}</p>
                                <p style="padding-left: 40px;">tagId:${params.idPet}</p>
                                <p style="padding-left: 40px;">&nbsp;</p>
                                <center>
                                <p><img src="https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/archivos%2FWhatsApp%20Image%202022-04-25%20at%209.46.14%20PM.jpeg?alt=media&amp;token=31c371e6-db33-4dd6-8319-053bd391004c" alt="" width="358" height="76"></p>
                                </center>
                                `,
                            },
                        }).then(() => console.log("::ALERT SENT SUCCESSFULLY::"));
                })
            })
        })
    }, [])
    return (
        <div className='bg-yellow-gradient contianer-fluid p-4'>
            {
                isLoading == true ?

                    <div className="d-flex justify-content-center align-items-center minHeight-100">
                        <img src="https://cdn.dribbble.com/users/713952/screenshots/2338364/dogrun_drib.gif" alt="" />
                    </div>
                    :
                    <>

                        {
                            returnIsActiveTag(petData.created_at) == false ?

                                <>
                                    <div className="p-2">
                                        <WaFloatButton></WaFloatButton>
                                        <div className='d-flex justify-content-center m-4'>
                                            <img src='https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/imagen.jpg?alt=media&token=4ef044e5-2206-4073-8e7f-050c7edf3d25' className="pet-circle-image" alt="" />
                                        </div>
                                        <div className='text-center'>
                                            <span className='fs-2 text-light fw-bold'>Hola, soy  {petData.nombreMascota}</span>
                                        </div>

                                        <div className='shadow p-4 my-3 bg-light'>Si no me conoces y estoy en la calle, es porque me perdí. Ayúdame a regresar a mi casa</div>
                                        <div className='shadow p-4 my-3 bg-light'>
                                            <div><p><span className='fw-bold fs-4'>Generales</span></p></div>


                                        </div>
                                        <div className='shadow p-4 my-3 bg-light'>
                                            <div><span className='fw-bold fs-4'>Contacto: {petData.usuarioNombre}</span></div>
                                            <div className='my-2'>
                                                <span><strong>Teléfono:</strong> {petData.telefono}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="shadow p-4 bg-dark text-center">
                                        <div className='my-2'>
                                            <span className='fw-bold text-light fs-4'>Recompensa inmediata</span>
                                        </div>
                                        <div className='my-2'>
                                            <span className='fw-bold text-warning display-1'>MXN${petData.recompenza}</span>
                                        </div>
                                    </div>


                                    {/* Contacto */}
                                    <div className='shadow p-4 my-3 bg-light'>

                                        <span className='fw-bold fs-4 my-2'>Avisar al dueño</span>
                                        <div className='my-2'>
                                            <span>Mensaje al dueño</span>
                                        </div>

                                        <div>
                                            <textarea className='form-control' cols="30" rows="3" placeholder='Ej. Estoy frente al parque' onChange={(e) => setMensajeRecompenza(e.target.value)}></textarea>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <a href={`https://api.whatsapp.com/send?phone=${petData.telefono}&text=INFORMACION DE Firulais ${mensajeRecompenza} UBICACION:`} className='btn button-card-info-pet shadow'>Avisar</a>
                                        </div>
                                        <i>Para informar al dueño necesitamos saber dónde estás con {petData.nombreMascota}</i>

                                    </div>

                                    <div className="my-4"></div>
                                </>

                                :
                                <div className="minHeight-100">
                                    <div className='my-4 bg-light shadow w-50 mw-auto text-center p-2'>
                                        <span className='display-6 fw-bold text-warning'>El tag  ha expirado</span>
                                        <div className="my-4 text-center">
                                            <Link to="/" className="text-dark fs-6 text-decoration-underline">Ir al inicio ➤</Link>
                                        </div>
                                    </div>
                                </div>
                        }

                    </>
            }
        </div>
    )
}
