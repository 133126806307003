/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
import FileUploader from '../FileUploader';
const db = app.firestore();


const VeterinarioDataForm = () => {

    const [clinica, setClinica] = useState('');
    const [doctor, setDoctor] = useState('');
    const [veterinarioPhone, setveterinarioPhone] = useState('');
    const [callTo, setCallto] = useState('');


    const savePet = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj)


        pet_obj["clinica"] = clinica;
        pet_obj["doctor"] = doctor;
        pet_obj["veterinarioPhone"] = veterinarioPhone;
        pet_obj["callTo"] = callTo;

        localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
        window.location.href = "/tag_medicine_form";


    }


    return (


        <>
            <div className='new-tag-bg container-fluid minHeight-100 justify-content-center d-flex align-items-center'>
                <div className='card h-auto w-auto shadow p-4'>
                    <div className='container'>
                        <div className="d-flex justify-content-center my-4">
                            <span className='text-dark fw-bold display-6'>
                                Veterinario
                            </span>
                        </div>

                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Clínica' onChange={(e) => setClinica(e.target.value)} ></input>
                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Doctor' onChange={(e) => setDoctor(e.target.value)}></input>
                        <input type="number" class=" form-control mb-2 mt-2" placeholder='Número de teléfono' onChange={(e) => setveterinarioPhone(e.target.value)}></input>
                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Hablar a' onChange={(e) => setCallto(e.target.value)}></input>



                    </div>

                    <div className='d-flex align-items-center flex-column justify-content-center'>

                        <button onClick={() => savePet()} className="btn btn-warning fw-bold text-light btn-full-rounded w-50 my-4">Siguiente</button>
                        <Link to={"/tag_medicine_form"} className="linkView">Omitir</Link>

                    </div>
                </div>
            </div>
        </>
    );



}


export default VeterinarioDataForm;