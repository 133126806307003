import React, { useEffect, useState } from 'react';
import FilterResults from 'react-filter-search';


const CheckboxSelector = ({ users, handleSelection, handleContinue, handleExternalMails }) => {
    const [selected, setSelected] = useState([]);
    const [parametro, setParametro] = useState('');

    const handleSelectAll = () => {
        if (selected.length === users.length) {
            setSelected([]);
        } else {
            setSelected(users);
        }
    }


    const handleCheckboxChange = (user) => {
        if (selected.includes(user)) {
            setSelected(selected.filter(selectedUser => selectedUser !== user));
        } else {
            setSelected([...selected, user]);
        }
    }




    const handleSearchUserInputChange = e => {
        setParametro(e.target.value)
    }

    const handleSendIndividualMails = () => {
        console.log("Individual mails")
    }


    useEffect(() => {
        handleSelection(selected)
    }, [selected])
    return (
        <div>
            <div className="d-flex flex-row my-4 justify-content-between">
                <div>
                    <div className='d-flex align-items-center'>
                        <input id='checkExterno' className="form-check-input" type="checkbox" onChange={() => handleExternalMails(true)} />
                        <label htmlFor="checkExterno" className='m-1'> Carga externa</label>
                    </div>
                    <button className="btn btn-primary" onClick={handleSelectAll}>Seleccionar todos</button>
                </div>
                <button className="btn btn-dark" onClick={() => handleContinue(true)}>Continuar ➤</button>
            </div>
            <div className="container">
                <div className='my-2'>
                    <input placeholder="Buscar usuario" value={parametro} onChange={handleSearchUserInputChange} className="form-control inputBuscarPedido"></input>
                </div>
                <div className="row row-cols-3">
                    {
                        <FilterResults
                            value={parametro}
                            data={users}
                            renderResults={results => (

                                results.length == 0 ?
                                    <label className="text-left fw-bold form-check-label m-1" for="flexCheckDefault">No hay coincidencias</label>
                                    :
                                    results.map(user => (
                                        <div className="col">
                                            <div className="form-check text-left my-2 bb-1 d-flex align-content-center align-items-center">
                                                <input className="form-check-input" type="checkbox" value={user} id={user.id} checked={selected.includes(user)} onChange={() => handleCheckboxChange(user)} />

                                                <div className="flex-column">
                                                    <div>
                                                        <label className="text-left fw-bold form-check-label m-1" for="flexCheckDefault">{user.userName}</label>
                                                    </div>
                                                    <div>
                                                        <label className="text-left form-check-label m-1 text-primary" for="flexCheckDefault">
                                                            {user.userEmail}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            )}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default CheckboxSelector;