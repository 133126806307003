/**React imports */
import React, { useState } from 'react';
/**Css styles */
import './Navigation.css';

import WaFloatButton from './components/WAFloatButton';
import IconInfo from './components/client-components/IconInfo';

const Info = () => {

    const [mensajeRecompenza, setMensajeRecompenza] = useState('')
    return (


        <div className='bg-yellow-gradient contianer-fluid p-4'>
            <WaFloatButton></WaFloatButton>
            {/* Presentación */}
            <div className="p-2">
                <div className='d-flex justify-content-center m-4'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/favicons%2FHUELLA-LOGO-IDPETSMX.png?alt=media&token=5af82693-7239-49c3-9903-510a6dfd87b3' className="pulse pet-circle-image bg-light" alt="IDPETS MX" />
                </div>
                <div className='text-center'>
                    <span className='fs-2 text-light fw-bold'>idPetsqr.com</span>
                </div>


                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionContacto">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span className='fw-bold fs-4'>Contacto</span>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-6 col-md-6 d-flex justify-content-center">
                                                <IconInfo iconText={"WhatsApp"} iconLink={"https://api.whatsapp.com/send?phone=+525584627932&text=Hola,%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre:"} iconImageLink={"https://cdn-icons-png.flaticon.com/512/124/124034.png?w=360"} />
                                            </div>
                                            <div className="col-6 col-md-6 d-flex justify-content-center">
                                                <IconInfo iconText={"Teléfono"} iconLink={"tel:525584627932"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/icon-phone-idpets.svg?alt=media&token=7afdf8fa-ea8a-4240-ba6a-4f6cec8fb299"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionCorreo">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <span className='fw-bold fs-4'>Correo</span>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#acordionCorreo">
                                <div className="accordion-body">

                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"Mail"} iconLink={"mailto:info@idpetsqr.com"} iconImageLink={" https://icones.pro/wp-content/uploads/2021/05/icones-de-messagerie-jaune.png"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionDemo">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                    <span className='fw-bold fs-4'>Demo</span>
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#acordionDemo">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"Prueba cómo funciona la plataforma"} iconLink={"https://idpetsqr.com/demoTag"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/Icono-huella.png?alt=media&token=b1087c40-2230-4eed-bc5c-0674246c31be"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionTienda">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                    <span className='fw-bold fs-4'>Tienda</span>
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#acordionTienda">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"Envíos seguros con MercadoLibre"} iconLink={"https://www.idpetshop.com/qr"} iconImageLink={"https://www.shareicon.net/data/512x512/2016/06/30/788741_commerce_512x512.png"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionWeb">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                    <span className='fw-bold fs-4'>Web</span>
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#acordionWeb">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"Ingresa a La Plataforma"} iconLink={"https://idpetsqr.com"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/icono-logo.png?alt=media&token=84220b58-c220-4cbe-9d81-00afb07d0325"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionSoporte">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                    <span className='fw-bold fs-4'>Soporte</span>
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#acordionSoporte">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"¿Problemas en tu cuenta? Escríbenos"} iconLink={"https://api.whatsapp.com/send?phone=+525584627932&text=Hola,%necesito%20ayuda%20con%20mi%20cuenta"} iconImageLink={"https://cdn-icons-png.flaticon.com/512/30/30415.png"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionRedes">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                    <span className='fw-bold fs-4'>Redes Sociales</span>
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#acordionRedes">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-4 col-md-4 d-flex justify-content-center">
                                                <IconInfo iconLink={"https://www.facebook.com/IdPetsqr"} iconImageLink={"https://logodownload.org/wp-content/uploads/2014/09/facebook-logo-3-1.png"} />
                                            </div>

                                            <div className="col-4 col-md-4 d-flex justify-content-center">
                                                <IconInfo iconLink={"https://www.instagram.com/idpets.mx/"} iconImageLink={"https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Instagram-Icon.png/1200px-Instagram-Icon.png"} />
                                            </div>

                                            <div className="col-4 col-md-4 d-flex justify-content-center">
                                                <IconInfo iconLink={"http://www.tiktok.com/@idpetqr"} iconImageLink={"https://fatimamartinez.es/wp-content/uploads/2022/03/LOGO-TIKTOK-png-300x300.png"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center my-2'>
                                        <span className='text-dark fw-bold fs-6'>Síguenos en tu Plataforma favorita</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );



}


export default Info;