import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../../Navigation.css';
import app from '../../firebase';

const db = app.firestore();


const PetInfoCard = () => {

    const [petData, setPetData] = useState('');
    const [mensajeRecompenza, setMensajeRecompenza] = useState('');


    const [isLoading, setIsLoading] = useState(true)

    let params = useParams();

    var today = new Date();
    var div = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + div + '/' + yyyy;


    function obtenerHoraExacta() {
        const ahora = new Date();
        let horas = ahora.getHours();
        const minutos = ahora.getMinutes();
        const periodo = horas >= 12 ? 'p.m.' : 'a.m.';

        // Convierte a formato de 12 horas
        horas = horas % 12 || 12;

        // Formatea los minutos para asegurar que siempre haya dos dígitos
        const minutosFormateados = minutos < 10 ? '0' + minutos : minutos;

        // Construye la cadena de la hora
        const horaExacta = `${horas}:${minutosFormateados} ${periodo}`;

        return horaExacta;
    }

    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }

    function calcularDiasRestantes(fechaObjetivo) {

        // SI RETORNA NUMEROS SON LOS DIAS QUE QUEDAN DE LA SUSCRIPCIÓN
        // SI RETORNA FALSE SIGUE VIGENTE POR QUE AUN 
        // SI RETORNA limit hoy es el limite

        // Convierte la fecha objetivo a un objeto Date
        const fechaObjetivoDate = new Date(fechaObjetivo);

        // Obtiene la fecha actual
        const fechaHoy = new Date();

        // Compara las fechas
        if (fechaHoy > fechaObjetivoDate) {
            return false;
        } else if (fechaHoy.toDateString() === fechaObjetivoDate.toDateString()) {
            return "limit"
        } else {
            // Calcula los milisegundos de diferencia entre las fechas
            const diferenciaMilisegundos = fechaObjetivoDate - fechaHoy;
            // Convierte los milisegundos a días
            const diasRestantes = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
            return diasRestantes
        }
    }


    const [plan, setPlan] = useState("")
    const [diasVigentes, setDiasVigentes] = useState(null)

    useEffect(() => {
        db.collection('pets').where("tagId", "==", params.idPet).onSnapshot((querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
                setPetData(doc.data())


                var auxData = doc.data()

                if (auxData.demoPro == true) {
                    const fechaObjetivo = auxData.fechaFinSuscripcion

                    if (fechaObjetivo) {
                        const mensaje = calcularDiasRestantes(fechaObjetivo);

                        if (mensaje === false) {
                            setPlan("Demo")
                            console.log(mensaje)
                        } else {
                            setPlan("Pro")
                            console.log("SE CAMBIO A DEMOPRO POR VIGENCIA DE SUSCRIPCION DE: ", mensaje)
                            setDiasVigentes(mensaje)
                        }
                    } else {
                        console.log("Fecha objetivo no definida");
                        setPlan("Demo")
                    }
                } else if (auxData.subscription?.subscriptionStatus == 'ACTIVE') {
                    setPlan(auxData.plan)
                } else if (auxData.subscription?.subscriptionStatus == 'FALSE') {
                    // Ejemplo de uso
                    const fechaObjetivo = auxData.subscription.subscription_nextBillingTime;

                    // Comprueba si la fechaObjetivo está definida y no es nula
                    if (fechaObjetivo) {
                        const mensaje = calcularDiasRestantes(fechaObjetivo);

                        if (mensaje === false) {
                            setPlan("Demo")
                            console.log(mensaje)
                        } else {
                            setPlan("Pro")
                            console.log("SE CAMBIO A PRO POR VIGENCIA DE SUSCRIPCION DE: ", mensaje)
                            setDiasVigentes(mensaje)
                        }
                    } else {
                        console.log("Fecha objetivo no definida");
                        setPlan("Demo")
                    }
                } else if (auxData.subscription == undefined) {
                    setPlan("Demo")
                } else if (auxData.plan == "Demo") {
                    setPlan("Demo")
                }



                setIsLoading(false)
                navigator.geolocation.getCurrentPosition((pos) => {

                    if (doc.data().correosEmergencia !== undefined) {
                        db.collection("mail")
                            .add({
                                to: doc.data().correosEmergencia,
                                message: {
                                    subject: "SE HA LOCALIZADO A " + doc.data().petName,
                                    text: "LECTURA DE QR",
                                    html: `
                                <p>Se ha registrado la localización de: ${doc.data().petName}</p>
                                <p>&nbsp;</p>
                                <p style="padding-left: 40px;">Ubicación: https://www.google.com/maps/place/${pos.coords.latitude},${pos.coords.longitude}</p>
                                <p style="padding-left: 40px;">Fecha de lectura de QR: ${timenow()}</p>
                                <p style="padding-left: 40px;">tagId:${params.idPet}</p>
                                <p style="padding-left: 40px;">&nbsp;</p>
                                <center>
                                <p><img src="https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/archivos%2FWhatsApp%20Image%202022-04-25%20at%209.46.14%20PM.jpeg?alt=media&amp;token=31c371e6-db33-4dd6-8319-053bd391004c" alt="" width="358" height="76"></p>
                                </center>
                                `,
                                },
                            }).then(() => {
                                console.log("Emailsend")
                            }).catch((error) => {
                                console.log(error)
                            })
                    }



                    if (doc.data().numeroWhatsApp !== undefined) {
                        const horaExacta = obtenerHoraExacta();

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append("Authorization", "Bearer EAADL4h0Q9ZBIBO4GBV0Y2KtJZB4GY9Qq5SkQ5VkUAdpmmYlYQWa79dHAXH5oRW0E1KHBZCqHFzwfKSG1NA3vDfnJSDR0K5RwUQIS4ZAuOcDZAICpGeFAoBA9b8QScBydGEjkZAs736ICqGtZAxYeMy2LXnQACbzeLZApsK3ZC3masC9ACWJ5U20rLFAJ6lTa2ks7NfMSCmVsZAW0lsMkXU");

                        const raw_1 = JSON.stringify({
                            "messaging_product": "whatsapp",
                            "to": doc.data().numeroWhatsApp,
                            "text": {
                                "preview_url": true,
                                "body": `Se ha escaneado la placa perteneciente a: *${doc.data().petName}* 👤 a las: *${horaExacta}* \n\nSe proporcionan los datos de la lectura, la ubicación es aproximada y depende del dispositivo que ha leído el tag \n\ntagId: *${params.idPet}*\n\n📍Ubicación: 👉 https://www.google.com/maps/place/${pos.coords.latitude},${pos.coords.longitude}`
                            }
                        })

                        const raw = JSON.stringify({
                            "messaging_product": "whatsapp",
                            "recipient_type": "individual",
                            "to": doc.data().numeroWhatsApp,
                            "type": "interactive",
                            "interactive": {
                                "type": "button",
                                "body": {
                                    "text": `Se ha escaneado la placa perteneciente a: \n\n*${doc.data().petName}* 👤 a las: *${horaExacta}* \n\nSe proporcionan los datos de la lectura, la ubicación es aproximada y depende del dispositivo que ha leído el tag \n\n*tagId*: ${params.idPet} \n\n📍 Ubicación: 👉\n\nhttps://www.google.com/maps/place/${pos.coords.latitude},${pos.coords.longitude} 🗺️\n\n _Para continuar recibiendo alertas, da click en *Permitir alertas*_`
                                },
                                "action": {
                                    "buttons": [
                                        {
                                            "type": "reply",
                                            "reply": {
                                                "id": "1",
                                                "title": "Permitir Alertas"
                                            }
                                        }
                                    ]
                                }
                            },
                            "preview_url": true
                        });

                        const requestOptions_1 = {
                            method: "POST",
                            headers: myHeaders,
                            body: raw,
                            redirect: "follow"
                        };

                        try {
                            fetch("https://graph.facebook.com/v19.0/213143195208157/messages", requestOptions_1)
                                .then((response) => {
                                    console.log("WhatsApp Alert Sent")
                                    console.log(response)
                                })
                        } catch (error) {
                            console.log(error)
                        }
                    } else {
                        console.log("Se terminó el proceso de acuerdo a las caracteristicas Demo")
                    }
                })
            })
        })
    }, [])







    return (
        <div className='d-flex flex-column align-items-center h-screen'>

            {
                isLoading == true ?

                    <div className="container-fluid d-flex justify-content-center align-items-center flex-column border">
                        <div className="my-4">
                            <span className='display-6 text-dark'>No se ha encontrado el tag</span>
                        </div>
                        <div className="my-4">
                            <Link to="/" className='btn btn-warning'>Ir al inicio ➤➤</Link>
                        </div>
                    </div>
                    :
                    <>


                        {
                            petData !== '' ?

                                <div className='container bg-yellow-gradient h-screen'>

                                    <div className="d-flex justify-content-center my-4">
                                        <div className="d-flex justify-content-center flex-column shadow bg-light p-3 w-50-responsive">
                                            <img src={petData.petImageLink} className="pet-circle-image" alt="" />
                                            <div className='text-center'>
                                                <label className='fs-2 text-dark fw-bold'>Hola, soy  {petData.petName}</label>
                                                <p>
                                                    Si no me conoces y estoy en la calle, es porque me perdí. Ayúdame a regresar a mi casa
                                                </p>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="bg-yellow-gradient">
                                        <div className="row row-cols-2">

                                            {
                                                plan !== "Demo" ?

                                                    petData.ruac ?
                                                        <div className="col-12 col-md-6 p-2 my-2">
                                                            <div className='shadow bg-light p-3'>
                                                                <h1 className='fs-4 fw-bold text-center'>CLAVE RUAC</h1>
                                                                <h2 className='fs-4 fw-bold text-warning text-center'>{petData.ruac}</h2>
                                                                <center>
                                                                    {
                                                                        petData && petData.ruacLink ? (
                                                                            <a href={petData.ruacLink} className="fs-6 fw-bold text-primary text-center">Ver tarjeta RUAC</a>
                                                                        ) : null
                                                                    }
                                                                </center>
                                                                <div className='text-center'>
                                                                    <small className='text-center'>Registro Único de Animales de Compañía</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    :
                                                    null

                                            }


                                            {
                                                plan !== "Demo" ?
                                                    <div className="col-12 col-md-6 p-2 my-2">
                                                        <div className='shadow bg-light p-3'>
                                                            <h1 className='fs-4 fw-bold'>Información General</h1>
                                                            <div>Fecha de nacimiento: {petData.bornDate}</div>
                                                            <div>Tamaño: {petData.petSize}</div>
                                                            <div>Raza: {petData.petType}</div>
                                                            <div>Color: {petData.color}</div>
                                                            <div>Castrado: {petData.steril}</div>
                                                            <div>Telefono de contacto: {petData.telefonoDueno}</div>
                                                        </div>
                                                    </div>

                                                    :
                                                    null
                                            }

                                            {
                                                petData?.planLite == true ?
                                                    <div className="col-12 col-md-6 p-2 my-2">
                                                        <div className='shadow bg-light p-3'>
                                                            <h1 className='fs-4 fw-bold'>Información General</h1>
                                                            <div>Fecha de nacimiento: {petData.bornDate}</div>
                                                            <div>Tamaño: {petData.petSize}</div>
                                                            <div>Raza: {petData.petType}</div>
                                                            <div>Color: {petData.color}</div>
                                                            <div>Castrado: {petData.steril}</div>
                                                            <div>Telefono de contacto: {petData.telefonoDueno}</div>
                                                        </div>
                                                    </div>

                                                    :
                                                    null
                                            }
                                            {
                                                plan !== "Demo" ?


                                                    <>
                                                        {petData.visibleContactInfo == true ?

                                                            <div className="col-12 col-md-6 p-2 my-2">
                                                                <div className='shadow bg-light p-3'>
                                                                    <h1 className='fs-4 fw-bold'>Información de Contacto</h1>
                                                                    <div>Nombre: {petData.contactName}</div>
                                                                    <div>Telefono primario: {petData.primaryNumber}</div>
                                                                    <div>Telefono alternativo: {petData.alternativeNumber}</div>
                                                                    <div>Dirección: {petData.location}</div>
                                                                    <div>E-mail: {petData.email}</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="divider"></div>
                                                        }
                                                        {

                                                            petData.visibleVeterinarioInfo == true ?
                                                                <div className="col-12 col-md-6 p-2 my-2">
                                                                    <div className='shadow bg-light p-3'>
                                                                        <h1 className='fs-4 fw-bold'>Veterinario</h1>
                                                                        <div>Clinica: {petData.clinica}</div>
                                                                        <div>Doctor: {petData.doctor}</div>
                                                                        <div>Telefono: {petData.verterinatioPhone}</div>
                                                                        <div>Habla a: {petData.callTo}</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="divider"></div>
                                                        }

                                                        {
                                                            petData.visibleMedicamentos == true ?
                                                                <div className="col-12 col-md-6 p-2 my-2">
                                                                    <div className='shadow bg-light p-3'>
                                                                        <h1 className='fs-4 fw-bold'>Medicamentos</h1>
                                                                        <div>Medicación: {petData.medicacion}</div>
                                                                        <div>Propósito: {petData.proposito}</div>
                                                                        <div>Notas: {petData.notas}</div>
                                                                        <div>Frecuencia diaria: {petData.frecuenciaDiaria}</div>
                                                                        <div>Tiempo y dosis: {petData.tiempoDosis}</div>
                                                                        <div>Fecha de Inicio: {petData.fechaInicio}</div>
                                                                        <div>Duración: {petData.duracion}</div>
                                                                        <div>Días: {petData.dias}</div>
                                                                    </div>
                                                                </div>
                                                                :

                                                                <div className="divider"></div>
                                                        }

                                                        {

                                                            petData.visibleAlergias == true ?
                                                                <div className="col-12 col-md-6 p-2 my-2">
                                                                    <div className='shadow bg-light p-3'>
                                                                        <h1 className='fs-4 fw-bold'>Alergias</h1>
                                                                        <div>Alergias: {petData.alergia}</div>
                                                                        <div>Reacción: {petData.reaccion}</div>
                                                                        <div>Remedio: {petData.remedio}</div>
                                                                        <div>Ultima Actualización: {petData.ultimaActualizacion}</div>
                                                                        <div>Notas: {petData.notasAlergias}</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="divider"></div>
                                                        }

                                                        {
                                                            petData.visibleRecompenza == true ?
                                                                <div className='col-12 col-md-6 p-2 my-2'>

                                                                    <div className="shadow p-4 bg-dark text-center">
                                                                        <div className='my-2'>
                                                                            <span className='fw-bold text-light fs-4'>Recompensa inmediata</span>
                                                                        </div>
                                                                        <div className='my-2'>
                                                                            <span className='fw-bold text-warning display-1'>MXN${petData.recompensa}</span>
                                                                        </div>
                                                                        <div className='my-2'>
                                                                            <span className='text-light fs-4'>{petData.notasRecompensa}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="divider"></div>
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }

                                            {
                                                plan !== "Demo" ?
                                                    <div className="col-12 col-md-6 p-2 my-2">
                                                        <div className='shadow bg-light p-3'>
                                                            <h1 className='fs-4 fw-bold'>Avisar al dueño</h1>
                                                            <div>
                                                                <label>Mensaje al dueño</label>
                                                            </div>

                                                            <div>
                                                                <textarea className='form-control' cols="30" rows="3" placeholder='Ej. Estoy frente al parque' onChange={(e) => setMensajeRecompenza(e.target.value)}></textarea>
                                                            </div>
                                                            <div>
                                                                <a href={`https://api.whatsapp.com/send?phone=52${petData.primaryNumber}&text=${petData.email} INFORMACION DE ${petData.petName} ${mensajeRecompenza} ESTOY EN: `} className='btn button-card-info-pet shadow bg-light fw-bold p-3 w-100 fs-5'>Avisar</a>
                                                            </div>
                                                            <i>Para informar al dueño necesitamos saber dónde estás con {petData.petName}</i>
                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>
                                </div>

                                :
                                <div className="container shadow bg-light p-3 d-flex justify-content-center align-items-center">
                                    <div className="my-4">
                                        <span className='display-6 text-dark'>No se ha encontrado el tag</span>
                                    </div>
                                    <div className="my-4">
                                        <Link to="/" className='btn btn-warning'>Ir al inicio ➤➤</Link>
                                    </div>
                                </div>

                        }

                    </>
            }


        </div>
    )



}


export default PetInfoCard;