/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

import app from '../../../firebase';
const db = app.firestore();
const auth  = app.auth()

const RewardForm = () => {

    const [recompensa, setRecompensa] = useState('');
    const [notasRecompensa, setNotasRecompensa] = useState('');
    const [pet, setPet] = useState('');
    const [isLoading, setIsLoading] = useState(false)


    let tagId = localStorage.getItem('@tagId');

    const savePet = async () => {
        setIsLoading(true);

        const currentUser = auth.currentUser;

        const userId = currentUser.uid;

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj);

        pet_obj["recompensa"] = recompensa;
        pet_obj["notasRecompensa"] = notasRecompensa;
        pet_obj["visibleInformacionGeneral"] = true;
        pet_obj["visibleContactInfo"] = true;
        pet_obj["visibleVeterinarioInfo"] = true;
        pet_obj["visibleMedicamentos"] = true;
        pet_obj["visibleAlergias"] = true;
        pet_obj["visibleRecompenza"] = true;

        if (userId) {
            pet_obj["userId"] = userId;

            await db.collection('pets')
                .add(pet_obj)
                .then(async () => {
                    updateTagIdIsActive();
                })
                .catch((err) => console.log(err));

            localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
        } else {
            alert("Confirme nuevamente el registro.");
        }
    };


    const updateTagIdIsActive = async () => {
        await db.collection("tags").where("tagId", "==", tagId)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    db.collection('tags').doc(doc.id).set({
                        "isActive": true,
                    }, {
                        merge: true
                    }).then(() => {
                        console.log('Completed..')
                        setIsLoading(false)
                        window.location.href = "/tag_saved_alert";
                    })
                });
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

    const recoverData = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj);

        setPet(pet_obj);
    }

    useEffect(() => {
        recoverData();
    }, [])

    return (


        <>






            <div className='new-tag-bg container-fluid minHeight-100 justify-content-center d-flex align-items-center'>
                <div className='card h-50 shadow p-4'>
                    <div className="w-100 bg-warning p-2 rounded-sm shadow my-3">
                        <span className='text-light fw-bold display-6'>Recompenza ofrecida</span>
                    </div>
                    <p className="mb-2 mt-2">
                        En caso de que  <strong>{pet.petName}</strong> se pierda, ¿Cuánto estarías dispuesto a recompensar a quien lo regrese a tu lado?
                    </p>
                    <input type="number" min={1} className=" form-control mb-2 mt-2" placeholder='$' onChange={(e) => setRecompensa(e.target.value)} ></input>
                    <textarea class=" form-control mb-2 mt-2" placeholder='Notas de recompensa' onChange={(e) => setNotasRecompensa(e.target.value)}></textarea>
                    <div className='d-flex flex-row container-group mb-3 justify-content-center'>

                        {
                            isLoading ?
                                <div class="spinner-grow text-warning" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                :
                                <button onClick={() => savePet()} className="btn btn-warning fw-bold text-light btn-full-rounded w-50 my-4">Listo</button>

                        }
                    </div>
                </div>
            </div>
        </>
    );



}


export default RewardForm;