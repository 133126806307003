import React, { useState, useEffect } from 'react';
import app from '../firebase';
import { DataTable, } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import 'primeicons/primeicons.css';
import { FilterMatchMode } from 'primereact/api';
const db = app.firestore();


export default function TableSortTags({ limit, tags }) {



    const cancelDemoSubscription = async (tagId) => {

        if (window.confirm("Confirme que desea cancelar la suscripción")) {
            try {
                // Busca el documento en la colección pets con el tagId proporcionado
                const querySnapshot = await db.collection('pets').where('tagId', '==', tagId).get();

                // Verifica si se encontró algún documento
                if (querySnapshot.empty) {
                    console.error(`No se encontró ningún pet con el tagId: ${tagId}`);
                    return;
                }

                // Actualiza el campo 'subscription.subscriptionStatus' a 'FALSE'
                const petDoc = querySnapshot.docs[0];
                const petId = petDoc.id;

                await db.collection('pets').doc(petId).update({
                    plan: "Demo",
                    'subscription.subscriptionStatus': 'FALSE'
                });

                alert(`La suscripción del pet con tagId ${tagId} ha sido cancelada.`);
            } catch (error) {
                alert('Error al cancelar la suscripción:', error);
            }
        }

    };






    const templateActiveTag = (state) => {
        if (state == true) {
            return (
                <div class="circle pulse green"></div>
            )
        } else {
            return (
                <span className='text-gray'>
                    Inactivo
                </span>
            )
        }

    }



    const deleteTag = (idTag, petIdTag) => {


        if (window.confirm("¿Desea eliminar el tag?")) {
            db.collection("tags").doc(idTag).delete().then(() => {


                const queryTagIdInPet = db.collection("pets").where("tagId", "==", petIdTag)


                queryTagIdInPet.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        doc.ref.delete().then(() =>
                            alert("La información del tag y de la mascota registrada ha sido eliminada correctamente"))
                    })
                })


            })
        } else {
            console.log(false)
        }
    }


    const restartTag = (idTag, petIdTag) => {
        if (window.confirm('¿Desea reiniciar el tag?')) {
            db.collection('tags').doc(idTag).update({
                "isActive": false
            }).then(() => {
                db.collection('pets').where('tagId', "==", petIdTag).get().then((query) => {
                    query.forEach((doc) => {
                        doc.ref.delete().then(() => {
                            alert('Información de la mascota eliminada, se ha reiniciado el tag correctamente')
                        })
                    })
                })
            })
        } else {
            console.log(null)
        }
    }

    const [searchTerm, setSearchTerm] = useState('');

    
    return (
        <div className="card">


            <input
                type="text"
                placeholder="Buscar..."
                value={searchTerm}
                className='form-control mb-4 w-50-responsive'
                onChange={(e) => setSearchTerm(e.target.value)}
            />
           

            <table className="table">
                <thead>
                    <tr>
                        <th style={{ width: '25%' }}>#</th>
                        <th style={{ width: '25%' }}>Tag ID</th>
                        <th style={{ width: '25%' }}>Creado</th>
                        <th style={{ width: '20%' }}>Estado</th>
                        <th style={{ width: '30%' }}>Tag</th>
                        <th style={{ width: '25%' }}>Eliminar</th>
                        <th style={{ width: '25%' }}>Reiniciar</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tags.filter(row => 
                                row.tagId?.toLowerCase().includes(searchTerm.toLowerCase())
                                || row.crated_at?.toLowerCase().includes(searchTerm.toLowerCase())
                                || row.isActive?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map((rowData, index) => (
                                <tr key={index}>
                                    <td style={{ width: '25%' }}>{index + 1}</td>
                                    <td style={{ width: '25%' }}>{rowData.tagId}</td>
                                    <td style={{ width: '25%' }}>{rowData.crated_at}</td>
                                    <td style={{ width: '20%' }}>{templateActiveTag(rowData.isActive)}</td>
                                    <td style={{ width: '30%' }}><a href={`/pet/${rowData.tagId}`} className='linkView'>Ver</a></td>
                                    <td style={{ width: '25%' }}><button className="btn btn-danger w-100 btn-full-rounded" onClick={() => deleteTag(rowData.id, rowData.tagId)}>Eliminar</button></td>
                                    <td style={{ width: '25%' }}><button className="btn btn-warning w-100 btn-full-rounded" onClick={() => restartTag(rowData.id, rowData.tagId)}>Reiniciar</button></td>
                                </tr>
                            ))
                    }
                </tbody>
            </table>
        </div>

    );
}
