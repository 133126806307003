/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';
import Pet from './Pet';

/**Transitions */

import app from '../../firebase';
import FadeInLeft from '../../animations/FadeInLeft';
const db = app.firestore();







const Pets = () => {

    let navigate = useNavigate();

    const [pets, setPets] = useState([]);
    let userId = sessionStorage.getItem("userId");

    const getPets = async () => {


        if (userId) {

        } else {
            navigate('/login')
        }


        await db.collection('pets').where('userId', '==', userId).onSnapshot((querySnapshot) => {

            const docs = [];
            querySnapshot.forEach(doc => {
                docs.push({ ...doc.data(), id: doc.id })
            });
            setPets(docs)

        }).catch(function (error) {
            console.log("Error getting documents: ", error);
        });
    }

    useEffect(() => {
        getPets()
    }, [])


    return (


        <div className='fade-div'>
            <FadeInLeft>
                {/* PC */}
                <div className='row' id="row-pc">
                    <h1 className='m-2'>Mis mascotas</h1>
                    <div className='d-flex flex-row m-4'>
                        <Link to={"/cl/dashboard"} className="linkView">Regresar</Link>
                    </div>
                    <div className="container m-2">
                        <div className='row row-cols-3'>
                            {pets == "" ? <h5>No has registrado mascotas, <Link className='linkView' to={'/new_tag'}>registra una aquí</Link> </h5> :
                                pets.map((pet) => (
                                    <Pet petName={pet.petName} plan={pet.plan} imgUrl={pet.petImageLink} tagId={pet.tagId}></Pet>
                                ))
                            }
                        </div>
                    </div>
                </div>

                {/* MB */}

                <div className='row p-4' id="row-mb">
                    <h1 className='display-6 fw-bold'>Mis mascotas</h1>
                    <div className='d-flex flex-row my-1'>
                        <Link to={"/cl/dashboard"} className="bg-dark text-white  p-1 rounded-2xl">
                            {'<'} Volver
                        </Link>
                    </div>
                    <div className="container m-2">
                        <div className='row row-cols-1'>
                            {pets == "" ? <h5>No has registrado mascotas, <Link className='linkView' to={'/new_tag'}>registra una aquí</Link> </h5> :
                                pets.map((pet) => (
                                    <Pet petName={pet.petName} plan={pet.plan} imgUrl={pet.petImageLink} tagId={pet.tagId}></Pet>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </FadeInLeft>
        </div>
    );



}


export default Pets;