/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
import FileUploader from '../FileUploader';
const db = app.firestore();


const ContactDataForm = () => {

    const [contactName, setContactName] = useState('');
    const [primaryNumber, setPrimaryNumber] = useState('');
    const [alternativeNumber, setAlternativeNumber] = useState('');
    const [location, setLocation] = useState('');
    const [email, setEmail] = useState('');

    const handleEmailInputChange = e => {
        const { value } = e.target;

        var mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e.target.value.match(mail) && e.target.value != "") {
            document.querySelector("#mailInput").className = 'form-control correctNumber';
            setEmail(value)
        } else {
            document.querySelector("#mailInput").className = 'form-control invalidNumber';
            setEmail("")
        }
    }

    const savePet = () => {


        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj)


        pet_obj["contactName"] = contactName;
        pet_obj["primaryNumber"] = primaryNumber;
        pet_obj["alternativeNumber"] = alternativeNumber;
        pet_obj["location"] = location;
        pet_obj["email"] = email;

        localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
        window.location.href = "/tag_veterinario_form";

    }


    return (


        <>
            <div className='new-tag-bg container-fluid minHeight-100 justify-content-center d-flex align-items-center'>
                <div className='card h-auto w-auto shadow p-4'>
                    <div className='container'>
                        <div className="d-flex justify-content-center my-4">
                            <span className='text-dark fw-bold display-6'>
                                Datos de contacto
                            </span>
                        </div>

                        <input type="text" id='mailInput' oninput="this.value=this.value.toLowerCase()" class=" form-control mb-2 mt-2" placeholder='Email' onChange={handleEmailInputChange} ></input>
                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Nombre' onChange={(e) => setContactName(e.target.value)}></input>
                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Número primario' onChange={(e) => setPrimaryNumber(e.target.value)} ></input>
                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Número alternativo' onChange={(e) => setAlternativeNumber(e.target.value)}></input>
                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Dirección' onChange={(e) => setLocation(e.target.value)}></input>

                    </div>

                    <div className='d-flex align-items-center flex-column justify-content-center'>
                        <button onClick={() => savePet()} className="btn btn-warning fw-bold text-light btn-full-rounded w-50 my-4">Siguiente</button>
                    </div>
                </div>
            </div>
        </>
    );



}


export default ContactDataForm;