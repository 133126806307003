import React, { useEffect, useState } from 'react'
import app from '../firebase';
import { Link } from 'react-router-dom';
import FilterResults from 'react-filter-search';
import { Button } from 'primereact/button';
import TableSort from './TableSort';
import { ProgressSpinner } from 'primereact/progressspinner';


const db = app.firestore();


export default function UsersConfig() {
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [startDateUser, setStartDateUser] = useState('')
  const [endDateUser, setEndDateUser] = useState('')
  const [endDate, setEndDate] = useState('');
  const [plan, setPlan] = useState('')
  const [emailUser, setEmailUser] = useState('')
  const [tagId, setTagId] = useState('')
  const [parametro, setParametro] = useState('');


  const [workingUsers, setWorkingUsers] = useState(false)
  const [workingOnUser, setWorkingOnUser] = useState(false)

  const [pets, setPets] = useState([]);

  const [showPetsConfig, setShowPetsConfig] = useState(false)


  const handleSearchInputChange = e => {
    setParametro(e.target.value)
  }

  const convertToISODate = (date) => {
    // Divide la fecha en partes (día, mes, año)
    const [day, month, year] = date.split('/');

    // Formatea la fecha en el formato ISO deseado
    const isoDate = `${year}-${month}-${day}T00:00:00Z`;
    return isoDate;
  };

  const updateSubscriptionDatesForUser = () => {

    if (window.confirm(`¿Estás seguro de que deseas modificar las fechas de suscripción de la mascota con tagId: ${tagId}?`) === false) return;
    const petsRef = db.collection('pets');
    setWorkingOnUser(true)
    // Realiza una consulta para encontrar el documento del usuario por su correo electrónico
    petsRef.where('tagId', '==', tagId).get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          console.log(`No se encontró ningúna mascota con el tag ${tagId}`);
          return;
        }

        // Deberías manejar el caso en el que haya múltiples usuarios con el mismo correo electrónico
        // Aquí asumimos que solo hay uno
        const doc = querySnapshot.docs[0];
        const docRef = doc.ref;

        const isoStartDate = convertToISODate(startDateUser);
        const isoEndDate = convertToISODate(endDateUser);

        // Actualiza el documento del usuario encontrado
        return docRef.update({
          fechaInicioSuscripcion: isoStartDate,
          fechaFinSuscripcion: isoEndDate,
          'demoPro': true,
          'subscription.subscriptionStatus': 'ACTIVE'
        });
      })
      .then(() => {
        alert(`Actualización de fechas de suscripción exitosa`);
        setWorkingOnUser(false)
      })
      .catch((error) => {
        alert(`Error al actualizar fechas de suscripción`, error);
        setWorkingOnUser(false)
      });
  };


  const [tagIdLite, setTagIdLite] = useState('')
  const updateToLitePet = () => {

    if (window.confirm(`¿Estás seguro de que deseas actualizar a LITE la suscripción de la mascota con tagId: ${tagIdLite}?`) === false) return;
    const petsRef = db.collection('pets');
    setWorkingOnUser(true)
    // Realiza una consulta para encontrar el documento del usuario por su correo electrónico
    petsRef.where('tagId', '==', tagIdLite).get()
      .then((querySnapshot) => {
        if (querySnapshot.size === 0) {
          console.log(`No se encontró ningúna mascota con el tag ${tagIdLite}`);
          return;
        }

        // Deberías manejar el caso en el que haya múltiples usuarios con el mismo correo electrónico
        // Aquí asumimos que solo hay uno
        const doc = querySnapshot.docs[0];
        const docRef = doc.ref;


        // Actualiza el documento del usuario encontrado
        return docRef.update({
          'planLite': true,
        });
      })
      .then(() => {
        alert(`Actualización exitosa`);
        setWorkingOnUser(false)
      })
      .catch((error) => {
        alert(`Error al actualizar suscripción`, error);
        setWorkingOnUser(false)
      });
  };


  const addPlanToUsers = () => {
    // Inicializa un batch
    const batch = db.batch();

    // Obtén una referencia a la colección de usuarios
    const petsRef = db.collection('pets');

    petsRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // Agrega una operación de actualización al batch para cada documento
          const docRef = petsRef.doc(doc.id);
          batch.update(docRef, { plan: plan });
        });

        // Ejecuta el batch para aplicar todas las actualizaciones de manera atómica
        return batch.commit();
      })
      .then(() => {
        console.log('Actualización de plan exitosa para todos las mascotas.');
      })
      .catch((error) => {
        console.error('Error al actualizar mascotas: ', error);
      });
  };

  useEffect(() => {
    setLoading(true);
    const unsubscribe = db.collection('pets')
      .where('subscription', '!=', null)
      .get()
      .then((querySnapshot) => {
        const petList = [];
        querySnapshot.forEach((doc) => {
          petList.push({ id: doc.id, ...doc.data() });
        });
        setPets(petList);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error al obtener datos de Firestore:', error);
      });

    return () => unsubscribe(); // Detener la suscripción cuando el componente se desmonte
  }, []); // Asegúrate de pasar un arreglo vacío como dependencia si solo quieres que se ejecute una vez al montar el componente


  return (
    <div className='container p-4'>




      <div className="my-4">
        <Link to="/ad/dashboard" className='bg-dark text-decoration-none text-white bg-white p-2 fs-5'>◄ Regresar</Link>
      </div>
      <div className="my-4">
        <span className='display-5 fw-bold'>Suscripciones</span>
      </div>

      <div className="d-flex flex-row-reverse">

        <button className={`${!showPetsConfig ? "btn btn-dark text-white" : "btn btn-secondary text-white"}`} onClick={() => setShowPetsConfig(!showPetsConfig)}>
          {
            !showPetsConfig ? <span className='fade-div'>Abrir configuración de mascotas
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: "20px", height: "20px" }}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495" />
              </svg>
            </span> : <span className='fade-div'>Cerrar configuración <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ width: "20px", height: "20px" }}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            </span>
          }
        </button>
      </div>
      {
        showPetsConfig ?
          <div className="row fade-div">
            <div className="col shadow my-4 m-4 p-4 border">

              <h1 className='text-info fw-bold'>Plan Lite</h1>
              <div class="my-4 alert alert-info" role="alert">
                En este modulo puedes colocarle las fechas de inicio de su sucripción LITE a una mascota actual así como su fecha de expiración del plan
              </div>

              <div>
                <label>TagId de la mascota</label>
                <input
                  className='form-control'
                  type="text"
                  onChange={(e) => setTagIdLite(e.target.value)}
                />
              </div>

              {
                workingOnUser ?
                  <button className='btn btn-primary my-4 d-flex align-items-center'>
                    <span class="sr-only mx-2">Por favor espere mientras se realizan los cambios </span>
                    <div class="spinner-border spinner-border-sm text-light" role="status"></div>
                  </button>
                  :
                  <button className='btn btn-info btn-full-rounded my-4 text-white font-bold' onClick={updateToLitePet}>Activar plan Lite del tag: {tagIdLite}</button>
              }

            </div>


            <div className="col shadow my-4 m-4 p-4 border">
              <h1 className='text-primary fw-bold'>Plan PRO</h1>

              <div class="my-4 alert alert-primary" role="alert">
                En este modulo puedes colocarle las fechas de inicio de su sucripción PRO a una mascota actual así como su fecha de expiración del plan
              </div>

              <div>
                <label>TagId de la mascota</label>
                <input
                  className='form-control'
                  type="text"
                  onChange={(e) => setTagId(e.target.value)}
                />
              </div>

              <div>
                <label>Fecha de Inicio (dd/mm/yyyy): </label>
                <input
                  className='form-control'
                  type="text"
                  value={startDateUser}
                  placeholder='Ej. 02/11/2023'
                  onChange={(e) => setStartDateUser(e.target.value)}
                />
              </div>
              <div>
                <label>Fecha de Fin (dd/mm/yyyy): </label>
                <input
                  className='form-control'
                  type="text"
                  value={endDateUser}
                  placeholder='Ej. 02/01/2024'
                  onChange={(e) => setEndDateUser(e.target.value)}
                />
              </div>
              {
                workingOnUser ?
                  <button className='btn btn-primary my-4 d-flex align-items-center'>
                    <span class="sr-only mx-2">Por favor espere mientras se realizan los cambios </span>
                    <div class="spinner-border spinner-border-sm text-light" role="status"></div>
                  </button>
                  :
                  <button className='btn btn-primary btn-full-rounded my-4 font-bold' onClick={updateSubscriptionDatesForUser}>Modificar suscripción del tag: {tagId}</button>
              }
            </div>
          </div>


          :
          null
      }


      <div>
        <h2>Listado de mascotas con suscripcion</h2>

        {
          loading ?
            <div className='d-flex justify-content-center my-4'>
              <img src="https://i.stack.imgur.com/kOnzy.gif" className='icon-loading' />
            </div>
            :
            <TableSort pets={pets} />
        }

      </div>
    </div>
  );
}
