import React from 'react';
import { Routes, Route } from 'react-router-dom'



import DashboardClient from './components/client-components/DashboardClient';
import ClientProfile from './components/client-components/ClientProfile';
import Login from './components/Login';
import Signup from './components/client-components/Signup';
import DashboardAdmin from './components/adminComponents/DashboardAdmin';
import Pets from './components/client-components/Pets';
import PetDetails from './components/client-components/PetDetails';
import NewTag from './components/client-components/NewTag';
import GeneralDataForm from './components/client-components/FormCreateNewTag/GeneralDataForm';
import ContactDataForm from './components/client-components/FormCreateNewTag/ContactDataForm';
import VeterinarioDataForm from './components/client-components/FormCreateNewTag/VeterinarioDataForm';
import MedicineForms from './components/client-components/FormCreateNewTag/MedicineForms';
import AllergyForm from './components/client-components/FormCreateNewTag/AllergyForm';
import RewardForm from './components/client-components/FormCreateNewTag/RewardForm';
import AlertConfirmSavedPet from './components/client-components/FormCreateNewTag/AlertConfirmSavedPet';
import AddTags from './components/adminComponents/AddTags';
import PetInfoCard from './components/client-components/PetInfoCard';
import NotFound from './components/client-components/NotFound';
import HomePage from './components/HomePage';
import ResetPassword from './components/ResetPassword';
import Demo from './components/Demo';
import AvisoPrivacidad from './components/AvisoPrivacidad';
import Terminos from './components/Terminos';
import Parking from './components/Parking';
import CentroMensajes from './components/adminComponents/CentroMensajes';
import Productos from './components/adminComponents/Productos';
import SendEmail from './components/adminComponents/SendEmail';
import NuevoDemoTag from './components/NuevoDemoTag';
import DemoTagCard from './components/DemoTagCard';
import DemoTags from './components/adminComponents/DemoTags';
import Info from './Info';
import AdminEmails from './components/adminComponents/AdminEmails/AdminEmails';
import EmailMarketing from './components/adminComponents/EmailMarketing/EmailMarketing';
import UsersConfig from './components/UsersConfig';

import Tags from './components/adminComponents/Tags';
import Clients from './components/adminComponents/Clients';
import ShowEmail from './components/adminComponents/AdminEmails/ShowEmail';

/**
 * <Route exact path="/authenticationUserAdmin/webServiceLoginAdministration" element={adminDashboard} />
 */
const WebRoutes = () => {

    return (
        <Routes>

            <Route exact path="/" element={<HomePage />} />

            {/* Vistas cliente */}
            <Route exact path='/cl/dashboard' element={<DashboardClient />} />
            <Route exact path='/cl/profile' element={<ClientProfile />} />
            <Route exact path='/signup' element={<Signup />} />
            <Route exact path='/cl/pets_list_all' element={<Pets />} />

            {/* Login general */}

            <Route path='/login' element={<Login />} />
            <Route path='/ad/login' element={<Login />} />

            {/* Vistas admin */}

            <Route path='/ad/dashboard' element={<DashboardAdmin />} />
            <Route path='/ad/tags' element={<Tags />} />
            <Route path='/ad/clients' element={<Clients />} />
            <Route path='/ad/add_tags' element={<AddTags />} />
            <Route path='/ad/message_center' element={<CentroMensajes />} />
            <Route path='/ad/productos' element={<Productos />} />
            <Route path='/sentMail' element={<SendEmail />} />
            <Route path='/ad/demos' element={<DemoTags />} />
            <Route path='/ad/emails' element={<AdminEmails />} />
            <Route path='/ad/email_marketing' element={<EmailMarketing />} />
            <Route exact path="/ad/petsConfig" element={<UsersConfig />} />



            {/* Pets */}


            <Route path='/cl/pet_details/:idPet' element={<PetDetails />} />
            <Route path='/pet/:idPet' element={<PetInfoCard />} />
            {/* Emails */}
            <Route path='/ad/emails/show/:idEmail' element={<ShowEmail />} />
            {/* Tags */}

            <Route path='/new_tag' element={<NewTag />} />
            <Route path='/pet' element={<Parking />} />
            <Route path='/tag_general_form' element={<GeneralDataForm />} />
            <Route path='/tag_saved_alert' element={<AlertConfirmSavedPet />} />
            <Route path='/reset_password' element={<ResetPassword />} />
            <Route path='/demoTag' element={<NuevoDemoTag />} />
            <Route path='/demoPet/:idPet' element={<DemoTagCard />} />

            {/* Demo */}
            <Route path='/demo' element={<Demo />} />
            {/* Info */}
            <Route path='/info' element={<Info />} />

            {/* Aviso privacidad */}
            <Route path='/privacidad' element={<AvisoPrivacidad />} />
            <Route path='/terminos' element={<Terminos />} />


            {/* Not found */}
            <Route path='/not-found' element={<NotFound />} />

        </Routes>
    );
    // return (
    //     <Routes>
    //         <Route exact path="*" element={
    //             <>
    //                 <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: 'red' }}>
    //                     <h5>Service unavailable You have reached the limits of your server resources, please contact your webmaster to solve the problem. [Error code: 503]</h5>
    //                 </div>
    //             </>
    //         } />
    //     </Routes>
    // );

}

export default WebRoutes;