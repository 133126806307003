/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';


/**Transitions */

import app from '../../firebase';
const db = app.firestore();







const ClientProfile = () => {

    let navigate = useNavigate()

    let currentAuth = app.auth().currentUser

    const [state, setState] = useState({})

    const [userData, setUserData] = useState({})
    const [newPassword, setNewPassword] = useState('')

    const getUserData = () => {

        let userId = sessionStorage.getItem("userId");

        if (userId) {
            db.collection('usuarios').where("userId", "==", userId).get().then(function (querySnapshot) {

                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    setUserData({ ...doc.data(), id: doc.id })
                });


            }).catch(function (error) {
                console.log("Error getting documents: ", error);
            });
        } else {
            navigate('/login')
        }


    }


    const changePassword = () => {
        let user = app.auth().currentUser;
        console.log(user.email)
        console.log("user")
        user.updatePassword(newPassword).then(() => {
            alert('Password was changed')
            sessionStorage.clear()
            window.location.href = "/login"
        }).catch((error) => {
            console.log(error.message)
        })


    }

    const deleteAccount = async () => {
        let userId = sessionStorage.getItem("userId");
        if (window.confirm("¿Realmente deseas eliminar tu cuenta?")) {
            let user = app.auth().currentUser;
            user.delete().then(() => {
                db.collection("usuarios").where("userId", "==", userId)
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            // doc.data() is never undefined for query doc snapshots
                            db.collection('pets').doc(doc.id).delete().then(() => {
                                alert("Cuenta eliminada, saliendo...")
                                window.location.href = "/login"

                            })
                        });
                    })

            })
        }
    }

    const handleTextChange = (name, value) => {
        setState({ ...state, [name]: value })
    }
    const updateContactData = () => {
        db.collection('usuarios').doc(userData.id).set(state, {
            merge: true
        }).then(() => {
            alert("Información actualizada")
            window.location.reload();
        }).catch((error) => {
            alert("No se pudo actualizar la información, intente más tarde, código de error ", error.message)
        })
    }
    useEffect(() => {
        getUserData()
    }, [])


    return (
        <div className='container'>
            <div className='row mt-2'>

                <div className='col col-8'>
                    <h2>Mi perfil</h2>

                    <div className='d-flex flex-row mt-4'>
                        <Link to={"/cl/dashboard"} className="linkView">Regresar</Link>
                    </div>
                </div>

            </div>


            <div className='p-2 shadow my-4 d-flex flex-column justify-content-center'>
                <div className='mt-3 mb-3'>
                    <h2>Datos personales</h2>
                </div>
                <div>

                    <div className="form-group my-2">
                        <span>Correo electrónico</span>
                        <input type="text" defaultValue={userData.userEmail} readOnly className='form-control' />
                    </div>

                    <div className="form-group my-2">
                        <span>Nombre completo</span>
                        <input type="text" onChange={(e) => handleTextChange("userName", e.target.value)} defaultValue={userData.userName} className='form-control' />
                    </div>

                    <div className="col col-4 d-flex flex-column-reverse col-md-12">
                        <button className='btn  btn-primary m-2' onClick={() => updateContactData()}>Actualizar</button>
                    </div>

                </div>
            </div>

            <div className='p-2 shadow my-4 d-flex flex-column justify-content-center'>
                <div className='mt-3 mb-3'>
                    <h2>Contraseña</h2>
                </div>


                <div className='row'>

                    <div className="form-group">
                        <div><span>Ingresar nueva contraseña</span></div>
                        <input type="text" onChange={(ev) => setNewPassword(ev.target.value)} class="form-control mb-2 mt-2"></input>
                    </div>
                    <div className="form-group">
                        <button className='btn  btn-primary m-2' onClick={() => changePassword()}>Actualizar</button>
                    </div>
                </div>
            </div>

            <div className='p-2 shadow my-4 d-flex flex-column justify-content-center'>
                <div className='mt-3 mb-3'>
                    <h2>Eliminar mi cuenta</h2>
                </div>
                <div class="alert alert-danger" role="alert">
                    Al eliminar tu cuenta toda tu información será eliminada permanentemente de nuestros sistemas de acuerdo a nuestra <a href="/privacidad">politica de privacidad</a>
                </div>
                <div className='my-4 d-flex flex-row-reverse'>
                    <button onClick={() => deleteAccount()} className='btn btn-danger btn-full-rounded'>Entiendo, eliminar cuenta</button>
                </div>
            </div>

            <div className='my-4 d-flex flex-row-reverse'>
                <a href="/" className='btn btn-danger' style={{ background: "red", color: "#fff" }}>Cerrar sesión</a>
            </div>
        </div>
    );



}


export default ClientProfile;