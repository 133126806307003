/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../Navigation.css';


/**Transitions */

import app from '../firebase';
const db = app.firestore();






const Login = () => {



    let navigate = useNavigate()
    const [emailUser, setEmailUser] = useState('')
    const [userPassword, setUserPassword] = useState('')

    const handleLogin = () => {

        if (emailUser != "" && userPassword != "") {
            app.auth().signInWithEmailAndPassword(emailUser, userPassword).then((response) => {

                if (response.user.uid == "xXpPuCKayxfEYXgBCxFqOMBrTA22") {
                    navigate('/ad/dashboard');
                    console.log(response.user.uid);
                    sessionStorage.setItem('adminId', response.user.uid)
                    localStorage.setItem('adminId', response.user.uid)
                } else {
                    navigate('/cl/dashboard');
                    console.log(response.user.uid);
                    sessionStorage.setItem('userId', response.user.uid);
                    localStorage.setItem('userId', response.user.uid);
                }


            }).catch((error) => {
                alert("Usuario o contraseña incorrecto");
            })
        }
    }

    return (


        <>
            <div className='row' id='row-pc'>

                <div className="col col-6 background_login"></div>
                <div className="col col-6 login-section">

                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='m-4'>
                            <h2 className='fw-bold'>Inicio de sesión</h2>
                            <div className='mt-4'>
                                <div className='form-group'>
                                    <label className='mt-3'>Usuario</label>
                                    <input type="email" class="form-control mb-2 mt-2" placeholder='Correo electrónico' onChange={(e) => setEmailUser(e.target.value)} ></input>
                                </div>
                                <div className='form-group'>
                                    <label className='mt-3'>Contraseña</label>
                                    <input type="password" class="form-control mb-2 mt-2" placeholder='Contraseña' onChange={(e) => setUserPassword(e.target.value)}></input>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid w-100 d-flex flex-column justify-content-center align-items-center">
                            <div>
                                <button onClick={() => handleLogin()} style={{ width: "300px" }} className="btn bg-warning mt-4">Iniciar sesión</button>
                            </div>

                            <div className="my-2">
                                <Link to={"/signup"} className="linkView mt-2">¿No tienes cuenta? Crea una aquí</Link>
                            </div>

                            <div className="my-2">
                                <Link to={"/reset_password"} className="linkView mt-2">¿Olvidaste tu contraseña?</Link>
                            </div>

                            <div className="my-2">
                                <Link to={"/"} className="linkView mt-2">Volver al inicio</Link>
                            </div>

                            <div>
                                <span style={{ fontSize: "10px" }}>Al ingresar, estás aceptando nuestros <a href="https://idpetsqr.com/terminos" target={"_blank"}>términos y condiciones</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="row-mb">
                <div className='row container new-tag-bg minHeight-100 align-items-center d-flex justify-content-center'>
                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>


                        <div className="my-4">
                            <h2 className='fw-bold'>Inicio de sesión</h2>
                        </div>
                        <div className='my-2'>
                            <div className='form-group'>
                                <label className='mt-3'>Usuario</label>
                                <input type="email" class="form-control mb-2 mt-2" placeholder='Correo electrónico' onChange={(e) => setEmailUser(e.target.value)} ></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Contraseña</label>
                                <input type="password" class="form-control mb-2 mt-2" placeholder='Contraseña' onChange={(e) => setUserPassword(e.target.value)}></input>
                            </div>
                        </div>


                        <div className='d-flex flex-row flex-column container-group mb-3'>
                            <button onClick={() => handleLogin()} className="btn btn-warning fw-bold my-4">Iniciar sesión</button>
                            <div className="text-center">
                                <div className="my-2">
                                    <Link to={"/signup"} className="linkView">¿No tienes cuenta? Crea una aquí</Link>
                                </div>
                                <div className="my-2">
                                    <Link to={"/reset_password"} className="linkView">¿Olvidaste tu contraseña?</Link>
                                </div>
                                <div className="my-2">
                                    <Link to={"/"} className="linkView">Volver al inicio</Link>
                                </div>
                            </div>
                            <div className='text-center'>
                                <span style={{ fontSize: "10px" }}>Al acceder, estás aceptando nuestros <a href="https://idpetsqr.com/terminos" target={"_blank"}>términos y condiciones</a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );



}


export default Login;