import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import app from '../../../firebase';
import { ProgressSpinner } from 'primereact/progressspinner';
const db = app.firestore();

export default function ShowEmail() {

    const { idEmail } = useParams();

    const [loading, setLoading] = useState(true)
    const [emailData, setEmailData] = useState(null)

    useEffect(() => {
        const fetchEmail = async () => {
            try {
                const docRef = db.collection('mail').doc(idEmail);
                const doc = await docRef.get();
                if (doc.exists) {
                    const emailData = doc.data();
                    setEmailData(emailData)
                    setLoading(!loading)
                } else {
                    console.log('No existe un email con ese ID.');
                    setLoading(!loading)
                }
            } catch (error) {
                setLoading(!loading)
                console.error('Error al obtener el email:', error);
            }
        };

        fetchEmail();
    }, [idEmail]);
    return (
        <div>

            {

                loading ?
                    <div className='d-flex justify-content-center'>
                        <ProgressSpinner />
                    </div>
                    :

                    emailData && emailData.message.html ?
                        <div className="container">
                            <div className="my-4 p-4 bg-light-gray rounded shadow" dangerouslySetInnerHTML={{ __html: emailData.message.html }} />
                        </div>
                        :
                        <h2>No hay información del email</h2>
            }
        </div>
    )
}
