/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import FileUploader from '../FileUploader';

import app from '../../../firebase';
const db = app.firestore();
const auth = app.auth()



const GeneralDataForm = () => {

    const [petName, setPetName] = useState('');
    const [telefonoDueno, setTelefonoDueno] = useState('');
    const [bornDate, setBornDate] = useState('');
    const [petSize, setPetSize] = useState('');
    const [petType, setPetType] = useState('');
    const [steril, setSteril] = useState('');
    const [color, setColor] = useState('');
    const [genre, setGenre] = useState('');
    const [microchipId, setMicrochipId] = useState('');
    const [noTatuaje, setNoTatuaje] = useState('');
    const [fileLink, setFileLink] = useState('');
    const [tagId, setTagId] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const convertToISODate = (daysToAdd = 0) => {
        const today = new Date();
        
        // Si se proporciona un número de días, suma esa cantidad de días a la fecha actual
        const futureDate = new Date(today.setDate(today.getDate() + daysToAdd));
    
        // Formatea la fecha en el formato ISO deseado
        const isoDate = futureDate.toISOString().split('T')[0];
        return isoDate;
    };

    const savePet = async () => {



        setFileLink(localStorage.getItem("@fileLink"));
        setTagId(localStorage.getItem("@tagId"));

        const currentUser = auth.currentUser;

        const userId = currentUser.uid;


        if (petName != "" && fileLink != "") {

            const pet_obj = {
                "petName": petName,
                "bornDate": bornDate,
                "petSize": petSize,
                "petType": petType,
                "steril": steril,
                "color": color,
                "genre": genre,
                "microchipId": microchipId,
                "noTatuaje": noTatuaje,
                "petImageLink": fileLink,
                "tagId": tagId,
                "plan": "Demo",
                "userId": userId,
                'showTryForFree': true,
                "telefonoDueno": telefonoDueno,
                "visibleContactInfo": true,
                "visibleVeterinarioInfo": true,
                "visibleMedicamentos": true,
                "visibleAlergias": true,
                "visibleRecompenza": true,
            }

            localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
            setIsLoading(true)

            await db.collection('pets')
                .add(pet_obj)
                .then(async () => {
                    updateTagIdIsActive();
                })
                .catch((err) => console.log(err));
        } else {
            alert("Verifique que su información sea correcta");
        }
    }

    const updateTagIdIsActive = async () => {
        await db.collection("tags").where("tagId", "==", tagId)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    db.collection('tags').doc(doc.id).set({
                        "isActive": true,
                    }, {
                        merge: true
                    }).then(() => {
                        console.log('Completed..')
                        setIsLoading(false)
                        window.location.href = `/tag_saved_alert`;
                    })
                });
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    }

    const razas = ['Mestizo',
        'Affenpinscher',
        'Airedale terrier',
        'Aïdi',
        'Akita Inu',
        'Akita Americano',
        'Alano español',
        'Alaskan malamute',
        'Alaskan Klee Kai',
        'American Hairless terrier',
        'American Staffordshire Terrier',
        'Antiguo Perro Pastor Inglés',
        'Appenzeller',
        'Australian Cattle Dog',
        'Australian terrier',
        'Australian Silky Terrier',
        'Azawakh',
        'Bardino (Perro majorero)',
        'Bandog',
        'Basenji',
        'Basset azul de Gascuña',
        'Basset hound',
        'Beagle',
        'Beauceron',
        'Bedlington terrier',
        'Bergamasco',
        'Bichon frisé',
        'Bichón maltés',
        'Bichón habanero',
        'Bobtail',
        'Bloodhound',
        'Border collie',
        'Borzoi',
        'Boston terrier',
        'Bóxer',
        'Boyero de Berna',
        'Boyero de Flandes',
        'Braco alemán de pelo corto',
        'Braco alemán de pelo duro',
        'Braco de Auvernia',
        'Braco francés',
        'Braco húngaro',
        'Braco italiano',
        'Braco tirolés',
        'Braco de Saint Germain',
        'Braco de Weimar',
        'Bull Terrier',
        'Bulldog americano',
        'Bulldog francés',
        'Bulldog inglés',
        'Bullmastiff',
        'Buhund noruego',
        'Calupoh',
        'Can de palleiro',
        'Caniche',
        'Cão da Serra da Estrela',
        'Cão da Serra de Aires',
        'Cão de Agua Português',
        'Cão de Castro Laboreiro',
        'Cão de Fila de São Miguel',
        'Cavalier King Charles Spaniel',
        'Cazador de alces noruego',
        'Chesapeake Bay Retriever',
        'Chihuahueño',
        'Crestado Chino',
        'Cimarrón Uruguayo',
        'Cirneco del Etna',
        'Chow chow',
        'Clumber spaniel',
        'Cobrador de pelo liso',
        'Cocker spaniel americano',
        'Cocker spaniel inglés',
        'Collie de pelo corto',
        'Collie de pelo largo',
        'Bearded collie',
        'Corgi galés de Cardigan',
        'Dachshund',
        'Dálmata',
        'Dandie Dinmont Terrier',
        'Deerhound',
        'Dobermann',
        'Dogo alemán',
        'Dogo argentino',
        'Dogo de burdeos',
        'Dogo del Tíbet',
        'Dogo guatemalteco',
        'English springer spaniel',
        'Entlebucher',
        'Épagneul bretón',
        'Épagneul français',
        'Epagneul papillón',
        'Eurasier',
        'Fila Brasileiro',
        'Flat-Coated Retriever',
        'Fox Terrier',
        'Foxhound americano',
        'Galgo español',
        'Galgo húngaro',
        'Galgo inglés',
        'Galgo italiano',
        'Golden retriever',
        'Glen of Imaal Terrier',
        'Gran danés',
        'Gegar colombiano',
        'Grifón belga',
        'Hovawart',
        'Husky siberiano',
        'Jack Russell Terrier',
        'Keeshond',
        'Kerry blue terrier',
        'Komondor',
        'Kuvasz',
        'Labrador',
        'Lakeland Terrier',
        'Laekenois',
        'Landseer',
        'Lebrel afgano',
        'Lebrel polaco',
        'Leonberger',
        'Lobero irlandés',
        'Lundehund',
        'Perro lobo de Saarloos',
        'Lhasa apso',
        'Löwchen',
        'Maltés',
        'Malinois',
        'Manchester terrier',
        'Mastín afgano',
        'Mastín del Pirineo',
        'Mastín español',
        'Mastín inglés',
        'Mastín italiano',
        'Mastín napolitano',
        'Mastín tibetano',
        'Mucuchies',
        'Mudi',
        'Münsterländer grande',
        'Münsterländer pequeño',
        'Nova Scotia Duck Tolling Retriever',
        'Ovejero magallánico',
        'Pastor alemán',
        'Pastor belga',
        'Pastor blanco suizo',
        'Pastor catalán',
        'Pastor croata',
        'Pastor garafiano',
        'Pastor holandés',
        'Pastor peruano Chiribaya',
        'Pastor de Brie',
        'Pastor de los Pirineos',
        'Pastor leonés',
        'Pastor mallorquín',
        'Pastor maremmano-abrucés',
        'Pastor de Valée',
        'Pastor vasco',
        'Pekinés',
        'Pembroke Welsh Corgi',
        'Pequeño Lebrel Italiano',
        'Perdiguero francés',
        'Perdiguero portugués',
        'Perro cimarrón uruguayo',
        'Perro de agua americano',
        'Perro de agua español',
        'Perro de agua irlandés',
        'Perro de agua portugués',
        'Perro de Groenlandia',
        'Perro de osos de Carelia',
        'Perro dogo mallorquín',
        'Perro esquimal canadiense',
        'Perro de Montaña de los Pirineos',
        'Perro fino colombiano',
        'Perro pastor de las islas Shetland',
        'Perro peruano sin pelo',
        'Phalène',
        'Pinscher alemán',
        'Pinscher miniatura',
        'Pitbull',
        'Podenco canario',
        'Podenco ibicenco',
        'Podenco portugués',
        'Pointer',
        'Pomerania',
        'Presa canario',
        'Pudelpointer',
        'Pug',
        'Puli',
        'Pumi',
        'Rafeiro do Alentejo',
        'Ratonero bodeguero andaluz',
        'Ratonero mallorquín',
        'Ratonero valenciano',
        'Rhodesian Ridgeback',
        'Rottweiler',
        'Saluki',
        'Samoyedo',
        'San Bernardo',
        'Schapendoes',
        'Schnauzer estándar',
        'Schnauzer gigante',
        'Schnauzer miniatura',
        'Staffordshire Bull Terrier',
        'Sabueso bosnio',
        'Schipperke',
        'Sealyham terrier',
        'Setter inglés',
        'Setter irlandés',
        'Shar Pei',
        'Shiba Inu',
        'Shih Tzu',
        'Shikoku Inu',
        'Siberian husky',
        'Skye terrier',
        'Spaniel japonés',
        'Spaniel tibetano',
        'Spitz enano',
        'Spitz grande',
        'Spitz mediano',
        'Spitz japonés',
        'Sussex spaniel',
        'Teckel',
        'Terranova',
        'Terrier alemán',
        'Terrier brasileño',
        'Terrier checo',
        'Terrier chileno',
        'Terrier de Norfolk',
        'Terrier de Norwich',
        'Terrier escocés',
        'Terrier galés',
        'Terrier irlandés',
        'Terrier ruso negro',
        'Terrier tibetano',
        'Toy spaniel inglés',
        'Tervueren',
        'Vallhund sueco',
        'Volpino italiano',
        'Weimaraner',
        'West Highland White Terrier',
        'Whippet',
        'Wolfsspitz',
        'Xoloitzcuintle',
        'Yorkshire terrier',
    ]

    const [isDog, setIsDog] = useState(false)

    const razasGatos = [
        'Abisinio',
        'Americano de pelo duro',
        'Asiático',
        'Azul ruso',
        'Balinés',
        'Bengalí',
        'Birmano',
        'Bobtail japonés de pelo corto',
        'Bobtail japonés de pelo largo',
        'Bombay',
        'Bosque de Noruega',
        'Bosque de Siberia',
        'Británico de pelo corto',
        'Burmés',
        'Burmilla',
        'Chinchilla',
        'Cornish rex',
        'Cymric',
        'Devon Rex',
        'Domesticos',
        'Europeo',
        'Exótico',
        'Fold escocés',
        'Khao Manee',
        'Korat',
        'Laperm',
        'Maine coon',
        'Manx',
        'Mau egipcio',
        'Mist australiano',
        'Munchkin',
        'Ocigato',
        'Oriental de pelo corto',
        'Oriental de pelo largo',
        'Pagination',
        'Persa',
        'Pixie bob',
        'Ragdoll',
        'Savannah',
        'Selkirk rex',
        'Siamés',
        'Singapura',
        'Snowshoe',
        'Somalí',
        'Sphynx',
        'Tiffanie',
        'Tonquinés',
        'Van turco',

    ];

    return (


        <>

        {/* <div>
        <iframe width="560" height="202" src="https://www.bandlab.com/embed/?id=f8ac8f14-d90a-e911-9f2a-00155d77cb96" frameborder="0" allowfullscreen></iframe>
        </div> */}
            <div className='new-tag-bg container-fluid minHeight-100 justify-content-center d-flex align-items-center'>
                <div className='card h-auto w-auto shadow p-4'>
                    <div className='container'>
                        <div className="d-flex justify-content-center my-4">
                            <span className='text-dark fw-bold display-6'>
                                Datos Generales
                            </span>
                        </div>


                        <label className='small fw-bold'>Foto</label>
                        <FileUploader></FileUploader>

                        <input type="text" className="rounded-2xl form-control mb-2 mt-2" placeholder='Nombre de la mascota' onChange={(e) => setPetName(e.target.value)}></input>
                    </div>

                    <div className='d-flex align-items-center flex-column justify-content-center'>
                        {
                            isLoading ?

                                <button className='btn btn-warning fw-bold text-light btn-full-rounded w-50 my-4'>
                                    <span class="sr-only mx-2">Guardando...</span>
                                    <div class="spinner-border spinner-border-sm text-light" role="status"></div>
                                </button>
                                :
                                <button onClick={() => savePet()} className="btn btn-warning fw-bold text-light btn-full-rounded w-50 my-4">Guardar</button>
                        }

                        <Link to={"/cl/dashboard"} className="linkView mt-2">Cancelar</Link>
                    </div>
                </div>
            </div>
        </>
    );
}


export default GeneralDataForm;