import app from '../firebase';
import { getBearerAccessToken } from './tools';

const db = app.firestore();
const auth = app.auth();


export const addSuscriptionDataToPet = async (suscriptionData, plan, petId) => {
    try {
        const currentUser = auth.currentUser;
        const userId = currentUser.uid;

        await db.collection("pets")
            .where("tagId", "==", petId) // Realiza la consulta para encontrar los documentos con el campo "userId" igual a userId
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // Una vez que encuentras los documentos, actualízalos individualmente
                    db.collection("pets").doc(doc.id).update({
                        plan: plan,
                        demoPro: false,
                        fechaFinSuscripcion: 0,
                        subscription: suscriptionData
                    });
                });
            })


        alert(`Te has suscrito correctamente al plan ${plan}, ¡Gracias por tu compra!`)

        await getUserDataByUserId(userId).then((userData) => {
            db.collection("mail")
                .add({
                    to: userData.userEmail,
                    message: {
                        subject: `${userData.userName} Te damos la bienvenida a IDPETS PRO`,
                        text: "Bienvenido a IDPETS",
                        html: `
                    <p>Nos da mucho gusto tenerte aquí ${userData.userName}</p>
                    <p>&nbsp;</p>
                    <p style="padding-left: 40px;">Empieza a cuidar de tu mascota desde hoy, configura su perfil, <a href="https://idpetsqr.com/login" rel="noopener noreferrer" target="_blank">Ingresa aquí</a></p>
                    <p style="padding-left: 40px;">Disfruta todos las herramientas que pensamos para cuidar a tu mascota, <a href="https://idpetsqr.com/login" rel="noopener noreferrer" target="_blank">Empieza aquí</a></p>
                    <p style="padding-left: 40px;">&nbsp;</p>
                    <p style="padding-left: 40px;">Con mucho cariño, por parte del equipo de IDPETS</p>
                    <center>
                    <p><img src="https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/archivos%2FWhatsApp%20Image%202022-04-25%20at%209.46.14%20PM.jpeg?alt=media&amp;token=31c371e6-db33-4dd6-8319-053bd391004c" alt="" width="358" height="76"></p>
                    </center>
                    `,
                    },
                }).then(() => {
                    window.location.reload()
                });
        })

        return true;
    } catch (error) {
        alert("Error al actualizar: " + error.message);
        return false;
    }
};

export const getUserDataByUserId = async (userId) => {
    try {
        // Consulta la colección 'users' por el documento con el campo 'userId' igual a userId
        const userDocRef = db.collection('usuarios').where('userId', '==', userId);

        // Realiza la consulta y obtén el primer resultado (asumiendo que solo hay un documento con el mismo userId)
        const querySnapshot = await userDocRef.get();

        if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            return userData;
        } else {
            throw new Error('Usuario no encontrado');
        }
    } catch (error) {
        console.error('Error al obtener los datos del usuario:', error);
        throw error; // Puedes manejar este error según tus necesidades
    }
};

export const cancelSubscription = async (reason) => {

    const currentUser = auth.currentUser;
    const userId = currentUser.uid;
    let userData = null

    const userRef = db.collection('users').doc(userId);
    userRef.get().then((doc) => {
        userData = doc.data()
    });

    const access_token = await getBearerAccessToken();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${access_token}`);

    const url = `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${userData.subscription.subscriptionID}/cancel`;

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(url, requestOptions)
        .then(response => {
            if (response.status === 204) {
                db.collection("users").doc(userId).update({
                    "plan": "Demo",
                    "subscription.subscriptionStatus": "CANCELLED"
                }).then(() => {
                    alert("Tu suscripción se ha cancelado correctamente")
                    window.location.reload()
                })
            } else {
                console.log('La operación no fue exitosa');
            }
        }).catch(error => console.log('error', error));
}


