import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTable, useSortBy, usePagination } from 'react-table'
import app from '../../../firebase'
import { Timestamp } from 'firebase/firestore'
import { useMemo } from 'react'
const db = app.firestore()


function TableSearch() {


    const [data, setData] = useState([]);



    function Table({ columns, data }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            page, // Instead of using 'rows', we'll use page,
            // which has only the rows for the active page

            // The rest of these things are super handy, too ;)
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
        } = useTable(
            {
                columns,
                data,
            },
            useSortBy,
            usePagination
        )


        return (
            <>
                <table className='table table-striped' {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    // Add the sorting props to control sorting. For this example
                                    // we can add them into the header props
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(
                            (row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                </table>

                <div>
                    <span className='fs-xsm'>
                        Mostrando {pageSize} de {rows.length} resultados
                    </span>
                </div>

                <div className="pagination">
                    <div className="container py-2">

                        <div className="row row-cols-3">
                            <div className="col-12 col-md-2">
                                <button className='btn' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    {'<<'}
                                </button>
                                <button className='btn' onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    {'<'}
                                </button>
                                <button className='btn' onClick={() => nextPage()} disabled={!canNextPage}>
                                    {'>'}
                                </button>
                                <button className='btn' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                    {'>>'}
                                </button>
                            </div>
                            <div className="col-12 col-md-3">
                                <div className='d-flex align-items-center'>
                                    <span className='fs-xsm'> Página<strong> {pageIndex + 1} de {pageOptions.length} </strong></span>
                                    <span className='mx-2 fs-xsm'>| Ir a</span>
                                    <input
                                        className='mx-2 form-control fs-xsm form-control-sm'
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-7">
                                <div className="d-flex flex-row">
                                    <select
                                        className='form-select w-25 form-select-sm'
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value))
                                        }}
                                    >
                                        {[10, 20, 30, 40, 50].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Mostrar {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }




    const columns = useMemo(
        () => [
            {
                Header: 'TagsDemo',
                columns: [

                    {
                        Header: 'Fecha de creación',
                        accessor: 'created_at',
                    },
                    {
                        Header: 'Usuario',
                        accessor: 'usuarioNombre',
                    },
                    {
                        Header: 'Tag id',
                        accessor: 'tagId',
                    },
                    {
                        Header: 'Nombre mascota',
                        accessor: 'nombreMascota',
                    },
                    {
                        Header: 'Correo',
                        accessor: 'correo',
                    },
                    {
                        Header: 'Teléfono',
                        accessor: 'telefono',
                    },
                ],
            },
        ],
        []
    )

    // async function updateDocuments() {
    //     const snapshot = await db.collection("demoTags").get();
    //     const batch = db.batch();

    //     snapshot.docs.forEach((doc) => {
    //         const data = doc.data();
    //         if (data.created_at && /^\d{2}\/\d{2}\/\d{4}$/.test(data.created_at)) {
    //             const dateParts = data.created_at.split("/");
    //             const dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    //             const timestamp = Timestamp.fromDate(dateObject);
    //             batch.update(doc.ref, { created_at: timestamp });
    //         }
    //     });

    //     await batch.commit();
    // }
    useEffect(() => {
        const unsubscribe = db.collection("demoTags")
            .orderBy("created_at", "asc").orderBy("usuarioNombre","asc")
            .onSnapshot((snapshot) => {
                setData(
                    snapshot.docs.map((doc) => {
                        const docData = doc.data();
                        // console.log(`DOCID:${docData.tagId}: ${docData.created_at} ${typeof (docData.created_at)}`)
                        return { ...docData, id: doc.id };
                    })
                );
            });
        return () => unsubscribe();
    }, []);

    return (
        <div>
            <Table columns={columns} data={data} />
        </div>
    )
}

export default TableSearch
