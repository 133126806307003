/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
import FileUploader from '../FileUploader';
const db = app.firestore();


const MedicineForms = () => {

    const [medicacion, setMedicacion] = useState('');
    const [proposito, setProposito] = useState('');
    const [notas, setNotas] = useState('');
    const [frecuenciaDiaria, setFrecuenciaDiaria] = useState('');
    const [tiempoDosis, setTiempoDosis] = useState('');
    const [fechaInicio, setFechaInicio] = useState('');
    const [duracion, setDuracion] = useState('');
    const [dias, setDias] = useState('');


    const savePet = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj)


        pet_obj["medicacion"] = medicacion;
        pet_obj["proposito"] = proposito;
        pet_obj["notas"] = notas;
        pet_obj["frecuenciaDiaria"] = frecuenciaDiaria;
        pet_obj["tiempoDosis"] = tiempoDosis;
        pet_obj["fechaInicio"] = fechaInicio;
        pet_obj["duracion"] = duracion;
        pet_obj["dias"] = dias;

        localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
        window.location.href = "/tag_allergy_form";


    }



    return (


        <>

            <div className='new-tag-bg container-fluid minHeight-100 justify-content-center d-flex align-items-center'>
                <div className='card h-auto w-auto shadow p-4'>
                    <div className='container'>
                        <div className="d-flex justify-content-center my-4">
                            <span className='text-dark fw-bold display-6'>
                                Medicamentos
                            </span>
                        </div>

                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Medicación' onChange={(e) => setMedicacion(e.target.value)} ></input>


                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Propósito' onChange={(e) => setProposito(e.target.value)} ></input>



                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Notas' onChange={(e) => setNotas(e.target.value)} ></input>



                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Frecuencia diaria' onChange={(e) => setFrecuenciaDiaria(e.target.value)}></input>



                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Tiempo y dosis' onChange={(e) => setTiempoDosis(e.target.value)} ></input>

                        <label htmlFor="">Fecha inicio</label>
                        <input type="date" class=" form-control mb-2 mt-2" onChange={(e) => setFechaInicio(e.target.value)} ></input>

                        <label htmlFor="">Duración</label>
                        <input type="text" class=" form-control mb-2 mt-2" placeholder='Escriba la duración' onChange={(e) => setDuracion(e.target.value)} ></input>


                        <input type="number" min={1} class=" form-control mb-2 mt-2" placeholder='Días' onChange={(e) => setDias(e.target.value)} ></input>



                    </div>

                    <div className='d-flex align-items-center flex-column justify-content-center'>

                        <button onClick={() => savePet()} className="btn btn-warning fw-bold text-light btn-full-rounded w-50 my-4">Siguiente</button>
                        <Link to={"/tag_allergy_form"} className="linkView">Omitir</Link>

                    </div>
                </div>
            </div>
        </>
    );



}


export default MedicineForms;