/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { useSearchParams, useParams, useNavigate, Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';

/**Transitions */

import app from '../../firebase';

import FileUploader from './FileUploader';
import FadeInLeft from '../../animations/FadeInLeft';
import SubscriptionsContainer from '../subscriptionsCard/SubscriptionsContainer';
import Swal from 'sweetalert2';

const db = app.firestore();
const auth = app.auth();


const PetDetails = () => {


    let params = useParams();

    const [showSubscriptionCard, setShowSubscriptionCard] = useState(false)
    const [planAuxiliar, setPlanAuxiliar] = useState("")

    let navigate = useNavigate();

    const [petData, setPetData] = useState({});
    let [petID, setPetID] = useState('')

    const [plan, setPlan] = useState('')
    const [diasVigentes, setDiasVigentes] = useState(null)



    const convertToISODate = (daysToAdd = 0) => {
        const today = new Date();

        // Si se proporciona un número de días, suma esa cantidad de días a la fecha actual
        const futureDate = new Date(today.setDate(today.getDate() + daysToAdd));

        // Formatea la fecha en el formato ISO deseado
        const isoDate = futureDate.toISOString().split('T')[0];
        return isoDate;
    };

    const updateToProDemo = (tagId) => {

        Swal.fire({
            title: `Activa tu prueba PRO`,
            text: `Terminará ${convertToISODate(7)}, ¿Deseas continuar?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, más adelante",
            confirmButtonText: "Sí activar PRO",
            reverseButtons: true,
            customClass: {
                cancelButton: "btn-secondary-2" // Aquí puedes agregar la clase deseada al botón de cancelar
            }
        }).then((result) => {
            if (result.isConfirmed) {


                const isoStartDate = convertToISODate();
                const isoEndDate = convertToISODate(7); // Sumar 7 días a la fecha actual

                // Buscar el documento con el tagId proporcionado
                db.collection('pets').where("tagId", "==", tagId).get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            // Actualizar los campos especificados con merge true
                            db.collection('pets').doc(doc.id).set({
                                'showTryForFree': false,
                                'demoPro': true,
                                'fechaInicioSuscripcion': isoStartDate,
                                'fechaFinSuscripcion': isoEndDate,
                                'hasActivedPro': true
                            }, { merge: true })
                                .then(() => {

                                    db.collection('pruebasActivas').add({
                                        ...petData,
                                        'demoPro': true,
                                        'fechaInicioSuscripcion': isoStartDate,
                                        'fechaFinSuscripcion': isoEndDate,
                                    });

                                    Swal.fire({
                                        title: "¡Listo!",
                                        text: `Haz activado tu prueba PRO para ${petData.petName}, disfruta de todas las herramientas que pensamos para tu mascota.`,
                                        icon: "success",
                                        confirmButtonText: "Aceptar"
                                    });
                                })
                                .catch((error) => {
                                    Swal.fire({
                                        title: "Ops!",
                                        text: "No fue posible activar tu prueba PRO por favor contacta a soporte técnico. Código de erorr" + error,
                                        icon: "error"
                                    });
                                });
                        });
                    })
                    .catch((error) => {
                        console.error("Error al buscar el tagId en la colección pets:", error);
                    });
            }
        });
    };

    const recoverDataPet = () => {
        db.collection('pets').where("tagId", "==", params.idPet).onSnapshot((querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
                setPetID(doc.id)
                setPetData(doc.data())

                var auxData = doc.data();

                switch (true) {
                    case auxData.subscription?.subscriptionStatus === 'ACTIVE' && auxData.subscription.subscription_nextBillingTime:
                        const fechaObjetivoActive = auxData.subscription.subscription_nextBillingTime;
                        const mensajeActive = calcularDiasRestantes(fechaObjetivoActive);

                        console.log("fechaObjetivo ", fechaObjetivoActive);
                        setDiasVigentes((mensajeActive + 1));
                        setPlan(auxData.plan);
                        break;

                    case auxData.demoPro === true:
                        const fechaObjetivoDemoPro = auxData.fechaFinSuscripcion;

                        if (fechaObjetivoDemoPro) {
                            const mensajeDemoPro = calcularDiasRestantes(fechaObjetivoDemoPro);

                            if (mensajeDemoPro === false) {
                                setPlan("Demo");
                            } else {
                                setPlan("Pro");
                                setDiasVigentes((mensajeDemoPro + 1));
                            }
                        } else {
                            setPlan("Demo");
                        }
                        break;

                    case auxData.subscription?.subscriptionStatus === 'FALSE':
                        const fechaObjetivoFalse = auxData.subscription.subscription_nextBillingTime;

                        if (fechaObjetivoFalse) {
                            const mensajeFalse = calcularDiasRestantes(fechaObjetivoFalse);

                            if (mensajeFalse === false) {
                                setPlan("Demo");
                                console.log(mensajeFalse);
                            } else {
                                setPlan("Pro");
                                console.log("SE CAMBIO A PRO POR VIGENCIA DE SUSCRIPCION DE: ", mensajeFalse);
                                setDiasVigentes((mensajeFalse + 1));
                            }
                        } else {
                            console.log("Fecha objetivo no definida");
                            setPlan("Demo");
                        }
                        break;

                    case auxData.subscription === undefined:
                        setPlan("Demo");
                        break;

                    case auxData.plan === "Demo":
                        setPlan("Demo");
                        break;

                    default:
                        // Manejo por defecto, por ejemplo, si ninguna condición se cumple
                        break;
                }
            });
        });
    };



    const [reward, setReward] = useState();
    const updateReward = () => {
        db.collection('pets').doc(petID).set({ "recompensa": reward }, { merge: true }).then(() => {
            alert("Recompensa actualizada")
            window.location.reload();
        }).catch((error) => {
            alert("No se pudo actualizar la recompensa, intente más tarde, código de error ", error.message)
        })
    }

    const [numeroWhatsApp, setNumeroWhatsApp] = useState();
    const updateNumeroWhatsApp = () => {
        db.collection('pets').doc(petID).set({ "numeroWhatsApp": !numeroWhatsApp.length ? " " : numeroWhatsApp }, { merge: true }).then(() => {
            alert("Número de WhatsApp actualizado")
            window.location.reload();
        }).catch((error) => {
            alert("No se pudo actualizar el número de WhatsApp, intente más tarde, código de error ", error.message)
        })
    }


    const [emergencyMails, setEmergencyMails] = useState('');
    const updateEmergencyMails = () => {
        db.collection('pets').doc(petID).set({ "correosEmergencia": emergencyMails }, { merge: true }).then(() => {
            alert("Correos de emergencia actualizados")
            window.location.reload();
        }).catch((error) => {
            alert("No se pudieron actualizar los correos de emergencia, intente más tarde, código de error ", error.message)
        })
    }

    const [verterinatioState, setVeterinarioState] = useState({})

    const handleChangeVeterinarioData = (name, value) => {
        console.log(value.length)
        setVeterinarioState({ ...verterinatioState, [name]: value })
    }
    const updateVeterinarioData = () => {
        db.collection('pets').doc(petID).set(verterinatioState, {
            merge: true
        }).then(() => {
            alert("Información de veterinario actualizada");
            window.location.reload();
        }).catch((error) => {
            alert("No se pudo actualizar la información del veterinario, intente más tarde, código de error: " + error.message);
        });
    };


    const [medicamentoState, setMedicamentoState] = useState({})

    const handleChangeMedicamentoState = (name, value) => {
        setMedicamentoState({ ...medicamentoState, [name]: value })
    }

    const updateMedicineData = () => {
        db.collection('pets').doc(petID).set(medicamentoState, {
            merge: true
        }).then(() => {
            alert("Información de medicamentos actualizada")
            window.location.reload();
        }).catch((error) => {
            alert("No se pudo actualizar la información, intente más tarde, código de error ", error.message)
        })
    }


    const [allergyState, setAllergyState] = useState({})

    const handleChangeAllergyState = (name, value) => {
        setAllergyState({ ...allergyState, [name]: value })
    }
    const updateAllergyData = () => {
        db.collection('pets').doc(petID).set(allergyState, {
            merge: true
        }).then(() => {
            alert("Información de alergias actualizada")
            window.location.reload();
        }).catch((error) => {
            alert("No se pudo actualizar la información, intente más tarde, código de error ", error.message)
        })
    }


    const [contactData, setContactData] = useState({})

    const handleContactDataTextChange = (name, value) => {
        setContactData({ ...contactData, [name]: value })
    }
    const [generalData, setGeneralData] = useState({})

    const handleGeneralDataTextChange = (name, value) => {
        setGeneralData({ ...generalData, [name]: value })
    }

    const updateGeneralData = () => {
        db.collection('pets').doc(petID).set(generalData, {
            merge: true
        }).then(() => {
            alert("Información general actualizada")
            window.location.reload();
        }).catch((error) => {
            alert("No se pudo actualizar la información, intente más tarde, código de error ", error.message)
        })
    }

    const updateContactData = () => {
        db.collection('pets').doc(petID).set(contactData, {
            merge: true
        }).then(() => {
            alert("Información de contacto actualizada")
            window.location.reload();
        }).catch((error) => {
            alert("No se pudo actualizar la información, intente más tarde, código de error ", error.message)
        })
    }

    const restartTag = () => {
        if (window.confirm("¿Realmente deseas reiniciar el Tag?")) {
            db.collection('pets').doc(petID).get().then((doc) => {
                if (doc.exists) {
                    const { fechaFinSuscripcion, fechaInicioSuscripcion, plan, subscription, tagId, userId } = doc.data();

                    // Create a new object with the fields you want to keep
                    const updatedData = {
                        fechaFinSuscripcion,
                        fechaInicioSuscripcion,
                        plan,
                        subscription,
                        tagId,
                        userId
                    };

                    // Update the document with the modified data
                    db.collection('pets').doc(petID).set(updatedData).then(() => {
                        // updateTagIdIsActive();
                        alert("Tag reiniciado")
                        window.location.href = "/cl/pets_list_all"
                    }).catch((error) => {
                        console.error("Error updating document: ", error);
                    });
                } else {
                    console.error("Document not found");
                }
            }).catch((error) => {
                console.error("Error getting document: ", error);
            });
        }
    };

    const updateTagIdIsActive = async () => {

        await db.collection("tags").where("tagId", "==", params.idPet)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    db.collection('tags').doc(doc.id).update({
                        "isActive": false,
                    }).then(() => {
                        alert('Tag reinciado');
                        console.log('Completed..');
                        window.location.href = "/cl/pets_list_all";

                    })
                });
            })

    }


    // const [fileLink, setFileLink] = useState('');
    const updatePhoto = () => {

        let fileLink = localStorage.getItem("@fileLink")


        if (fileLink == "") {
            alert("No se ha seleccionado ninguna foto")
        } else {
            db.collection('pets').doc(petID).update({
                "petImageLink": fileLink,
            }).then(() => {
                alert("Foto de la mascota actualizada")
                window.location.reload();
            }).catch((error) => {
                alert("No se pudo actualizar la foto, intente más tarde, código de error ", error.message)
            })
        }
    }

    // Contacto
    const changeVisibleContactInfo = (value) => {
        db.collection("pets").doc(petID).set({
            "visibleContactInfo": value
        }, {
            merge: true
        }).then(() => {
            alert("Información actualizada correctamente");
        }).catch(() => {
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // Veterinario
    const changeVisibleVeterinarioInfo = (value) => {
        db.collection("pets").doc(petID).set({
            "visibleVeterinarioInfo": value
        }, {
            merge: true
        }).then(() => {
            alert("Información actualizada correctamente");
        }).catch(() => {
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // Medicamentos
    const changeVisibleMedicamentos = (value) => {
        db.collection("pets").doc(petID).set({
            "visibleMedicamentos": value
        }, {
            merge: true
        }).then(() => {
            alert("Información actualizada correctamente");
        }).catch(() => {
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // Alergias

    const changeVisibleAlergias = (value) => {
        db.collection("pets").doc(petID).set({
            "visibleAlergias": value
        }, {
            merge: true
        }).then(() => {
            alert("Información actualizada correctamente");
        }).catch(() => {
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // Recompenza
    const changeVisibleRecompenza = (value) => {
        console.log("change")
        console.log(value)
        db.collection("pets").doc(petID).set({
            "visibleRecompenza": value
        }, {
            merge: true
        }).then(() => {
            alert("Información actualizada correctamente");
        }).catch(() => {
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }



    // Update data

    const handleChangeUpdateText = (name, value) => {
        setUpdateState({ ...updateState, [name]: value })
    }

    const [updateState, setUpdateState] = useState({});

    const updateData = () => {

        db.collection("pets").doc(petID).set(updateState, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }



    const [showCancelCard, setShowCancelCard] = useState(false);
    const [razonCancelar, setRazonCancelar] = useState("")
    const [fraseUsuario, setFraseUsuario] = useState("")

    function validarPalabraEnFrase(fraseUsuario, palabraCorrecta) {
        // Convierte la frase y la palabra a minúsculas para hacer la comparación insensible a mayúsculas
        const fraseUsuarioMin = fraseUsuario.toLowerCase();
        const palabraCorrectaMin = palabraCorrecta.toLowerCase();

        // Verifica si la palabra está presente en la frase
        const esCorrecto = fraseUsuarioMin.includes(palabraCorrectaMin);

        return esCorrecto;
    }

    const getBearerAccessToken = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Basic QVJ5SE9aYUE0dEMwak1kbHNPSnp3RXNPWUxDdURwUm9jUk1COEJxbnJXcm9CV0xIMXg5eGlLVUxIaXNmSk1JNjFzYnVMWTdEcUlyRDAzMWg6RU11WlllYmY3ZWp2QmRBcFQ1UFZnd0hSQkJtZ0hEZkYtSUpwVVdtSmhwN3MzZHBOVGhaSzFlOHdRV01Tc1poYzl2TzdkejV0dnlKdURFS1o=");

        var urlencoded = new URLSearchParams();
        urlencoded.append("grant_type", "client_credentials");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch("https://api-m.paypal.com/v1/oauth2/token?_ga=2.197096236.1638088848.1692680276-2047062090.1685468217", requestOptions);
        const result = await response.json();
        return result.access_token;
    }

    const cancelSubscription = async (reason) => {

        const access_token = await getBearerAccessToken();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${access_token}`);

        const url = `https://api-m.paypal.com/v1/billing/subscriptions/${petData.subscription.subscriptionID}/cancel`;

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(url, requestOptions)
            .then(response => {
                if (response.status === 204) {
                    db.collection("pets").where("tagId", "==", petData.tagId).get()
                        .then((querySnapshot) => {
                            // Iterar sobre los documentos que coinciden con la búsqueda
                            querySnapshot.forEach((doc) => {
                                // Actualizar cada documento encontrado
                                doc.ref.update({
                                    "plan": "Demo",
                                    "subscription.subscriptionStatus": "FALSE"
                                }).then(() => {
                                    alert("La suscripción se ha cancelado correctamente");
                                    window.location.reload();
                                }).catch((error) => {
                                    console.error("Error al actualizar documento:", error);
                                });
                            });
                        })
                } else {
                    console.log('La operación no fue exitosa');
                }
            }).catch(error => console.log('error', error));
    }


    const handleValidarPalabraCancelacion = async (palabraCorrecta) => {
        const esValido = validarPalabraEnFrase(fraseUsuario, palabraCorrecta);
        if (esValido) {
            await cancelSubscription(razonCancelar)
        } else {
            alert("La palabra ingresada es incorrecta");
        }
    };



    const [canActivateWhatsApp, setCanActivateWhatsApp] = useState(true)
    const handleWhatsAppChange = (text) => {
        if (text.length <= 10) {
            setNumeroWhatsApp(text)
            setCanActivateWhatsApp(true)
        } else {
            setCanActivateWhatsApp(false)
        }
    }


    const razas = ['Mestizo',
        'Affenpinscher',
        'Airedale terrier',
        'Aïdi',
        'Akita Inu',
        'Akita Americano',
        'Alano español',
        'Alaskan malamute',
        'Alaskan Klee Kai',
        'American Hairless terrier',
        'American Staffordshire Terrier',
        'Antiguo Perro Pastor Inglés',
        'Appenzeller',
        'Australian Cattle Dog',
        'Australian terrier',
        'Australian Silky Terrier',
        'Azawakh',
        'Bardino (Perro majorero)',
        'Bandog',
        'Basenji',
        'Basset azul de Gascuña',
        'Basset hound',
        'Beagle',
        'Beauceron',
        'Bedlington terrier',
        'Bergamasco',
        'Bichon frisé',
        'Bichón maltés',
        'Bichón habanero',
        'Bobtail',
        'Bloodhound',
        'Border collie',
        'Borzoi',
        'Boston terrier',
        'Bóxer',
        'Boyero de Berna',
        'Boyero de Flandes',
        'Braco alemán de pelo corto',
        'Braco alemán de pelo duro',
        'Braco de Auvernia',
        'Braco francés',
        'Braco húngaro',
        'Braco italiano',
        'Braco tirolés',
        'Braco de Saint Germain',
        'Braco de Weimar',
        'Bull Terrier',
        'Bulldog americano',
        'Bulldog francés',
        'Bulldog inglés',
        'Bullmastiff',
        'Buhund noruego',
        'Calupoh',
        'Can de palleiro',
        'Caniche',
        'Cão da Serra da Estrela',
        'Cão da Serra de Aires',
        'Cão de Agua Português',
        'Cão de Castro Laboreiro',
        'Cão de Fila de São Miguel',
        'Cavalier King Charles Spaniel',
        'Cazador de alces noruego',
        'Chesapeake Bay Retriever',
        'Chihuahueño',
        'Crestado Chino',
        'Cimarrón Uruguayo',
        'Cirneco del Etna',
        'Chow chow',
        'Clumber spaniel',
        'Cobrador de pelo liso',
        'Cocker spaniel americano',
        'Cocker spaniel inglés',
        'Collie de pelo corto',
        'Collie de pelo largo',
        'Bearded collie',
        'Corgi galés de Cardigan',
        'Dachshund',
        'Dálmata',
        'Dandie Dinmont Terrier',
        'Deerhound',
        'Dobermann',
        'Dogo alemán',
        'Dogo argentino',
        'Dogo de burdeos',
        'Dogo del Tíbet',
        'Dogo guatemalteco',
        'English springer spaniel',
        'Entlebucher',
        'Épagneul bretón',
        'Épagneul français',
        'Epagneul papillón',
        'Eurasier',
        'Fila Brasileiro',
        'Flat-Coated Retriever',
        'Fox Terrier',
        'Foxhound americano',
        'Galgo español',
        'Galgo húngaro',
        'Galgo inglés',
        'Galgo italiano',
        'Golden retriever',
        'Glen of Imaal Terrier',
        'Gran danés',
        'Gegar colombiano',
        'Grifón belga',
        'Hovawart',
        'Husky siberiano',
        'Jack Russell Terrier',
        'Keeshond',
        'Kerry blue terrier',
        'Komondor',
        'Kuvasz',
        'Labrador',
        'Lakeland Terrier',
        'Laekenois',
        'Landseer',
        'Lebrel afgano',
        'Lebrel polaco',
        'Leonberger',
        'Lobero irlandés',
        'Lundehund',
        'Perro lobo de Saarloos',
        'Lhasa apso',
        'Löwchen',
        'Maltés',
        'Malinois',
        'Manchester terrier',
        'Mastín afgano',
        'Mastín del Pirineo',
        'Mastín español',
        'Mastín inglés',
        'Mastín italiano',
        'Mastín napolitano',
        'Mastín tibetano',
        'Mucuchies',
        'Mudi',
        'Münsterländer grande',
        'Münsterländer pequeño',
        'Nova Scotia Duck Tolling Retriever',
        'Ovejero magallánico',
        'Pastor alemán',
        'Pastor belga',
        'Pastor blanco suizo',
        'Pastor catalán',
        'Pastor croata',
        'Pastor garafiano',
        'Pastor holandés',
        'Pastor peruano Chiribaya',
        'Pastor de Brie',
        'Pastor de los Pirineos',
        'Pastor leonés',
        'Pastor mallorquín',
        'Pastor maremmano-abrucés',
        'Pastor de Valée',
        'Pastor vasco',
        'Pekinés',
        'Pembroke Welsh Corgi',
        'Pequeño Lebrel Italiano',
        'Perdiguero francés',
        'Perdiguero portugués',
        'Perro cimarrón uruguayo',
        'Perro de agua americano',
        'Perro de agua español',
        'Perro de agua irlandés',
        'Perro de agua portugués',
        'Perro de Groenlandia',
        'Perro de osos de Carelia',
        'Perro dogo mallorquín',
        'Perro esquimal canadiense',
        'Perro de Montaña de los Pirineos',
        'Perro fino colombiano',
        'Perro pastor de las islas Shetland',
        'Perro peruano sin pelo',
        'Phalène',
        'Pinscher alemán',
        'Pinscher miniatura',
        'Pitbull',
        'Podenco canario',
        'Podenco ibicenco',
        'Podenco portugués',
        'Pointer',
        'Pomerania',
        'Presa canario',
        'Pudelpointer',
        'Pug',
        'Puli',
        'Pumi',
        'Rafeiro do Alentejo',
        'Ratonero bodeguero andaluz',
        'Ratonero mallorquín',
        'Ratonero valenciano',
        'Rhodesian Ridgeback',
        'Rottweiler',
        'Saluki',
        'Samoyedo',
        'San Bernardo',
        'Schapendoes',
        'Schnauzer estándar',
        'Schnauzer gigante',
        'Schnauzer miniatura',
        'Staffordshire Bull Terrier',
        'Sabueso bosnio',
        'Schipperke',
        'Sealyham terrier',
        'Setter inglés',
        'Setter irlandés',
        'Shar Pei',
        'Shiba Inu',
        'Shih Tzu',
        'Shikoku Inu',
        'Siberian husky',
        'Skye terrier',
        'Spaniel japonés',
        'Spaniel tibetano',
        'Spitz enano',
        'Spitz grande',
        'Spitz mediano',
        'Spitz japonés',
        'Sussex spaniel',
        'Teckel',
        'Terranova',
        'Terrier alemán',
        'Terrier brasileño',
        'Terrier checo',
        'Terrier chileno',
        'Terrier de Norfolk',
        'Terrier de Norwich',
        'Terrier escocés',
        'Terrier galés',
        'Terrier irlandés',
        'Terrier ruso negro',
        'Terrier tibetano',
        'Toy spaniel inglés',
        'Tervueren',
        'Vallhund sueco',
        'Volpino italiano',
        'Weimaraner',
        'West Highland White Terrier',
        'Whippet',
        'Wolfsspitz',
        'Xoloitzcuintle',
        'Yorkshire terrier',
    ]

    const [isDog, setIsDog] = useState(false)

    const razasGatos = [
        'Abisinio',
        'Americano de pelo duro',
        'Asiático',
        'Azul ruso',
        'Balinés',
        'Bengalí',
        'Birmano',
        'Bobtail japonés de pelo corto',
        'Bobtail japonés de pelo largo',
        'Bombay',
        'Bosque de Noruega',
        'Bosque de Siberia',
        'Británico de pelo corto',
        'Burmés',
        'Burmilla',
        'Chinchilla',
        'Cornish rex',
        'Cymric',
        'Devon Rex',
        'Domesticos',
        'Europeo',
        'Exótico',
        'Fold escocés',
        'Khao Manee',
        'Korat',
        'Laperm',
        'Maine coon',
        'Manx',
        'Mau egipcio',
        'Mist australiano',
        'Munchkin',
        'Ocigato',
        'Oriental de pelo corto',
        'Oriental de pelo largo',
        'Pagination',
        'Persa',
        'Pixie bob',
        'Ragdoll',
        'Savannah',
        'Selkirk rex',
        'Siamés',
        'Singapura',
        'Snowshoe',
        'Somalí',
        'Sphynx',
        'Tiffanie',
        'Tonquinés',
        'Van turco',

    ];


    const [ruac, setRuac] = useState("")
    const [ruacLink, setRuacLink] = useState("")
    const updateRUAC = () => {
        db.collection('pets').doc(petID).set({ "ruac": !ruac.length ? " " : ruac, "ruacLink": !ruacLink.length ? " " : ruacLink }, { merge: true }).then(() => {
            alert("RUAC registrado correctamente")
            window.location.reload();
        }).catch((error) => {
            alert("No se pudo registrar el RUAC, intente más tarde, código de error ", error.message)
        })
    }


    const createCarnet = () => {
        if (petData == "") {

            return (
                <>
                    <div className="my-4">
                        <center>
                            <h2>Ups...</h2>
                            <h4>No hemos encontrado información del tag</h4>
                            <h5>También es posible que aún no se haya registrado la información del tag</h5>
                            <div className='m-2'>
                                <a href='/cl/pets_list_all' className='btn btn-warning fw-bold btn btn-warning fw-bold-primat'>Regresar</a>
                            </div>
                        </center>
                    </div>

                </>
            )
        } else {
            return (

                <>

                    <h2 className='display-6 fw-bold py-4'>Detalles de {petData.petName}</h2>

                    <Link to={"/cl/pets_list_all"} className="bg-dark text-white  p-1 rounded-2xl">
                        {'<'} Volver
                    </Link>

                    <center>

                        {/* Foto */}
                        <div className="shadow p-2 my-3">
                            <div style={{ backgroundImage: `url(${petData.petImageLink ? petData.petImageLink : "https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2F_30dc433e-92a5-48d4-bc38-e3c1a8a7d30a%202.png?alt=media&token=d90b99b6-d303-488f-8a2b-8647baec5fe9"})`, borderRadius: "50%", width: "200px", height: "200px" }} className="pet-circle-image-details shadow my-4"></div>
                            <h5 className='display-6 fw-bold'>Actualiza la foto de {petData.petName ? petData.petName : "Mi mascota"}</h5>
                            <p>Sube una foto</p>
                            <FileUploader></FileUploader>
                            <div className='form-group'>
                                <button onClick={() => updatePhoto()} className="btn rounded-2xl btn-warning fw-bold">Actualizar foto</button>
                            </div>
                        </div>

                        {
                            plan == "Demo" ?
                                <div className="bg-paw-dog  rounded-2xl shadow p-4">
                                    <div className="paw-card">
                                        <h2 className='fw-bold text-black fs-2 my-2 py-4'>Cámbiate a PRO</h2>
                                        <p className='fs-6 text-black fw-bold'>No pierdas los beneficios y todas las herramientas que cuidan de <span className='bg-warning text-white fw-bold'>{petData.petName}</span>, actualiza tu plan a PRO para seguir utilizando las características PRO que ya conces</p>

                                        {
                                            !showSubscriptionCard ?
                                                <div div className="my-3">
                                                    <button onClick={() => setShowSubscriptionCard(!showSubscriptionCard)} className='btn btn-warning fs-3 my-4 text-light  fw-bold rounded-2xl shadow'>Conservar beneficios</button>
                                                </div>
                                                :
                                                null
                                        }


                                        {
                                            showSubscriptionCard ?
                                                <div className='fade-div'>
                                                    <div className="container">
                                                        <SubscriptionsContainer petId={petData.tagId} nombreMascota={petData.petName} />
                                                        <div className="my-2">
                                                            <span className='text-secondary text-decoration-underline text-white' onClick={() => setShowSubscriptionCard(!showSubscriptionCard)}>Cancelar</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                :
                                                null
                                        }

                                        {
                                            petData?.showTryForFree ?

                                                <div className="my-4">
                                                    <h4>¿No estás seguro de cómo comenzar?</h4>
                                                    <div>
                                                        <p>
                                                            Prueba 7 días de IDPETS PRO y prueba todas las herramientas en PRO de tu mascota
                                                        </p>
                                                    </div>

                                                    <div className="my-4">
                                                        <button onClick={() => updateToProDemo(petData.tagId)} className="btn btn-warning fw-bold text-light btn-full-rounded w-50 my-4">Activar prueba PRO</button>
                                                    </div>
                                                </div>

                                                :
                                                null
                                        }

                                    </div>
                                </div>
                                :
                                <div className="bannerPro p-2 rounded-2xl">
                                    <h3 className='fs-3 fw-bold'>Plan PRO</h3>
                                </div>
                        }

                        {
                            diasVigentes !== null ?
                                <div className="my-4 bg-dark w-50 p-2">
                                    <h6 className='text-warning'>Importante: tu suscripción PRO vencerá {diasVigentes == 1 ? "Hoy" : `en ${diasVigentes} días`}</h6>
                                </div>
                                :
                                null
                        }



                        {/* RUAC */}
                        <div className="container bg-white shadow rounded-2xl my-4 p-1">
                            <div>

                                <span class="display-6 fw-bold">Registrar RUAC</span>
                                <div className='form-group'>
                                    <label className='mt-3'>Registra la clave RUAC de  <strong>{petData.petName}</strong></label>
                                    <div>
                                        <a className='my-2 text-warning text-decoration-underline' href="https://www.ruac.cdmx.gob.mx/registro" target='_blank'>¿No has registrado a {petData.petName}? Regístralo aquí</a>
                                    </div>
                                    <input defaultValue={petData.ruac} class="form-control rounded-2xl mb-2 mt-2" onChange={(e) => setRuac(e.target.value)}></input>
                                </div>

                                <div className='form-group'>
                                    <label className='mt-3'>Registra en enlace RUAC de  <strong>{petData.petName}</strong></label>
                                    <input defaultValue={petData.ruacLink} class="form-control rounded-2xl mb-2 mt-2" onChange={(e) => setRuacLink(e.target.value)}></input>
                                </div>


                                {
                                    plan == 'Demo' ?
                                        <div className='form-group'>
                                            <button onClick={() => alert("Función disponible sólo para mascotas nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                            </button>
                                        </div>
                                        :
                                        <div className='d-flex justify-content-center my-3'>
                                            <button className='btn btn-warning fw-bold btn btn-warning rounded-2xl fw-boldMyProfile' onClick={() => updateRUAC()}>Actualizar información</button>
                                        </div>
                                }
                            </div>
                        </div>
                        {/* FIN RUAC */}
                        {/* Configuración de correos */}


                        <div className="container bg-white shadow rounded-2xl my-4 p-1">
                            <div>

                                <span class="display-6 fw-bold">Correos de emergencia</span>
                                <div className='form-group'>
                                    <label className='mt-3'>Ingresa correos, separados por coma (",")</label>
                                    <textarea rows={6} defaultValue={petData.correosEmergencia} class="form-control rounded-2xl mb-2 mt-2" onChange={(e) => setEmergencyMails(e.target.value)}></textarea>
                                </div>


                                {
                                    plan == 'Demo' ?
                                        <div className='form-group'>
                                            <button onClick={() => alert("Función disponible sólo para mascotas nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                            </button>
                                        </div>
                                        :
                                        <div className='d-flex justify-content-center my-3'>
                                            <button className='btn btn-warning fw-bold btn btn-warning rounded-2xl fw-boldMyProfile' onClick={() => updateEmergencyMails()}>Actualizar información</button>
                                        </div>
                                }
                            </div>
                        </div>

                        {/* Fin configuración de correos */}

                        {/* Datos generales */}

                        <div className="container bg-white shadow rounded-2xl my-4 p-1">

                            <div className='row justify-content-center'>
                                <span className='display-6 fw-bold my-4'>Datos Generales</span>
                            </div>

                            <div style={{ borderRadius: "10px", textAlign: "left", padding: "10px" }}>

                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Nombre de tu mascota:</label>
                                    <input type="text" defaultValue={petData.petName} class="rounded-2xl form-control rounded-2xl" placeholder='Ingresa el nombre' onChange={(e) => handleGeneralDataTextChange("petName", e.target.value)}></input>
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Teléfono del/la dueño/a:</label>
                                    <input type="text" defaultValue={petData.telefonoDueno} class="rounded-2xl form-control rounded-2xl" placeholder='Ingrese el teléfono' onChange={(e) => handleGeneralDataTextChange("telefonoDueno", e.target.value)}></input>
                                </div>

                                <div className="text-start my-3">
                                    <label className='small fw-bold'>F. Nacimiento:</label>
                                    <input type="date" defaultValue={petData.bornDate} class="rounded-2xl form-control rounded-2xl" placeholder='Fecha de Nacimiento' onChange={(e) => handleGeneralDataTextChange("bornDate", e.target.value)}></input>
                                </div>

                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Tamaño: {petData.petSize} </label>
                                    <select type="text" class="rounded-2xl form-select mb-2 mt-2" onChange={(e) => handleGeneralDataTextChange("petSize", e.target.value)}>
                                        <option selected>Seleccione</option>
                                        <option value="Pequeño">Pequeño</option>
                                        <option value="Mediano">Mediano</option>
                                        <option value="Grande">Grande</option>
                                    </select>
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Gato o perro</label>
                                    <select type="text" class="rounded-2xl form-select mb-2 mt-2" onChange={(e) => setIsDog(!isDog)}>
                                        <option selected>Seleccione</option>
                                        <option selected value="Pequeño">Es Gato</option>
                                        <option selected value="Pequeño">Es Perro</option>
                                    </select>
                                </div>

                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Raza: {petData.petType}</label>
                                    {
                                        !!isDog ?

                                            <select type="text" class="rounded-2xl form-select mb-2 mt-2" onChange={(e) => handleGeneralDataTextChange("petType", e.target.value)} >
                                                <option selected>-- Raza Gato--</option>
                                                {
                                                    razasGatos.map((raza, index) => {
                                                        return <option value={razasGatos[index]}>{raza}</option>
                                                    })
                                                }
                                            </select>
                                            :

                                            <select type="text" class="rounded-2xl form-select mb-2 mt-2" onChange={(e) => handleGeneralDataTextChange("petType", e.target.value)} >
                                                <option selected>-- Raza Perro --</option>
                                                {
                                                    razas.map((raza, index) => {
                                                        return <option value={razas[index]}>{raza}</option>
                                                    })
                                                }
                                            </select>
                                    }

                                </div>



                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Castrado: {petData.steril}</label>
                                    <select type="text" class="rounded-2xl form-select mb-2 mt-2" onChange={(e) => handleGeneralDataTextChange("steril", e.target.value)}>
                                        <option selected>Seleccione</option>
                                        <option value="Sí">Sí</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>

                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Genero: {petData.genre}</label>
                                    <select type="text" class="rounded-2xl form-select mb-2 mt-2" onChange={(e) => handleGeneralDataTextChange("genre", e.target.value)}>
                                        <option selected>Seleccione</option>
                                        <option value="Macho">Macho</option>
                                        <option value="Hembra">Hembra</option>
                                    </select>
                                </div>

                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Color:</label>
                                    <input type="text" defaultValue={petData.color} class="rounded-2xl form-control rounded-2xl" placeholder='Color' onChange={(e) => handleGeneralDataTextChange("color", e.target.value)}></input>
                                </div>


                                <div className="d-flex justify-content-center">
                                    {
                                        plan === 'Demo' && !petData.planLite ? (
                                            <div className='form-group'>
                                                <button onClick={() => alert("Función disponible solo para mascotas nivel PRO")} className="rounded-2xl btn btn-warning fw-bold">
                                                    Actualizar datos generales
                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                </button>
                                            </div>
                                        ) : (
                                            <div className='form-group'>
                                                <button onClick={() => updateGeneralData()} className="rounded-2xl btn btn-warning fw-bold">Actualizar datos generales</button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>


                        {/*Recompenza */}

                        <div className='container bg-white shadow rounded-2xl my-4 p-2 my-2'>

                            {
                                plan == 'Demo' ?
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para mascotas nivel PRO")}></input>
                                        </div>
                                    </div>


                                    :
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" defaultChecked={petData.visibleRecompenza} onChange={(e) => changeVisibleRecompenza(e.target.checked)}></input>
                                        </div>
                                    </div>
                            }

                            <div>
                                <span className='display-6 fw-bold text-center my-4'>Recompensa ofrecida <span className='text-warning'>${petData.recompensa}</span></span>

                                <input type="text" onChange={(text) => setReward(text.target.value)} className="rounded-2xl form-control rounded-2xl mb-2 mt-2" placeholder='Nueva recompensa' ></input>

                                {
                                    plan == 'Demo' ?
                                        <div className='form-group'>
                                            <button onClick={() => alert("Función disponible sólo para mascotas nivel PRO")} className="btn btn-warning rounded-2xl fw-bold mt-2">Actualizar recompenza
                                                <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                            </button>
                                        </div>
                                        :
                                        <div className='form-group'>
                                            <button onClick={() => updateReward()} className="btn btn-warning rounded-2xl fw-bold mt-2">Actualizar recompenza</button>
                                        </div>
                                }
                            </div>
                        </div>


                        {/*Notificaciones WhatsApp */}

                        <div className='container bg-white shadow rounded-2xl my-4 p-2 my-2'>

                            <div>
                                <span className='display-6 fw-bold text-center my-4'>Activar notificaciones por WhatsApp</span>
                                <div class="alert alert-success" role="alert">
                                    Si deseas recibir la ubicación de {petData.petName} por WhatsApp regístra tu número aquí
                                </div>
                                <input placeholder='¿A qué número notificar?' defaultValue={petData.numeroWhatsApp} type="text" onChange={(text) => handleWhatsAppChange(text.target.value)} className="rounded-2xl form-control rounded-2xl mb-2 mt-2" ></input>

                                {
                                    plan == 'Demo' ?
                                        <div className='form-group'>
                                            <button onClick={() => alert("Función disponible sólo para mascotas nivel PRO")} className="btn btn-warning rounded-2xl fw-bold mt-2">Actualizar recompenza
                                                <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                            </button>
                                        </div>
                                        :
                                        canActivateWhatsApp ?

                                            <div className='form-group'>
                                                <button onClick={() => updateNumeroWhatsApp()} className="btn btn-warning rounded-2xl fw-bold mt-2">Actualizar WhatsApp</button>
                                            </div>
                                            :
                                            <div class="alert alert-danger" role="alert">
                                                El número de WhastApp debe ser menor a 10 dígitos {numeroWhatsApp.length}
                                            </div>
                                }
                            </div>
                        </div>

                        {/* Datos contacto */}



                        <div className="container bg-white shadow rounded-2xl my-4 p-2 my-2">


                            {
                                plan == 'Demo' ?
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para mascotas nivel PRO")}></input>
                                        </div>
                                    </div>


                                    :
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" defaultChecked={petData.visibleContactInfo} onChange={(e) => changeVisibleContactInfo(e.target.checked)}></input>
                                        </div>
                                    </div>
                            }

                            <div className='d-flex flex-row'>
                                <span className='display-6 fw-bold my-4'>Información de contacto</span>
                            </div>

                            <div className='container my-2'>
                                <div className='row row-cols-1'>
                                    <div className='col-md-12'>
                                        <div className="text-start my-3">
                                            <label className='small fw-bold'>Nombre</label>
                                            <input type="text" defaultValue={petData.contactName} class="rounded-2xl form-control rounded-2xl" placeholder='Nombre' onChange={(e) => handleContactDataTextChange("contactName", e.target.value)}></input>
                                        </div>
                                        <div className="text-start my-3">
                                            <label className='small fw-bold'>Número primario</label>
                                            <input type="text" defaultValue={petData.primaryNumber} class="rounded-2xl form-control rounded-2xl" placeholder='Número primario' onChange={(e) => handleContactDataTextChange("primaryNumber", e.target.value)}></input>
                                        </div>
                                        <div className="text-start my-3">
                                            <label className='small fw-bold'>Número alternativo</label>
                                            <input type="text" defaultValue={petData.alternativeNumber} class="rounded-2xl form-control rounded-2xl" placeholder='Número alternativo' onChange={(e) => handleContactDataTextChange("alternativeNumber", e.target.value)}></input>
                                        </div>
                                        <div className="text-start my-3">
                                            <label className='small fw-bold'>Dirección</label>
                                            <input type="text" defaultValue={petData.location} class="rounded-2xl form-control rounded-2xl" placeholder='Dirección' onChange={(e) => handleContactDataTextChange("location", e.target.value)}></input>
                                        </div>
                                        <div className="text-start my-3">
                                            <label className='small fw-bold'>Email</label>
                                            <input type="text" defaultValue={petData.email} class="rounded-2xl form-control rounded-2xl" placeholder='Email' onChange={(e) => handleContactDataTextChange("email", e.target.value)}></input>
                                        </div>
                                    </div>
                                </div>



                                {
                                    plan == 'Demo' ?
                                        <div className='form-group'>
                                            <button onClick={() => alert("Función disponible sólo para mascotas nivel PRO")} className="rounded-2xl btn btn-warning fw-bold">
                                                Actualizar datos
                                                <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                            </button>
                                        </div>
                                        :
                                        <div className='form-group'>
                                            <button onClick={() => updateContactData()} className="rounded-2xl btn btn-warning fw-bold">Actualizar datos</button>
                                        </div>
                                }

                            </div>

                        </div>

                        {/* Datos salud */}






                        <div className="container bg-white shadow rounded-2xl my-4 p-1">
                            <h2 className='display-6 fw-bold my-4'>Salud de {petData.petName}</h2>


                            {
                                plan == 'Demo' ?
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para mascotas nivel PRO")}></input>
                                        </div>
                                    </div>


                                    :
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" defaultChecked={petData.visibleVeterinarioInfo} onChange={(e) => changeVisibleVeterinarioInfo(e.target.checked)}></input>
                                        </div>
                                    </div>
                            }


                            <div className="container">
                                <div className='d-flex flex-row'>
                                    <span className='pet-circle-name'>Veterinario</span>
                                </div>

                                <div className="text-start my-3">
                                    <label className='small fw-bold'>
                                        Clínica
                                    </label>
                                    <input defaultValue={petData.clinica} type="text" className="form-control rounded-2xl" placeholder='Clínica' onChange={(e) => handleChangeVeterinarioData("clinica", e.target.value)} />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>
                                        Doctor
                                    </label>

                                    <input defaultValue={petData.doctor} type="text" className="form-control rounded-2xl" placeholder='Doctor' onChange={(e) => handleChangeVeterinarioData("doctor", e.target.value)} />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>
                                        Teléfono
                                    </label>
                                    <input defaultValue={petData.verterinatioPhone} type="number" className="form-control rounded-2xl" placeholder='Teléfono' onChange={(e) => handleChangeVeterinarioData("verterinatioPhone", e.target.value)} />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>
                                        Nombre de la mascota registrada
                                    </label>
                                    <input defaultValue={petData.callTo} type="text" className="form-control rounded-2xl" placeholder='Nombre de mascota' onChange={(e) => handleChangeVeterinarioData("callTo", e.target.value)} />
                                </div>

                                {
                                    plan == 'Demo' ?
                                        <div className='form-group'>
                                            <button onClick={() => alert("Función disponible sólo para mascotas nivel PRO")} className="rounded-2xl btn btn-warning fw-bold">
                                                Actualizar datos
                                                <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                            </button>
                                        </div>
                                        :
                                        <div className='form-group'>
                                            <button onClick={() => updateVeterinarioData()} className="rounded-2xl btn btn-warning fw-bold">Actualizar datos</button>
                                        </div>
                                }
                            </div>
                        </div>



                        <div style={{ height: "20px" }}></div>



                        <div className="container bg-white shadow rounded-2xl my-4 p-1">

                            {
                                plan == 'Demo' ?
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para mascotas nivel PRO")}></input>
                                        </div>
                                    </div>


                                    :
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" defaultChecked={petData.visibleMedicamentos} onChange={(e) => changeVisibleMedicamentos(e.target.checked)}></input>
                                        </div>
                                    </div>

                            }


                            <span className='display-6 fw-bold my-4'>Medicamentos</span>

                            <div className="container">
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Medicación</label>
                                    <input
                                        type="text"
                                        className="form-control rounded-2xl mb-2 mt-2"
                                        placeholder="Medicación"
                                        defaultValue={petData.medicacion}
                                        onChange={(e) => handleChangeMedicamentoState("medicacion", e.target.value)}
                                    />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Propósito</label>
                                    <input
                                        type="text"
                                        className="form-control rounded-2xl mb-2 mt-2"
                                        placeholder="Propósito"
                                        defaultValue={petData.proposito}
                                        onChange={(e) => handleChangeMedicamentoState("proposito", e.target.value)}
                                    />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Notas</label>
                                    <input
                                        type="text"
                                        className="form-control rounded-2xl mb-2 mt-2"
                                        placeholder="Notas"
                                        defaultValue={petData.notas}
                                        onChange={(e) => handleChangeMedicamentoState("notas", e.target.value)}
                                    />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Frecuencia diaria</label>
                                    <input
                                        type="text"
                                        className="form-control rounded-2xl mb-2 mt-2"
                                        placeholder="Frecuencia diaria"
                                        defaultValue={petData.frecuenciaDiaria}
                                        onChange={(e) => handleChangeMedicamentoState("frecuenciaDiaria", e.target.value)}
                                    />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Tiempo y dosis</label>
                                    <input
                                        type="text"
                                        className="form-control rounded-2xl mb-2 mt-2"
                                        placeholder="Tiempo y dosis"
                                        defaultValue={petData.tiempoDosis}
                                        onChange={(e) => handleChangeMedicamentoState("tiempoDosis", e.target.value)}
                                    />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Fecha de inicio</label>
                                    <input
                                        type="date"
                                        className="form-control rounded-2xl mb-2 mt-2"
                                        defaultValue={petData.fechaInicio}
                                        onChange={(e) => handleChangeMedicamentoState("fechaInicio", e.target.value)}
                                    />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Escriba la duración</label>
                                    <input
                                        type="text"
                                        className="form-control rounded-2xl mb-2 mt-2"
                                        placeholder="Escriba la duración"
                                        defaultValue={petData.duracion}
                                        onChange={(e) => handleChangeMedicamentoState("duracion", e.target.value)}
                                    />
                                </div>
                                <div className="text-start my-3">
                                    <label className='small fw-bold'>Días</label>
                                    <input
                                        type="number"
                                        min={1}
                                        className="form-control rounded-2xl mb-2 mt-2"
                                        placeholder="Días"
                                        defaultValue={petData.dias}
                                        onChange={(e) => handleChangeMedicamentoState("dias", e.target.value)}
                                    />
                                </div>
                            </div>


                            {
                                plan == 'Demo' ?
                                    <div className='form-group'>
                                        <button onClick={() => alert("Función disponible sólo para mascotas nivel PRO")} className="rounded-2xl btn btn-warning fw-bold">
                                            Actualizar datos
                                            <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                        </button>
                                    </div>
                                    :
                                    <div className='form-group'>
                                        <button onClick={() => updateMedicineData()} className="rounded-2xl btn btn-warning fw-bold">Actualizar datos</button>
                                    </div>
                            }

                        </div>


                        <div style={{ height: "20px" }}></div>


                        <div className="container bg-white shadow rounded-2xl my-4 p-1">

                            {
                                plan == 'Demo' ?
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para mascotas nivel PRO")}></input>
                                        </div>
                                    </div>


                                    :
                                    <div className='m-2 d-flex flex-row'>
                                        <span className='titleText'>Es visible: </span>
                                        <div class="form-check form-switch my-1">
                                            <input class="form-check-input" type="checkbox" role="switch" defaultChecked={petData.visibleAlergias} onChange={(e) => changeVisibleAlergias(e.target.checked)}></input>
                                        </div>
                                    </div>

                            }




                            <div className="container">
                                <div className='d-flex flex-row justify-content-center'>
                                    <span className='display-6 fw-bold'>Alergias</span>
                                </div>

                                <div className="text-start my-3">
                                    <span className='small fw-bold'>Alergia</span>
                                    <input defaultValue={petData.alergia} type="text" class="form-control rounded-2xl mb-2 mt-2" placeholder='Alergia' onChange={(e) => handleChangeAllergyState("alergia", e.target.value)}></input>
                                </div>
                                <div className="text-start my-3">
                                    <span className='small fw-bold'>Reacción</span>
                                    <input defaultValue={petData.reaccion} type="text" class="form-control rounded-2xl mb-2 mt-2" placeholder='Reacción' onChange={(e) => handleChangeAllergyState("reaccion", e.target.value)} ></input>
                                </div>
                                <div className="text-start my-3">
                                    <span className='small fw-bold'>Remedio</span>
                                    <input defaultValue={petData.remedio} type="text" placeholder='Remedio' class="form-control rounded-2xl mb-2 mt-2" onChange={(e) => handleChangeAllergyState("remedio", e.target.value)}></input>
                                </div>
                                <div className="text-start my-3">
                                    <span className='small fw-bold'>Última actualización</span>
                                    <textarea defaultValue={petData.ultimaActualizacion} type="text" class="form-control rounded-2xl mb-2 mt-2" placeholder='Última actualización' onChange={(e) => handleChangeAllergyState("ultimaActualizacion", e.target.value)} ></textarea>
                                </div>

                                <div className="text-start my-3">
                                    <span className='small fw-bold'>Notas</span>
                                    <textarea defaultValue={petData.notasAlergias} type="text" class="form-control rounded-2xl mb-2 mt-2" placeholder='Notas' onChange={(e) => handleChangeAllergyState("notasAlergias", e.target.value)}></textarea>
                                </div>


                                {
                                    plan == 'Demo' ?
                                        <div className='form-group'>
                                            <button onClick={() => alert("Función disponible sólo para mascotas nivel PRO")} className="rounded-2xl btn btn-warning fw-bold">
                                                Actualizar datos
                                                <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                            </button>
                                        </div>
                                        :
                                        <div className='form-group'>
                                            <button onClick={() => updateAllergyData()} className="rounded-2xl btn btn-warning fw-bold">Actualizar datos</button>
                                        </div>
                                }
                            </div>

                        </div>

                        <div style={{ height: "20px" }}></div>

                        <div className="container bg-white shadow rounded-2xl my-4 p-2">
                            <h4 className='display-6 fw-bold text-danger'>Reiniciar tag</h4>
                            <div className='my-2'>
                                <strong>Importante: Debes contar con el TAG ID que corresponde a tu QR</strong>
                            </div>
                            <p className='text-center small my-2'>
                                Al reiniciar el tag de {petData.petName} podrás registrar a otra mascota en su lugar, con esto no tendrás que adquirir un nuevo TAG ID
                            </p>
                            <div className="my-4">
                                <button className='btn bg-danger rounded-2xl text-white' onClick={() => restartTag()}>Reiniciar el TAG</button>
                            </div>

                        </div>


                        {
                            plan !== 'Demo' ?

                                <div className="container bg-white shadow rounded-2xl my-4 p-2 py-4">
                                    <h4 className='display-6 fw-bold'>Acerca de tu suscripción</h4>

                                    <div className="row row-cols-2 gap-4 justify-content-center">
                                        <div className="col-12 col-md-5 text-start my-3 rounded shadow rounded-2xl">
                                            <div className="my-4">
                                                <h3 className='fw-bold text-secondary'>
                                                    Información de tu suscripción
                                                </h3>

                                                <div className="my-2">
                                                    <div>
                                                        <span>
                                                            Plan contratado: <strong>{petData.plan}</strong>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            Inicio de tu suscripción: <strong>{petData.subscription?.subscription_startTime}</strong>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span>
                                                            Fin de tu suscripción:  <strong>{petData.subscription?.subscription_nextBillingTime}</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-5 my-3 rounded shadow rounded-2xl">
                                            <div className='my-2'>
                                                <strong>Importante: Debes contar con el TAG ID que corresponde a tu QR</strong>
                                            </div>
                                            <p className='text-center small my-2'>
                                                Al cancelar la suscripción PRO de <strong className='text-warning'>{petData.petName}</strong> perderás todos los beneficios que cuidan de tu mascota, tu suscripción PRO estará vigente hasta la próxima fecha de facturación
                                            </p>

                                            {
                                                showCancelCard ?
                                                    <div>
                                                        <div className="form-group">
                                                            <label>Escribe la frase <span className='fw-bold text-danger'>Cancelar suscripción</span></label>
                                                            <input type="text" className="form-control rounded-2xl" onChange={(e) => setFraseUsuario(e.target.value)} placeholder='Cancelar suscripción' />
                                                            <label> Razón de cancelación</label>
                                                            <input type="text" className="form-control rounded-2xl" onChange={(e) => setRazonCancelar(e.target.value)} placeholder='Dinos, ¿Por qué estás cancelando?' />
                                                            <div className="my-2">
                                                                <button style={{ backgroundColor: "#3483fa" }} className='btn fw-bold rounded-2xl text-white' onClick={() => setShowCancelCard(false)}>Conservar beneficios</button>
                                                            </div>
                                                            <div className='my-2'>
                                                                <button className='btn rounded-2xl text-secondary' onClick={() => handleValidarPalabraCancelacion("Cancelar suscripción")}>Cancelar suscripción</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                !showCancelCard ?
                                                    <div className="my-4">
                                                        <button className='btn bg-danger rounded-2xl text-white' onClick={() => setShowCancelCard(!showCancelCard)}>Cancelar suscripción</button>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>


                                </div>

                                :
                                null
                        }

                        <div style={{ height: "20px" }}></div>
                    </center >

                </>
            );
        }
    }

    function calcularDiasRestantes(fechaObjetivo) {

        // SI RETORNA NUMEROS SON LOS DIAS QUE QUEDAN DE LA SUSCRIPCIÓN
        // SI RETORNA FALSE SIGUE VIGENTE POR QUE AUN 
        // SI RETORNA limit hoy es el limite

        // Convierte la fecha objetivo a un objeto Date
        const fechaObjetivoDate = new Date(fechaObjetivo);

        // Obtiene la fecha actual
        const fechaHoy = new Date();

        // Compara las fechas
        if (fechaHoy > fechaObjetivoDate) {
            return false;
        } else if (fechaHoy.toDateString() === fechaObjetivoDate.toDateString()) {
            return "limit"
        } else {
            // Calcula los milisegundos de diferencia entre las fechas
            const diferenciaMilisegundos = fechaObjetivoDate - fechaHoy;
            // Convierte los milisegundos a días
            const diasRestantes = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
            return diasRestantes
        }
    }

    useEffect(() => {

        let userIdAuth = sessionStorage.getItem('userId')
        if (!userIdAuth) {
            navigate('/login')
        }

        recoverDataPet();
    }, [])


    return (
        <FadeInLeft>
            <div className="container bg-gray-50">
                {createCarnet()}
            </div>
        </FadeInLeft>
    )



}


export default PetDetails;