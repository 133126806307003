import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import WAFloatButton from './WAFloatButton'
import '../Navigation.css';

// Firebase
import app from '../firebase';
const db = app.firestore();



export default function HomePage() {

    const [isLoading, setIsLoading] = useState(true)
    const [plaquitas, setPlaquitas] = useState([])
    const [showModal, setShowModal] = useState(true)

    useEffect(() => {
        db.collection("productos").orderBy("created_at", "asc")
            .onSnapshot((querySnapshot) => {
                const docs = []
                querySnapshot.docs.forEach((doc) => {
                    docs.push({ ...doc.data(), id: doc.id })
                })
                setPlaquitas(docs)
                setIsLoading(false)
            })
    }, [])

    return (

        <main>
            {
                isLoading == true ?
                    <div className="d-flex justify-content-center align-items-center minHeight-100">
                        <img src="https://cdn.dribbble.com/users/713952/screenshots/2338364/dogrun_drib.gif" alt="" />
                    </div>
                    :
                    <div>
                        <WAFloatButton></WAFloatButton>
                        <header id='row-pc' className="d-flex flex-wrap justify-content-center py-3  border-bottom">
                            <a href="/" className="d-flex align-items-center m-2 mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                                <img src="https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/favicons%2FLOGO-png.png?alt=media&token=a2ca2a5e-38f6-4e70-b776-e2d19993712b" height="50" alt=""></img>
                            </a>

                            <ul>
                                <Link to={'/signup'} className='m-2 nav-item text-dark'>Plaquitas</Link>
                                <Link to={'/signup'} className='m-2 nav-item text-dark'>Cómo funciona</Link>
                                <Link to={'/login'} className='m-2 nav-item btn bg-dark text-light btn-full-rounded'>Iniciar sesión</Link>
                                <Link to={'/signup'} className='m-2 btn btn-full-rounded text-dark fw-bold nav-item bg-warning'>Regístrate</Link>
                            </ul>
                        </header>

                        <header id='row-mb'>
                            <div className="nav-container d-flex w-100 flex-row justify-content-center">
                                <div className="logo_container_nav_color"></div>
                            </div>
                            <nav className="navbar navbar-light bg-light">
                                <div className="container-fluid justify-content-center my-4">
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false">
                                        <span className="text-dark">Menú de navegación </span>
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>
                            </nav>
                            <div className="collapse" id="navbarToggleExternalContent">
                                <div className="bg-light p-4">
                                    <div className="items">
                                        <div className='my-2'>
                                            <a href='#plaquitas' className='m-2 nav-item text-dark'>Plaquitas</a>
                                        </div>
                                        <div className='my-2'>
                                            <a href='#como-funciona' className='m-2 nav-item text-dark'>Cómo funciona</a>
                                        </div>
                                        <div className='my-2'>
                                            <Link to={'/login'} className='m-2 nav-item btn bg-dark text-light btn-full-rounded'>Iniciar sesión</Link>
                                        </div>
                                        <div className='my-2'>
                                            <Link to={'/signup'} className='m-2 btn btn-full-rounded text-dark nav-item bg-warning'>Regístrate</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>




                        {/* Hero pc */}

                        <div className="container-fluid">
                            <div className="row row-cols-2 d-flex align-items-center">
                                <div className="col">

                                    <div className="my-4">
                                        <span className='fs-4 fw-bold text-dark'>Plaquitas de identificación inteligente para tu mascota</span>
                                    </div>
                                    <div className="my-4">
                                        <span className='fs-6 text-dark'>Protege a tu mascota con una plaquita y una plataforma de geolocalización en tiempo real</span>
                                    </div>

                                    <div className="my-4">
                                        <Link to={"/login"} className='btn btn-full-rounded bg-warning text-dark fw-bold'>Empieza gratis</Link>
                                    </div>

                                </div>
                                <div className="col d-flex justify-content-center">
                                    <div className="container-dog-face"></div>
                                </div>
                            </div>
                        </div>

                        {/* Fin Hero pc */}

                        <div className="p-4">
                            <span className='fs-4 fw-bold text-dark'>Plaquitas</span>
                            <p className='text-dark my-2'>Conoce todos nuestros modelos, nuestras plaquitas son producidas y grabadas con el material de más alta calidad</p>
                        </div>

                        {/* Plaquitas lista */}

                        <div className="p-4 d-flex justify-content-center">
                            <div className="table-responsive hideScrollbar">
                                <table className='table'>
                                    <tr>

                                        {
                                            plaquitas.map((plaquita) => (
                                                <td>
                                                    <div className="card shadow m-1 card-plaquita">
                                                        <div style={{
                                                            backgroundSize: "contain",
                                                            backgroundPosition: "center",
                                                            backgroundRepeat: "no-repeat",
                                                            justifyContent: 'center',
                                                            borderRadius: "20px",
                                                            backgroundImage:
                                                                `url(${(plaquita.urlImagen)})`
                                                        }} className="card-img-top" alt="3 meses de cobertura" ></div>
                                                        <span className='my-2 fw-bold fs-4'>{plaquita.nombre}</span>
                                                        <a href={plaquita.urlCompra} target="_blank" className='btn btn-warning text-dark btn-full-rounded fw-bold'>Comprar</a>
                                                    </div>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                </table>
                            </div>
                        </div>


                        {/* Fin Plaquitas lista */}


                        <div className="d-flex justify-content-center">
                            <div className="row row-cols-2">
                                <div className="col-12 col-md-6 my-4 d-flex align-items-center p-0">

                                    <div className='p-4'>
                                        <span className='text-dark fs-4 fw-bold'>Cómo funciona</span>
                                        <p className="my-3 text-dark">Escanea el siguiente QR para ver la información que podrías mostrarle a alguna persona que encuentre a tu mascota y lea su  <strong>Plaquita Inteligente</strong>.</p>
                                        <a href='/demo' className='text-warning text-decoration-underline fs-4 fw-bold'>O ve el demo aquí</a>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-center p-0">
                                    <div className="container-qr-demo"></div>
                                </div>
                            </div>
                        </div>


                        {/* Seccion planes */}


                        <div className="my-2">
                            <h1 className='fw-bold text-black displa-6 text-center'>Planes IDPETSQR</h1>
                        </div>

                        <div className="row row-cols-12">
                            <div className="col-12 col-md-3"></div>
                            <div className="col-12 col-md-6 my-4">
                                <div className="card-plan text-center">
                                    <div className="my-2">
                                        <img className='my-2 img-card-plan' src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2F_30dc433e-92a5-48d4-bc38-e3c1a8a7d30a%202.png?alt=media&token=d90b99b6-d303-488f-8a2b-8647baec5fe9" alt="IDPETSQR | PLAN PRO" />
                                        <div>
                                            <span class="badge bg-yellow-gradient text-dark">Recomendado</span>
                                        </div>
                                        <h2 className='text-black fw-bold fs-3'>PRO</h2>
                                        <div className="d-flex justify-content-center">
                                            <h5 className='text-secondary fw-bold fs-6 my-2 p-2 border-2-secondary rounded-2xl w-50-responsive'>Plan mensual <span className='line-through'>$120</span> a $39</h5>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <h5 className='text-secondary fw-bold fs-6 my-2 p-2 border-2-primary rounded-2xl w-50-responsive'>Plan anual <span className='line-through'>$650</span> a $399<span class="badge bg-success-2 text-white">39% off</span></h5>
                                        </div>
                                    </div>

                                    <div className="my-2">
                                        <ul className='ul-addons text-start'>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Foto</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Nombre de la mascota</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Fecha de nacimiento</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Tamaño</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Raza</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Esterilización</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Color</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Género</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Teléfono del dueño</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Reinicio de tag sin costo</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Soporte vía WhatsApp</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Bloques independientes</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Notificación de localización por WhatsApp</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Alertas ilimitadas por Mail</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Muestra recompensa en caso de extravío</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Muestra información de contacto</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Carnet veterinario</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Carnet de medicamentos</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Bitácora de alergías</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Notificaciones a correos de emergencia 24/7</li>
                                            <li className='fs-6 my-2'><span className='text-success text-start fw-bold'>✓</span> Soporte vía WhatsApp Premium</li>
                                        </ul>
                                    </div>

                                    <div className="d-flex justify-content-center my-4">
                                        <a href='/signup' target='_blank' className='w-100  fs-5 text-white btn btn-warning fw-bold btn btn-warning rounded-2xl fw-boldMyProfile'>Comienza ahora</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-3"></div>
                        </div>


                        <section className="my-4 d-flex justify-content-center">
                            <div className="shadow  p-3 text-center bg-dark mw-50-responsive">

                                <h2 className='display-6 fw-bold my-4 text-light'>Crea un TagDemo</h2>
                                <p className='my-4 text-light fs-5'>Simula un tag de Demo para darte una idea de cómo <strong className='text-warning'>IdpetsQR</strong> te puede dar una mano si se extravía tu mascota</p>

                                <Link to='/demoTag' className='btn btn-warning text-dark fw-bold'>Crear TagDemo</Link>

                            </div>
                        </section>


                        <div className="p-4">
                            <div className="my-4 text-center">
                                <span className='text-dark fs-4 fw-bold'>Instrucciones de uso, registro y extravío de mascota</span>
                            </div>

                            <div className="contianer-fluid">
                                <div className="row row-cols-4">
                                    <div className="col-12 col-md-3 d-flex justify-content-center align-items-center flex-column">
                                        <div className="circle shadow d-flex justify-content-center align-items-center my-2">
                                            <span className='display-1 text-warning'>1</span>
                                        </div>
                                        <div className="text-center">
                                            <p>Registra a tu mascota en idpetsqr.com</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 d-flex justify-content-center align-items-center flex-column">
                                        <div className="circle shadow d-flex justify-content-center align-items-center my-2">
                                            <span className='display-1 text-warning'>2</span>
                                        </div>
                                        <div className="text-center">
                                            <p>El código QR Se Asocia a Los Datos De Tu Mascota </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 d-flex justify-content-center align-items-center flex-column">
                                        <div className="circle shadow d-flex justify-content-center align-items-center my-2">
                                            <span className='display-1 text-warning'>3</span>
                                        </div>
                                        <div className="text-center">
                                            <p>Con el QR, verán los datos de tu mascota. Si su GPS está activo, recibirás un mail con la ubicación exacta</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 d-flex justify-content-center align-items-center flex-column">
                                        <div className="circle shadow d-flex justify-content-center align-items-center my-2">
                                            <span className='display-1 text-warning'>4</span>
                                        </div>
                                        <div className="text-center">
                                            <p>Sólo queda reunirte con tu Mascota</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <section className="my-6 d-flex justify-content-center">
                            <div className="shadow  p-3 text-center bg-dark mw-50-responsive">

                                <h2 className='display-6 fw-bold my-4 text-light'>Activa tu tag, y protege a tu amigo todos los días</h2>

                                <a href="/login" className="btn btn-warning fs-5 fw-bold text-dark btn-full-rounded">Activar tag</a>

                            </div>
                        </section>

                        <footer className="bg-dark">
                            <div className="p-4">
                                <div className="row row-cols-3 rounded">
                                    <div className="col-12 col-md-4 links-group">
                                        <span className='fs-4 my-4 fw-bold text-warning'>Redes sociales</span>
                                        <ul className='my-4'>
                                            <li className='my-2'>
                                                <a className='text-decoration-none text-light' target="_blank" href="http://www.tiktok.com/@idpetqr">Tiktok</a>
                                            </li>
                                            <li className='my-2'>
                                                <a className='text-decoration-none text-light' target={"_blank"} href="https://www.instagram.com/idpets.mx/">Instagram</a>
                                            </li>
                                            <li className='my-2'>
                                                <a className='text-decoration-none text-light' target={"_blank"} href="https://www.facebook.com/IdPetsqr">Facebook</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-4 links-group">
                                        <span className='fs-4 my-4 fw-bold text-warning'>Recursos</span>
                                        <ul className='my-4'>
                                            <li className='my-2'>
                                                <a className='text-decoration-none text-light my-2' target="_blank" href="https://www.idpetshop.com/qr">Tienda en línea ➤</a>
                                            </li>
                                            <li className='my-2'>
                                                <a className='text-decoration-none text-light my-2' target="_blank" href="https://idpetsqr.com/login">Acceder a mi cuenta ➤</a>
                                            </li>
                                            <li className='my-2'>
                                                <a className='text-decoration-none text-light' target="_blank" href="https://idpetsqr.com/reset_password">Reestablecer mi contraseña de Idpets ➤</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-4 links-group">
                                        <span className='fs-4 my-4 fw-bold text-warning'>IdPetsQr.com</span>
                                        <ul className='my-4'>
                                            <li className='my-2'>
                                                <a className='text-decoration-none text-light my-2' target={"_blank"} href="http://link.mercadopago.com.mx/plaquitasqr">Donaciones ➤</a>
                                            </li>
                                            <li className='my-2'>
                                                <a className='text-decoration-none text-light' href="tel:5584627932">Tel:55 8462 7932</a>
                                            </li>
                                            <li className='my-2'>
                                                <a className='text-decoration-none text-light' href="/privacidad">Aviso de privacidad</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            {/* Firma */}
                            <div className="p-4 text-center">
                                <a className='text-decoration-none text-light' href="https://www.desarrollalab.com" target="_blank">Desarrollado por Desarrollalab - Soluciones Tecnológicas</a>.
                            </div>
                            {/* Ends firma */}
                        </footer>
                    </div>
            }

        </main >

    )
}
