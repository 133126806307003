import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../index.css'
import WaFloatButton from './WAFloatButton'

export default function Terminos() {
    return (
        <main className="bodybackground">
            <WaFloatButton></WaFloatButton>
            <header class="header">
                <title>Términos y condiciones - IdPetsQR</title>
                <div class="container flex-between">
                    <div class="logo">
                        <a href="#">
                            <img src="https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/archivos%2FWhatsApp%20Image%202022-04-25%20at%209.46.14%20PM.jpeg?alt=media&token=31c371e6-db33-4dd6-8319-053bd391004c" alt="" />
                        </a>
                    </div>
                    <nav class="d-flex flex-row-reverse" id='row-pc'>
                        <div class="nav-buttons">
                            <Link to={'/login'} class="badge rounded-pill bg-primary" style={{ color: "#fff" }}>Inicia sesión</Link>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <Link to={'/signup'} class="badge rounded-pill bg-info" style={{ color: "#fff" }}>Registrate</Link>
                        </div>
                    </nav>
                    <div className="container" id='row-mb'>
                        <div className="row cols-2">
                            <Link to={'/login'} className='col btn badge rounded-pill bg-primary'>Iniciar sesión</Link>
                            <Link to={'/signup'} className='col btn badge rounded-pill bg-info'>Regístrate</Link>
                        </div>
                    </div>
                </div>
            </header>




            <div className="main-container">

                <div className='d-flex flex-row justify-content-center mt-4'>
                    <img className='img-feature' src="https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/archivos%2FWhatsApp%20Image%202022-04-25%20at%209.46.14%20PM.jpeg?alt=media&token=31c371e6-db33-4dd6-8319-053bd391004c" />
                </div>
                <div className='d-flex flex-row justify-content-center mt-4'>
                    <h1 className='text-center'>Declaración de Términos y Condiciones de uso para la Plataforma y el Servicio de Identificaciones Digitales</h1>
                </div>

                <div>

                    <p className="parraf" style={{ textAlign: "justify" }}>
                        Esta Declaración de Términos y Condiciones
                        ("Declaración", "Términos ", "Condiciones" o "DTC")
                        contiene las condiciones del servicio que rigen nuestra relación
                        con los usuarios y con todos aquellos que interactúan con el servicio de
                        IdPetsQr (en adelante y, conjunta e indistintamente, el “Servicio”).
                        Al hacer uso del Servicio muestras tu conformidad con esta Declaración.
                    </p>
                    <p className="parraf" style={{ textAlign: "justify" }}>
                        IdPetsQr y/o sus subsidiarios, controladoras, partes relacionadas y afiliadas (en adelante y, conjunta e indistintamente, la “Empresa”) se reservan el derecho de modificar discrecionalmente el contenido del portal en cualquier momento, sin necesidad de previo aviso.
                    </p>

                    <p className="parraf" style={{ textAlign: "justify" }}>
                        A los Usuarios, les informamos que los siguientes Términos y Condiciones les son aplicables por el simple uso o acceso a cualquiera de las páginas, aplicaciones web y móviles, software y, aplicaciones en general, que integran el Portal de www.idPetsQr.com (en adelante y, conjunta e indistintamente, la “Plataforma”), por lo que entenderemos que los acepta y acuerda en obligarse a su cumplimiento.
                    </p>

                    <div className="my-2">

                        <ul>
                            <li>

                                <p className="parraf">
                                    <strong>
                                        1) Valores y Código de Conducta.
                                    </strong>
                                    &nbsp;
                                    IdPetsQr es una marca con responsabilidad ecológica, social y moral, comprometida con los valores que llevan por buen rumbo el actuar de la sociedad y de los miembros de su comunidad. Por lo anterior, IdPetsQr se opone tajantemente a las conductas que fomenten el maltrato y la explotación animal o de cualquier ser vivo. Se debe entender el Servicio que prestamos como una forma recreativa de convivencia con nuestras mascotas y pretendemos que nuestras herramientas digitales (“Identificación Digital”, “Mascotas Perdidas”,) sean utilizadas como medios de ayuda para el fomento del bienestar animal y nunca para cualquier otro medio nocivo.
                                </p>

                            </li>
                            <li>
                                <p className="parraf">
                                    <strong>
                                        2) Privacidad.
                                    </strong>
                                    &nbsp;
                                    Tu privacidad es muy importante para nosotros. En nuestro Aviso de Privacidad Simplificado puede ayudarte a comprender cómo puedes usar el Servicio para compartir información con otras personas y cómo recopilamos y usamos tu información y contenido. Te recomendamos que leas nuestro Aviso de Privacidad Simplificado y que lo utilices para poder tomar decisiones fundamentadas.
                                </p>
                            </li>
                            <li>
                                <p className="parraf">

                                    <strong>
                                        3) Seguridad y Comportamiento.
                                    </strong>
                                    &nbsp;
                                    Hacemos todo lo posible por que el Servicio y la Plataforma sean seguros, pero no podemos garantizarlo. Necesitamos tu ayuda para que así sea, lo que implica los siguientes compromisos de tu parte:
                                </p>
                                <ul>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.1</strong>&nbsp;No publicarás comunicaciones ofensivas o maliciosas en la Plataforma.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.2</strong>  &nbsp;
                                            No recopilarás información o contenido de otros usuarios ni accederás a la Plataforma utilizando medios automáticos (como bots de recolección, robots, arañas o scrapers) sin nuestro permiso previo. En caso de ser inevitable la recopilación de información de otros usuarios, deberás obtener su consentimiento previo, dejar claro que eres tú (y no IdPetsQr) quien recopila la información y publicar una política de privacidad que explique qué datos recopilas y cómo los usarás.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.3</strong>  &nbsp;
                                            No subirás virus ni código malicioso de ningún tipo.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.4</strong>  &nbsp;
                                            No accederás a una cuenta perteneciente a otro usuario.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.5</strong>  &nbsp;
                                            No molestarás, intimidarás ni acosarás a ningún usuario de la Plataforma.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.6</strong>  &nbsp;
                                            No publicarás contenido que contenga lenguaje que incite al odio, sea ofensivo, resulte intimidatorio, sea pornográfico, incite a la violencia o contenga desnudos o violencia gráfica o gratuita.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.7</strong>  &nbsp;
                                            No utilizarás las herramientas de (idPetsqr) para realizar o conducir acciones comerciales de cualquier tipo.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.8</strong>  &nbsp;
                                            No utilizarás las herramientas de (idPetsqr) para realizar o conducir campañas publicitarias de cualquier tipo, sin consentimiento previo de los administradores de la Plataforma.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.9</strong>  &nbsp;
                                            No desarrollarás ni pondrás en funcionamiento aplicaciones de terceros que incluyan contenido relacionado con el consumo de alcohol o las citas, o bien dirigido a público adulto sin las restricciones de edad apropiadas.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.10</strong>  &nbsp;
                                            No utilizarás el Servicio para actos ilícitos, engañosos, malintencionados o discriminatorios.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.10</strong>  &nbsp;
                                            No realizarás ninguna acción que pudiera inhabilitar, sobrecargar o afectar al funcionamiento correcto de la Plataforma o del Servicio, como un ataque de denegación de servicio o la alteración de la presentación de páginas u otras funciones de la Plataforma
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.11</strong>  &nbsp;
                                            No facilitarás ni fomentarás el incumplimiento de esta Declaración ni de nuestras políticas.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="parraf">
                                            <strong>4.12</strong>  &nbsp;
                                            Denunciarás o reportarás cualquier perfil y/o contenido que consideres ofensivo o que va en contra de la presente Declaración de Términos y Condiciones de Uso.
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <p className='parraf'>

                            <strong>
                                4) Cuentas.
                            </strong>
                            &nbsp;
                            El Usuario, al crear una cuenta la Plataforma, declara, bajo protesta de decir la verdad, que tiene como mínimo 18 años de edad o la mayoría de edad legal en su jurisdicción; el Usuario reconoce que es su responsabilidad cualquier actividad que se desarrolle con dicha cuenta o a través de ella y reconoce que tiene conocimiento y acepta las condiciones establecidas en los presentes Términos y Condiciones y en el Aviso de Privacidad Simplificado.
                        </p>
                        <p className='parraf'>
                            <strong>
                                5) Seguridad de la cuenta y registro.
                            </strong>
                            &nbsp;
                            Los usuarios de la Plataforma y del Servicio deben proporcionar los datos reales de su mascota, y en determinado caso, los datos de contacto reales de las personas que registran el Servicio. Por lo anterior, necesitamos tu colaboración para que siga siendo así. Estos son algunos de los compromisos que aceptas en relación con el registro y el mantenimiento de la seguridad de tu cuenta:

                            <ul>
                                <li>
                                    <p className="parraf">
                                        <strong>6.1</strong>  &nbsp;
                                        No proporcionarás información falsa en la Plataforma, ni crearás una cuenta para otras personas sin su autorización.
                                    </p>
                                </li>
                                <li>
                                    <p className="parraf">
                                        <strong>6.2</strong>  &nbsp;
                                        No crearás más de una cuenta personal.
                                    </p>
                                </li>
                                <li>
                                    <p className="parraf">
                                        <strong>6.3</strong>  &nbsp;
                                        Si inhabilitamos tu cuenta, no crearás otra sin nuestro permiso.
                                    </p>
                                </li>
                                <li>
                                    <p className="parraf">
                                        <strong>6.4</strong>  &nbsp;
                                        No utilizarás la Plataforma si eres menor de 18 años.
                                    </p>
                                </li>
                                <li>
                                    <p className="parraf">
                                        <strong>6.5</strong>  &nbsp;
                                        No utilizarás la Plataforma si fuiste declarado culpable de un delito sexual.
                                    </p>
                                </li>
                                <li>
                                    <p className="parraf">
                                        <strong>6.6</strong>  &nbsp;
                                        Mantendrás la información de contacto exacta y actualizada.
                                    </p>
                                </li>
                                <li>
                                    <p className="parraf">
                                        <strong>6.7</strong>  &nbsp;
                                        No compartirás tu contraseña ni pregunta secreta, no dejarás que otra persona acceda a tu cuenta, ni harás nada que pueda poner en peligro la seguridad de tu cuenta.
                                    </p>
                                </li>
                                <li>
                                    <p className="parraf">
                                        <strong>6.8</strong>  &nbsp;
                                        No transferirás tu cuenta personal a nadie.
                                    </p>
                                </li>
                            </ul>
                        </p>

                        <p className="parraf">
                            <strong>
                                6) Protección de los derechos de otras personas.
                            </strong>
                            &nbsp;
                            Respetamos los derechos de otras personas y esperamos que tú hagas lo mismo. Por lo tanto, deberás respetar las siguientes estipulaciones al utilizar la Plataforma:

                            <ul>
                                <li>
                                    <p className="parraf">
                                        <strong>7.1</strong>  &nbsp;
                                        No publicarás contenido ni realizarás ninguna acción en la Plataforma que infrinja o vulnere los derechos de terceros o que vulnere la ley de algún modo.
                                    </p>
                                    <p className="parraf">
                                        <strong>7.2</strong>  &nbsp;
                                        Podemos retirar cualquier contenido o información que publiques en la Plataforma si consideramos que infringe esta Declaración, nuestras Políticas o nuestros Valores.
                                    </p>
                                    <p className="parraf">
                                        <strong>7.3</strong>  &nbsp;
                                        No puedes utilizar nuestra marca (como “idPetsQr”, los logotipos de “IdPetsQr.com” ni ninguna marca que se parezca a la nuestra, excepto si recibes consentimiento previo por escrito de IdPetsQr.
                                    </p>
                                    <p className="parraf">
                                        <strong>7.4</strong>  &nbsp;
                                        No publicarás los documentos de identidad ni la información financiera confidencial de nadie en la Plataforma.
                                    </p>
                                </li>
                            </ul>

                        </p>
                        <p className="parraf">
                            <strong>
                                7) Dispositivos móviles y de otros tipos.
                            </strong>
                            &nbsp;
                            El Software de nuestro Servicio Digital es multiplataforma. Podrás hacer uso de él en dispositivos móviles, tablets y PC. Actualmente ofrecemos nuestros servicios para dispositivos móviles sin cargos adicionales, pero ten en cuenta que se aplicarán las tarifas normales de tu operador de datos móviles.
                        </p>

                        <p className="parraf">
                            <strong>
                                8) Suscripciones.
                            </strong>
                            &nbsp;
                            Es posible que para el uso y/o contratación de algunos o todos los Servicios y Contenidos, sea necesario el registro del Usuario y que este cuente con un método de pago automático y válido, asociado a dicho registro, método que podrá ser modificado o cancelado a discreción del Usuario. En su caso, el Usuario se sujetará a los Términos y Condiciones del proveedor del servicio de gestión de cobros electrónicos implementado.
                        </p>
                        <p className="parraf">
                            <strong>
                                9) Envíos.
                            </strong>
                            &nbsp;
                            La empresa no será responsable de ninguna demora y/o incumplimiento respecto de los envíos que se tengan que realizar mediante terceros ajenos a la Empresa, el Usuario acepta los riesgos adjuntos y que se sujeta a los Términos y Condiciones del proveedor de servicios encargado del envío.
                        </p>


                        <p className="parraf">
                            <strong>
                                10) Disposiciones especiales aplicables al software.
                            </strong>
                            &nbsp;

                            <ul>
                                <li>
                                    <p className="parraf">
                                        <strong>12.1</strong>  &nbsp;
                                        Si descargas o utilizas nuestro software, como un producto de software independiente, una aplicación o un plug-in para el navegador, aceptas que, periódicamente, el software puede descargar e instalar mejoras, actualizaciones y funciones adicionales a fin de mejorarlo y desarrollarlo.
                                    </p>
                                    <p className="parraf">
                                        <strong>12.2</strong>  &nbsp;
                                        No modificarás nuestro código fuente ni llevarás a cabo con él trabajos derivados, como descompilar o intentar de algún otro modo extraer dicho código fuente, excepto en los casos
                                        permitidos expresamente por una licencia de código abierto o si te damos nuestro consentimiento expreso por escrito.
                                    </p>
                                </li>
                            </ul>
                        </p>


                        <p className="parraf">
                            <strong>
                                11) Enmiendas.
                            </strong>
                            &nbsp;

                            <ul>
                                <li>
                                    <p className="parraf">
                                        <strong>13.1</strong>  &nbsp;
                                        A no ser que hagamos un cambio por motivos legales o administrativos, o para corregir una declaración incorrecta, te daremos siete (7) días para que nos remitas tus comentarios sobre el cambio de esta Declaración (a través de los correos electrónicos oficiales de la Plataforma de IdPetsQr).
                                    </p>
                                    <p className="parraf">
                                        <strong>13.2</strong>  &nbsp;
                                        Si realizamos cambios en las políticas a las que hace referencia esta Declaración o que estén incorporadas en ella, podemos indicarlo en nuestro sitio web, redes sociales o dentro de la Plataforma a través de notificaciones directas.
                                    </p>
                                    <p className="parraf">
                                        <strong>13.3</strong>  &nbsp;
                                        Tu uso continuado del Servicio después de los cambios en nuestras Condiciones supone la aceptación de las enmiendas por tu parte.
                                    </p>
                                </li>
                            </ul>
                        </p>


                        <p className="parraf">
                            <strong>
                                12) Terminación.
                            </strong>
                            &nbsp;
                            Si infringes la esencia o el espíritu de esta Declaración, si creas riesgos de cualquier tipo para IdPetsQr o nos expones a posibles responsabilidades jurídicas, podríamos impedirte el acceso a la Plataforma y la negación del Servicio total o parcialmente. Te notificaremos por correo electrónico o a través de nuestra Plataforma la próxima vez que intentes acceder a tu cuenta. También puedes eliminar tu cuenta en cualquier momento. En tales casos, esta Declaración cesará, pero las siguientes disposiciones continuarán vigentes: 3.2, 4-7, 10, 12 y 13.
                        </p>

                        <p className="parraf">
                            <strong>
                                13) Leyes Aplicables y Jurisdicción.
                            </strong>
                            &nbsp;
                            Para la interpretación, cumplimiento y ejecución de los presentes Términos y Condiciones, el Usuario está de acuerdo en que serán aplicables las leyes Federales de los Estados Unidos Mexicanos, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera correspóndeles debido a sus domicilios presentes o futuros o por cualquier otra causa.
                        </p>

                        <p className="parraf">
                            <strong>
                                14) Conflictos.
                            </strong>
                            &nbsp;
                            Resolverás cualquier demanda, causa de acción o conflicto (colectivamente, "demanda") que tengas con nosotros surgida de la presente Declaración o relacionada con estos, directamente con los responsables de la Plataforma de IdPetsQr.

                            <ul>
                                <li>
                                    <p className="parraf">
                                        <strong>16.1</strong>  &nbsp;
                                        Si alguien interpone una demanda contra nosotros relacionada con tus acciones, tu contenido o tu información en la Plataforma, nos indemnizarás y nos librarás de la responsabilidad por todos los posibles daños, pérdidas y gastos de cualquier tipo (incluidas las costas y tasas procesales razonables) relacionados con dicha demanda. Aunque proporcionamos normas para la conducta de los usuarios, no controlamos ni dirigimos sus acciones en la Plataforma y no somos responsables del contenido o de la información que los usuarios transmitan o compartan en la Plataforma. No somos responsables de ningún contenido que se considere ofensivo, inapropiado, obsceno, ilegal o inaceptable que puedas encontrar en la Plataforma. No somos responsables de la conducta de ningún usuario de la Plataforma, tanto si está en internet como si está desconectado.
                                    </p>
                                    <p className="parraf">
                                        <strong>16.2</strong>  &nbsp;
                                        Intentamos mantener la Plataforma en funcionamiento, sin errores y segura, pero lo utilizas bajo tu propia responsabilidad. Proporcionamos la Plataforma tal cual, sin garantía alguna, expresa o implícita, incluidas, entre otras, las garantías de comerciabilidad, adecuación a un fin particular y no incumplimiento. No garantizamos que la Plataforma sea siempre segura o esté libre de errores, ni que funcione siempre sin interrupciones, retrasos o imperfecciones. IdPetsQr no se responsabiliza de las acciones, el contenido, la información o los datos de terceros, y por la presente nos dispensas a nosotros, nuestros directivos, empleados y agentes de cualquier demanda o daños, conocidos o desconocidos, derivados de cualquier demanda que tengas interpuesta contra tales terceros o de algún modo relacionados con esta. No seremos responsables de ninguna pérdida de beneficios, así como de otros daños resultantes, especiales, indirectos o incidentales derivados de esta Declaración de IdPetsQr o relacionados con esta, incluso en el caso de que se haya avisado de la posibilidad de que se produzcan dichos daños. Nuestra responsabilidad conjunta derivada de la presente Declaración o de IdPetsQr no podrá sobrepasar (en su caso) la última cantidad que nos hayas pagado en los últimos doce meses. Las leyes aplicables podrían no permitir la limitación o exclusión de responsabilidad por daños incidentales o derivados, por lo que la limitación o exclusión anterior podría no ser aplicable en tu caso. En tales casos, la responsabilidad de IdPetsQr se limitará al grado máximo permitido por la ley aplicable.
                                    </p>
                                    <p className="parraf">
                                        <strong>16.3</strong>  &nbsp;
                                        El Usuario puede contactar, en todo momento, al personal de la Empresa para cualquier aclaración, comentario, duda y/o sugerencia relacionada con los Servicios y Contenidos, con el Portal y/o con los presentes Términos y Condiciones en  info@idpetsqr.com.
                                    </p>
                                </li>
                            </ul>
                        </p>


                        <p className="parraf">
                            <strong>
                                15) Definiciones.
                            </strong>

                            <ul>
                                <li>
                                    <p className="parraf">
                                        <strong>17.1</strong>  &nbsp;
                                        El término "IdPetsQr" se refiere a las funciones y los servicios que proporcionamos, incluidos los que se ofrecen a través de (a) nuestro sitio web en info@idpetsqr.com ¡Error! Referencia de hipervínculo no válida.y cualquier otro sitio web con marca o marca compartida de IdPetsQr (incluidos los subdominios, las versiones internacionales, los widgets y las versiones para dispositivos móviles); (b) nuestra Plataforma; (c) otros medios, software (como la barra de herramientas), dispositivos o redes ya existentes o desarrollados con posterioridad.
                                    </p>
                                    <p className="parraf">
                                        <strong>17.2</strong>  &nbsp;
                                        El término "Plataforma" se refiere al conjunto de API y servicios (como el contenido) de los que se puede hacer uso dentro del servicio de IdPetsQr.
                                    </p>
                                    <p className="parraf">
                                        <strong>17.3</strong>  &nbsp;
                                        El término "información" se refiere a los hechos y a otra información sobre ti, incluidas las acciones que realizan los usuarios y las personas que, sin ser usuarios, interactúan con IdPetsQr.
                                    </p>
                                    <p className="parraf">
                                        <strong>17.4</strong>  &nbsp;
                                        El término "contenido" se refiere a todo lo que tú u otros usuarios publican en la Plataforma para el servicio de IdPetsQr que no se incluye en la definición de "información".
                                    </p>
                                    <p className="parraf">
                                        <strong>17.5</strong>  &nbsp;
                                        Las expresiones "datos", "datos de usuario" o "datos del usuario" se refieren a los datos, incluidos el contenido o la información de un usuario, que otros pueden obtener del Servicio de IdPetsQr o proporcionar a IdPetsQr a través de la Plataforma.
                                    </p>
                                    <p className="parraf">
                                        <strong>17.6</strong>  &nbsp;
                                        El término “publicar” significa publicar en la Plataforma del servicio de IdPetsQr o proporcionar contenido de otro modo mediante dicha Plataforma.
                                    </p>
                                    <p className="parraf">
                                        <strong>17.7</strong>  &nbsp;
                                        Por "usar" se entiende utilizar, ejecutar, copiar, reproducir o mostrar públicamente, distribuir, modificar, traducir y crear obras derivadas.
                                    </p>
                                    <p className="parraf">
                                        <strong>17.8</strong>  &nbsp;
                                        La expresión "usuario registrado activo" se refiere a un usuario que entró en la Plataforma del servicio de IdPetsQr al menos una vez en los últimos 30 días.
                                    </p>
                                    <p className="parraf">
                                        <strong>17.9</strong>  &nbsp;
                                        El término "aplicación" significa cualquier aplicación o sitio web que usa la Plataforma o accede a ella, así como cualquier otro componente que recibe o recibió datos de nosotros. Si ya no accedes a la plataforma, pero no eliminaste todos los datos que te proporcionamos, el término "aplicación" continuará siendo válido hasta que los elimines.
                                    </p>
                                </li>
                            </ul>
                        </p>

                        <p className="parraf">
                            <strong>
                                16) Otro.
                            </strong>

                            <ul>
                                <li>
                                    <p className="parraf">
                                        <strong>18.1</strong>  &nbsp;
                                        Esta Declaración constituye el acuerdo completo entre las partes en relación con IdPetsQr y sustituye cualquier acuerdo previo.
                                    </p>
                                    <p className="parraf">
                                        <strong>18.2</strong>  &nbsp;
                                        Si alguna parte de esta Declaración no puede hacerse cumplir, la parte restante seguirá teniendo plenos efecto y validez.
                                    </p>
                                    <p className="parraf">
                                        <strong>18.3</strong>  &nbsp;
                                        Si no cumpliéramos alguna parte de esta Declaración, no se considerará una exención.
                                    </p>
                                    <p className="parraf">
                                        <strong>18.4</strong>  &nbsp;
                                        Cualquier corrección a esta Declaración o exención de esta deberá hacerse por escrito y estar firmada por nosotros.
                                    </p>
                                    <p className="parraf">
                                        <strong>18.5</strong>  &nbsp;
                                        No transferirás ninguno de tus derechos u obligaciones en virtud de esta Declaración a ningún tercero sin nuestro consentimiento.
                                    </p>
                                    <p className="parraf">
                                        <strong>18.6</strong>  &nbsp;
                                        Todos nuestros derechos y obligaciones según esta Declaración son asignables libremente por nosotros en relación con una fusión, adquisición o venta de activos, o por efecto de ley, o de algún otro modo.
                                    </p>
                                    <p className="parraf">
                                        <strong>18.7</strong>  &nbsp;
                                        Nada de lo dispuesto en esta Declaración nos impedirá cumplir la ley.
                                    </p>
                                    <p className="parraf">
                                        <strong>18.8</strong>  &nbsp;
                                        Esta Declaración no otorga derechos de beneficiario a ningún tercero.
                                    </p>
                                    <p className="parraf">
                                        <strong>18.9</strong>  &nbsp;
                                        Nos reservamos todos los derechos que no te hayamos concedido de forma expresa.
                                    </p>
                                    <p className="parraf">
                                        <strong>18.10</strong>  &nbsp;
                                        Cuando accedas a la Plataforma de IdPetsQr o utilices sus servicios deberás cumplir todas las leyes aplicables.
                                    </p>
                                </li>
                            </ul>
                        </p>

                    </div>



                </div>
            </div>
            <footer class="footer p-2" id="resources">
                <div class="container">
                    {/* <!-- Website Logo --> */}
                    <div class="logo">
                        <a href="#">
                            {/* <img src="https://raw.githubusercontent.com/MohamedAridah/frontendmentor_url-shortening-api/main/images/logo.svg" alt=""></img> */}
                        </a>
                    </div>
                    {/* <!-- Quick Links --> */}
                    <div class="quick-links">
                        <div class="links-group">
                            <span>Redes sociales</span>
                            <div>
                                <a href="#">Tiktok</a>
                                <a href="#">Instagram</a>
                                <a href="#">Facebook</a>
                            </div>
                        </div>
                        <div class="links-group">
                            <span>Recursos</span>
                            <div>
                                <a href="#">Tienda</a>
                            </div>
                        </div>
                        <div class="links-group">
                            <span>IdPetsQr.com</span>
                            <div>
                                <a href="#">Acerca de</a>
                                <a href="#">Tel:55 8462 7932</a>
                                <a href="/privacidad">Aviso de privacidad</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Social Media --> */}
                    <div class="social-media">
                        <a href="#">
                            {/* <img src="https://raw.githubusercontent.com/MohamedAridah/frontendmentor_url-shortening-api/main/images/icon-facebook.svg" alt="Facebook Logo"></img> */}
                        </a>
                        <a href="#">
                            {/* <img src="https://raw.githubusercontent.com/MohamedAridah/frontendmentor_url-shortening-api/main/images/icon-twitter.svg" alt="Twitter Logo"></img> */}
                        </a>
                        <a href="#">
                            {/* <img src="https://raw.githubusercontent.com/MohamedAridah/frontendmentor_url-shortening-api/main/images/icon-pinterest.svg" alt="Pinterest Logo"></img> */}
                        </a>
                        <a href="#">
                            {/* <img src="https://raw.githubusercontent.com/MohamedAridah/frontendmentor_url-shortening-api/main/images/icon-instagram.svg" alt="Instagram Logo"></img> */}
                        </a>
                    </div>
                </div>
                {/* <!-- Made By --> */}
                <div class="attribution">
                    Desarrollado por <a href="https://www.desarrollalab.com" class="outer-link" target="_blank">Desarrollalab - Soluciones Tecnológicas</a>.
                </div>
            </footer>
        </main>

    )
}
