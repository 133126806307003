import React from 'react';

export default function SubscriptionCardSpei({plan,tagId, nombreMascota}) {


    const CopyToClipboard = ({ textToCopy }) => {
        const handleCopyClick = () => {
            navigator.clipboard.writeText(textToCopy);
            alert('Copiado al portapapeles');
        };

        return (
            <img
                style={iconCopyStyle}
                onClick={handleCopyClick}
                src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fcopy-icon.svg?alt=media&token=40fab4b2-4bd9-462a-a87a-05667ec00e26"
                alt="Copy to Clipboard"
            />
        );
    };

    const cardStyle = {
        border: '2px dashed #FFDC00', // Ajusta el ancho y color del borde según tus preferencias
        borderRadius: '10px', // Ajusta el radio de borde según tus preferencias
        padding: '15px',
    };

    const iconCopyStyle = {
        width: '20px',
        height: '20px',
        cursor: 'pointer'
    }
    return (
        <div className='fade-div shadow rounded-2xl p-3 w-50-responsive my-4'>
            <div className='text-center'>
                <h4 className='fw-bold mb-4'>TICKET DE PAGO</h4>
            </div>
            <div className='mb-3 bb-1-yellow'>
                <strong>BENEFICIARIO:</strong>
                <div>
                    Plaquitas IDPETSQR &nbsp;  <CopyToClipboard textToCopy="DIEGO PACHECO ORTUÑO" />
                </div>

            </div>
            <div className='mb-3 bb-1-yellow'>
                <strong>BANCO:</strong> MIFEL &nbsp;
                <CopyToClipboard textToCopy="MIFEL" />
            </div>
            <div className='mb-3 bb-1-yellow'>
                <strong>CLABE INTERBANCARIA:</strong> 042180010005491223 &nbsp;
                <CopyToClipboard textToCopy="042180010005491223" />
            </div>
            <div className='mb-3 bb-1-yellow'>
                <strong>NOTA:</strong> ENVIAR COMPROBANTE AL
                <a href={`https://api.whatsapp.com/send?phone=525584627932&text=Acabo de enviar un pago SPEI para la compra del plan ${plan}, el tagId de mi mascota: ${nombreMascota} y su tagID es: ${tagId}`}>WHATSAPP</a>
            </div>
            <div className='text-muted text-center mt-3' style={cardStyle}>
                <small>TIEMPO DE ACTIVACIÓN MÁXIMO 24HRS.</small>
            </div>
        </div>
    );
}
