import React from 'react'

export default function AvisoPrivacidad() {
    return (
        <div className="p-4 m-2">
            <center>
                <h1>Aviso de privacidad</h1>
            </center>
            <div className="container-card  ">
                <center>
                    <img className='img-feature' src="https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/archivos%2FWhatsApp%20Image%202022-04-25%20at%209.46.14%20PM.jpeg?alt=media&token=31c371e6-db33-4dd6-8319-053bd391004c" />
                </center>
                <center>AVISO DE PRIVACIDAD SIMPLIFICADO DEL SERVICIO DE IDENTIFICACIÓN DIGITAL PARA MASCOTAS.</center>
                <p className="mb-2" style={{ textAlign: "justify" }}>IdPetsQr, con portal de internet www.idpetsqr.com, es el responsable del tratamiento, uso y protección de los datos personales que Usted proporcione, y al respecto le informamos lo siguiente:</p>

                <p className="mb-2" style={{ textAlign: "justify" }}> Los datos personales que podemos recabar de Usted, los utilizaremos para las siguientes finalidades:

                    <p className="mb-2" style={{ textAlign: "justify" }}>
                        a)	Facilitar los medios de contacto entre Usted y el resto de la comunidad que integran la plataforma de IdPetsQr en el servicio de Identificación Digital.
                    </p>

                    <p className="mb-2" style={{ textAlign: "justify" }}>
                        b)	Facilitar los medios de contacto entre Usted y los administradores de la plataforma de IdPetsQr en el servicio de Identificación Digital.
                    </p>
                    <p className="mb-2" style={{ textAlign: "justify" }}>
                        c)	Para la verificación y determinación de su identidad al momento de ingresar a la plataforma de IdPetsQr en el servicio de Identificación Digital.
                        De manera adicional, podemos utilizar su información para el mejoramiento del servicio de Identificación Digital, mediante la clasificación de nuestros usuarios por zona geográfica.
                    </p>


                </p>
                <p className="mb-2" style={{ textAlign: "justify" }}>
                    La Plataforma de IdPetsQr solo hace uso de cookies técnicas propias, específicamente destinadas para el correcto funcionamiento y personalización del Servicio. No hacemos uso de cookies analíticas, funcionales ni publicitarias. De igual forma, el Servicio de Identificación Digital para Mascotas solicita compartir y registrar la geolocalización/ubicación del dispositivo (smartphone/teléfono inteligente) al momento de escanear el código QR para ingresar a la Plataforma, cuya aceptación o negación depende y queda a discreción del usuario que utiliza dicho dispositivo. La decisión de aceptar o negar el compartir la geolocalización/ubicación del dispositivo será recordada por el navegador del mismo dispositivo en futuros ingresos a la plataforma de IdPetsQr.
                    Para el servicio de Identificación Digital de IdPetsQr, es necesario ingresar información básica de la mascota a la cual va dirigido el servicio, y no

                    considera necesario que los usuarios (personas que registran el servicio) proporcionen sus datos personales confidenciales. Sin embargo, es posible ingresar los siguientes datos personales para las finalidades enlistadas anteriormente:

                    <p className="mb-2" style={{ textAlign: "justify" }}>
                        •	Dirección/Ubicación geográfica/Geolocalización.
                    </p>
                    <p className="mb-2" style={{ textAlign: "justify" }}>
                        •	Teléfonos móviles con funciones de WhatsApp.
                    </p>
                    <p className="mb-2" style={{ textAlign: "justify" }}>
                        •	Correo Electrónico.
                    </p>
                </p>
                <p className="mb-2" style={{ textAlign: "justify" }}>
                    IdPetsQr garantiza el uso confidencial de la información proporcionada por el usuario de esta plataforma. Sin embargo, y salvo que Usted nos indique lo contrario a través de la configuración y administración de su Identificación Digita, mostrará a los usuarios registrados en la plataforma los datos e información que Usted señale como pública en la configuración, por lo que no responderá de los daños y perjuicios que se le puedan ocasionar con motivo de la información que Usted proporcione por la utilización de este servicio.
                    IdPetsQr se compromete a no proporcionar ni transferir tus datos de contacto, tus datos personales y la información que aparece en tu Identificación Digital a ningún tercero. Nuestro compromiso es tratar estos datos únicamente para
                    a) fines de contacto
                    b) verificación y determinación de su identidad y
                    c) mejoramiento del servicio.
                </p>
                <p className="mb-2" style={{ textAlign: "justify" }}>
                    IdPetsQr utiliza la información capturada para poder dar un servicio eficiente y proveer una mejor atención al usuario. Asimismo, IdPetsQr no revelará su nombre o de alguna u otra forma divulgará el hecho que Usted nos contactó o nos envió información salvo que nos sea requerida por la autoridad competente.
                    Adicionalmente, nuestro sistema de manejo de información está configurado de tal forma que bloquea o inhibe a personas no autorizadas para tener acceso a la información para la cual no están permitidos. Gracias a nuestro sistema, resulta posible que IdPetsQr reconozca a los Usuarios registrados después de que éstos se hayan registrado por primera vez, sin que se tengan que registrarse en cada visita para acceder a las áreas y servicios reservados exclusivamente para ellos.
                    IdPetsQr proporcionará a los Usuarios los recursos técnicos adecuados para que presten de forma inequívoca su consentimiento a fin de que IdPetsQr pueda proceder al tratamiento automatizado de sus Datos Personales y para que, con carácter previo a la prestación de dicho consentimiento, puedan acceder a este aviso sobre la Política de Privacidad para la información de las Identificación Digital de IdPetsQr o a cualquier otra información relevante.
                    Es importante mencionar que todos los usuarios de la Plataforma idPetsQr, tienen en su poder el eliminar de forma permanente toda la información proporcionada para nuestro servicio de Identificación Digital, a través del módulo Perfil, Apartado Eliminar Cuenta. La eliminación de la información por parte del usuario a través del módulo de Configuración será permanente e irrecuperable.

                </p>
                <p className="mb-2" style={{ textAlign: "justify" }}>

                </p>
                <p className="mb-2" style={{ textAlign: "justify" }}>
                    Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso), esto puede hacerlo desde la configuración y administración de su Identificación Digital. Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
                    Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:
                    Contactarnos a través de nuestro correo electrónico: <a href="mailto:info@idpetsqr.com">info@idpetsqr.com</a>
                    El presente aviso de privacidad simplificado puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad simplificado, a través de notificaciones directas desde nuestra plataforma y por medio de nuestras redes sociales.
                    Si usted tiene conocimiento de que cualquiera de nuestros empleados está violando este aviso de privacidad simplificado, por favor contáctenos.

                </p>
                <p className="mb-2" style={{ textAlign: "justify" }}>

                    Si usted tiene cualquier duda acerca de las presentes políticas, por favor contáctenos mediante los siguientes medios:
                    Por correo electrónico:  <a href="mailto:info@idpetsqr.com">info@idpetsqr.com</a>

                </p>

            </div>
        </div>
    )
}
