/**React imports */
import React, { useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';

import app from '../../firebase';
import FadeInLeft from '../../animations/FadeInLeft';
const db = app.firestore();


const NewTag = () => {

    const [tagId, setTagId] = useState('');
    const [isTagId, setIsTagId] = useState('');


    const validateIsActiveTag = (querySnapshot) => {
        let isActive = ''
        querySnapshot.forEach(function (doc) {
            isActive = doc.data().isActive
            console.log(isActive)
        });

        return isActive
    }
    const validateTagId = async () => {

        await db.collection('tags').where("tagId", "==", tagId).get().then(function (querySnapshot) {

            let isActive = validateIsActiveTag(querySnapshot)

            switch (isActive) {
                case true:
                    alert("El tag ya fue activado")
                    break;
                case false:
                    querySnapshot.forEach(function (doc) {
                        // doc.data() is never undefined for query doc snapshots
                        localStorage.setItem("@tagIdFirebase", doc.id)
                        localStorage.setItem("@tagId", tagId)
                        window.location.href = "/tag_general_form";
                    });
                    break;
                case "":
                    alert("No se reconoció el tag, verifique e ingreselo nuevamente")
                    break;
                default:
                    console.log(isActive)
                    alert("No se pudo procesar el tag")
                    break;
            }


        }).catch(function (error) {
            alert("Tag inválido" + error);
        });



    }




    return (
        <div className='new-tag-bg container-fluid minHeight-100 justify-content-center d-flex align-items-center'>
            <FadeInLeft>
                <div className='card h-50 shadow p-4'>

                    <div className='m-4'>
                        <h2 className='text-dark fw-bold'>Nuevo Tag</h2>
                        <div className='mt-4'>
                            <div className='form-group'>
                                <label className='mt-3'>Ingresa el ID del Tag</label>
                                <input type="text" className="form-control mb-2 mt-2 rounded-2xl" onChange={(e) => setTagId(e.target.value)} placeholder='Ej. 1fe26035' ></input>
                            </div>
                        </div>
                    </div>


                    <div className='d-flex flex-column align-items-center justify-content-center p-2'>
                        <button className='btn w-50 btn-warning btn-full-rounded fw-bold' onClick={() => validateTagId()}>Registrar</button>
                        <Link to={"/cl/dashboard"} className="linkView my-4">Cancelar</Link>
                    </div>
                </div>
            </FadeInLeft>
        </div>
    );



}


export default NewTag;