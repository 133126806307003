import React, { useEffect, useState } from 'react'
import app from '../../firebase';
import { Link, useNavigate } from 'react-router-dom';
import FilterResults from 'react-filter-search';
import * as firebaseAdmin from 'firebase/auth'
import Select from 'react-select';
const db = app.firestore();


export default function Clients() {
    
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [optionsTags, setOptionsTags] = useState([])


    const [tagsIds, setTagsIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true)


    const [tagsUsuario, setTagsUsuario] = useState([])
    const [users, setUsers] = useState([])
    const [parametroUsuario, setParametroUsuario] = useState("")
    const [currentUserId, setCurrentUserId] = useState("")
    const [isLoadingTags, setIsLoadingTags] = useState(false)
    const [activeTags, setActiveTags] = useState([])
    const [inactiveTags, setInactiveTags] = useState([])

    const [parametro, setParametro] = useState('');


    const closeSession = () => {
        sessionStorage.clear()
        navigate('/ad/login')
    }








    const handleSearchInputChange = e => {
        setParametro(e.target.value)
    }




    const handleParametroUsuario = e => {
        setParametroUsuario(e.target.value)
    }







    const getPetsByUserId = (userId) => {
        setIsLoadingTags(true)
        try {
            db.collection("pets").where("userId", "==", userId).get().then((query) => {
                let petsUsuario = [];
                query.forEach(doc => {
                    petsUsuario.push({ ...doc.data(), id: doc.id });
                });
                setTagsUsuario(petsUsuario)
                setIsLoadingTags(false)
            })
        } catch (error) {
            console.error("Error obteniendo los pets: ", error);
        }
    }




    async function getTagIdByUserId(id) {
        return db.collection("pets")
            .where("userId", "==", id)
            .get()
            .then((querySnapshot) => {
                const tagIds = [];
                querySnapshot.forEach((doc) => {
                    tagIds.push(doc.data().tagId);
                });
                return tagIds;
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }


    const deleteUser = async (id, uid) => {
        if (window.confirm("¿Eliminar usuario?")) {

            alert("Eliminando usuario, por favor espere");
            await db.collection("usuarios").doc(id).delete().then(async () => {



                firebaseAdmin.deleteUser(uid).then(() => {
                    console.log("User deleted from firebase auth")
                })


                const tagsId = await getTagIdByUserId(uid)

                tagsId.forEach((tagId) => {
                    db.collection("tags").where("tagId", "==", tagId).get().then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            doc.ref.delete();
                        })
                    })

                    db.collection("pets").where("tagId", "==", tagId).get().then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            doc.ref.delete();
                        })
                    })
                })

                alert("Se ha eliminado el usuario y su información almacenada en la base de datos, también han sido eliminados sus tags")
            })
        } else {
            return false;
        }
    }


    const [currentUserAsociateId, setCurrentUserAsociateId] = useState("")
    const [currentSelectedOptions, setCurrentSelectedOptions] = useState("")
    const handleChange = (dataSelected, userId) => {
        setCurrentUserAsociateId(userId)
        setCurrentSelectedOptions(dataSelected)
    };


    const updatePetUserId = () => {
        if (window.confirm("¿Continuar?, esta acción no se puede deshacer")) {
            const collectionRef = db.collection("pets");

            currentSelectedOptions.forEach((item) => {
                collectionRef
                    .where("tagId", "==", item.label)
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            const docRef = collectionRef.doc(doc.id);
                            docRef.set({ userId: currentUserAsociateId }, { merge: true });
                        });
                    })
                    .then(() => {
                        setCurrentUserAsociateId("")
                        setCurrentSelectedOptions([])
                        alert("Tags asociados correctamente");
                    })
                    .catch((error) => {
                        console.error("Error al asociar los tags:", error);
                    });
            });
        }
    };


    const getTagsIds = () => {


        let localTagsIds = []
        db.collection('tags')
            .orderBy('isActive', 'desc')
            .orderBy("crated_at", "desc")
            .onSnapshot((querySnapshot) => {


                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });
                setTagsIds(docs)
                localTagsIds = docs
                const activeTagsCount = docs.filter(doc => doc.isActive === true).length;
                const inactiveTagsCount = docs.filter(doc => doc.isActive === false).length;
                setActiveTags(activeTagsCount);
                setInactiveTags(inactiveTagsCount);
                setIsLoading(false)

                const adaptedData = docs.map(item => ({
                    value: item.id,
                    label: item.tagId
                }));

                setOptionsTags(adaptedData);
            });




    }



    useEffect(() => {

        let adminIdAuth = sessionStorage.getItem('adminId')
        if (!adminIdAuth) {
            navigate('/ad/login')
        }


        getTagsIds()
        setLoading(true)
        const unsubscribe = db.collection('usuarios')
            .orderBy('userName', 'asc')
            .onSnapshot((querySnapshot) => {


                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });


                setIsLoading(false);
                setUsers(docs)
            });



        return () => unsubscribe(); // Detener la suscripción cuando el componente se desmonte

    }, [/* Dependencias, si las hay */]);
    return (
        <div className='container p-4'>


            <div className="my-4">
                <Link to="/ad/dashboard" className='text-decoration-none text-dark fs-5'>◄ Regresar</Link>
            </div>
            <div className="my-4">
                <span className='display-5'>Clientes</span>
            </div>

            <div>


                <h4>Listado de Clientes</h4>

                <div className='mt-4 d-flex justify-content-center'>
                    <div className='d-flex flex-column justify-content-center shadow w-80'>
                        <div className='my-4'>
                            <div className='p-2'>
                                <span className='text-dark fw-bold fs-4'>Usuarios</span>
                                <div className="d-flex">
                                    <div className="m-4">
                                        <span className='text-dark fw-bold fs-6'>Usuarios registrados: <span className='green rounded p-1'>{users.length}</span></span>
                                    </div>
                                </div>
                                <div className="container w-50 rounded border bg-light-gray">
                                    {


                                        isLoadingTags == true ?
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div class="spinner-border text-primary" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                            :
                                            tagsUsuario.length !== 0 ?


                                                tagsUsuario.map(pet => (
                                                    <ul>
                                                        <li>
                                                            {pet.name}: {pet.tagId}
                                                        </li>
                                                    </ul>


                                                ))
                                                :
                                                <div>Sin tags asociados</div>
                                    }
                                </div>
                                <div className='my-2'>
                                    <input placeholder="Buscar usuario" value={parametroUsuario} onChange={handleParametroUsuario} className="form-control inputBuscarPedido"></input>
                                </div>
                            </div>




                            {
                                isLoading == true ?
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :


                                    <div className="p-4 table-responsive table-container-auto w-100">


                                        <FilterResults
                                            value={parametroUsuario}
                                            data={users}
                                            renderResults={results => (


                                                <div className="table-responsive hideScrollBar d-flex justify-content-center">
                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th>Nombre de usuario</th>
                                                                <th>Correo</th>
                                                                <th>Tags asociados</th>
                                                                <th>Asociar tag</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {
                                                                results.map((usuario, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <span>
                                                                                {usuario.userName}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span>
                                                                                {usuario.userEmail}
                                                                            </span>
                                                                        </td>


                                                                        <td>
                                                                            <td><button className='btn btn-full-rounded btn-dark' onClick={() => getPetsByUserId(usuario.userId)}>Ver</button></td>
                                                                        </td>
                                                                        <td>
                                                                            <Select
                                                                                onChange={(e) => handleChange(e, usuario.userId)}
                                                                                options={optionsTags}
                                                                                isMulti={true}
                                                                                placeholder={"Seleccionar tagId"}
                                                                            />

                                                                            <div className="my-2">
                                                                                <button className='btn btn-success' onClick={() => updatePetUserId()}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" style={{ width: "20px", height: "20px" }}>
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <td><button className='btn btn-full-rounded btn-danger text-light' onClick={() => deleteUser(usuario.id, usuario.userId)}>Eliminar</button></td>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
