import React, { Component } from 'react';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import WebRoutes from './WebRoutes'


export default class App extends Component {
  render() {
    return (
      <PrimeReactProvider>
        <WebRoutes />
      </PrimeReactProvider>
    );
  }
}