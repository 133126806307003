import React, { useState } from 'react'
import app from '../../firebase';
const db = app.firestore();

export default function SendEmail() {

    const [state, setState] = useState({})
    const handleTextChange = (name, value) => {
        setState({ ...state, [name]: value })
    }

    const sendMail = () => {
        db.collection("mail")
            .add({
                to: state.to,
                message: {
                    subject: state.subject,
                    text: state.text,
                    html: `<p>${state.text}</p>`,
                },
            }).then(() => alert("Queued email for delivery!"));
    }

    return (
        <div>
            <div>
                <input type="text" onChange={(e) => handleTextChange("to", e.target.value,)} placeholder="Para" />
            </div>
            <div>
                <input type="text" onChange={(e) => handleTextChange("subject", e.target.value,)} placeholder="Asunto" />
            </div>
            <div>
                <input type="text" onChange={(e) => handleTextChange("text", e.target.value,)} placeholder="texto" />
            </div>
            <button onClick={() => sendMail()}>Enviar</button>
        </div>
    )
}
