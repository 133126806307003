import React, { useState, useEffect } from 'react';
import app from '../firebase';
import { DataTable, } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import 'primeicons/primeicons.css';
import { FilterMatchMode } from 'primereact/api';
const db = app.firestore();


export default function TableSort({ pets }) {


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });


    const [globalFilterValue, setGlobalFilterValue] = useState('');


    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };



    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar" />
            </div>
        );
    };


    const cancelDemoSubscription = async (tagId) => {

        if (window.confirm("Confirme que desea cancelar la suscripción")) {
            try {
                // Busca el documento en la colección pets con el tagId proporcionado
                const querySnapshot = await db.collection('pets').where('tagId', '==', tagId).get();

                // Verifica si se encontró algún documento
                if (querySnapshot.empty) {
                    console.error(`No se encontró ningún pet con el tagId: ${tagId}`);
                    return;
                }



                // Actualiza el campo 'subscription.subscriptionStatus' a 'FALSE'
                const petDoc = querySnapshot.docs[0];
                const petId = petDoc.id;

                await db.collection('pets').doc(petId).update({
                    plan: "Demo",
                    fechaFinSuscripcion: 0,
                    'subscription.subscriptionStatus': 'FALSE'
                });

                alert(`La suscripción del pet con tagId ${tagId} ha sido cancelada.`);
            } catch (error) {
                alert('Error al cancelar la suscripción:', error);
            }
        }

    };


    const buttonShowTagTemplate = (tagId) => (
        <a href={`/pet/${tagId}`} className='linkView'>Ver</a>
    )


    const buttonCancelTagTemplate = (tagId) => (
        <Button label="Cancelar" onClick={() => cancelDemoSubscription(tagId)} severity="danger" outlined />
    )

    const header = renderHeader();

    return (
        <div className="card">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Tag</th>
                        <th>Mascota</th>
                        <th>Email</th>
                        <th>Estado</th>
                        <th>Inicio</th>
                        <th>Vencimiento</th>
                        <th>Plan</th>
                        <th>Ver Tag</th>
                        <th>Cancelar</th>
                    </tr>
                </thead>
                <tbody>
                    {pets.map((rowData, index) => (
                        <tr key={index}>
                            <td>{rowData.tagId}</td>
                            <td>{rowData.petName}</td>
                            <td>{rowData.email}</td>
                            <td>{rowData?.subscription.subscriptionStatus}</td>
                            <td>{rowData?.fechaInicioSuscripcion || rowData.subscription.subscription_startTime?.split('T')[0]}</td>
                            <td>{rowData?.fechaFinSuscripcion || rowData.subscription.subscription_nextBillingTime?.split('T')[0]}</td>
                            <td>{rowData?.plan}</td>
                            <td>{buttonShowTagTemplate(rowData.tagId)}</td>
                            <td>{buttonCancelTagTemplate(rowData.tagId)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
