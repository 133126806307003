import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Timestamp } from 'firebase/firestore'
import 'firebase/compat/auth';
import 'firebase/compat/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const app = firebase.initializeApp({
    apiKey: "AIzaSyBKDj0aow6vWckcG1m6PRpXWYDKGfnjJ0o",
    authDomain: "idpets.firebaseapp.com",
    projectId: "idpets",
    storageBucket: "idpets.appspot.com",
    messagingSenderId: "651379911607",
    appId: "1:651379911607:web:bc63be8ec492cd1ea9d632",
    measurementId: "G-H21YCKE1WM"
})

export default app;