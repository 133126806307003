import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate, useParams, Link } from 'react-router-dom';

// Firebase
import app from '../../firebase';
import TableSearch from './tableTagDemos/Table';

export default function DemoTags() {

    return (
        <div className="container">
            <>
                <div className="my-4">
                    <span className='text-dark fw-bold display-6'>Lista de TagsDemo</span>
                </div>
                <div className="my-2">
                    <Link to="/ad/dashboard" className='text-decoration-underline text-dark fw-bold'>Regresar</Link>
                </div>
                <TableSearch />
            </>
        </div>
    )
}
