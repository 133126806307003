'use client';
import { useRef, useEffect, useState } from 'react'

const FadeInLeft = ({ children }) => {
    const mRef = useRef(null);
    const [inView, setInView] = useState(false)

    const handleIntersection = (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
            setInView(true);
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
        if (mRef.current) {
            observer.observe(mRef.current);
        }
        return () => {
            if (mRef.current) {
                observer.unobserve(mRef.current);
            }
        }
    }, []);

    return (
        <div ref={mRef} className={`wrapper wrapper-content animated ${inView ? 'fadeInLeft' : 'opacity-0'} transition-opacity duration-500`}>
            {children}
        </div>
    );
};

export default FadeInLeft;