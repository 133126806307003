/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';


import FilterResults from 'react-filter-search';

import * as firebaseAdmin from 'firebase/auth'
import Select from 'react-select';

import app from '../../firebase';
const db = app.firestore();




const DashboardAdmin = () => {
    let navigate = useNavigate();
    const [tagsIds, setTagsIds] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const closeSession = () => {
        sessionStorage.clear()
        navigate('/ad/login')
    }

    useEffect(() => {
        let adminIdAuth = sessionStorage.getItem('adminId')
        if (!adminIdAuth) {
            navigate('/ad/login')
        }


        const unsubscribe = db.collection('pruebasActivas')
            .orderBy('fechaInicioSuscripcion', 'asc')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });
                setTagsIds(docs)

                setIsLoading(false)
            });


        return () => unsubscribe(); // Detener la suscripción cuando el componente se desmonte

    }, [])

    return (






        <div>
            <div className="contianer-fluid">
                <div className='d-flex flex-column justify-content-center mt-4'>


                    <div className='my-4'>


                        <div className="p-4">
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <span className='text-dark fw-bold display-6'>Idpets QR | Administración</span>
                                </div>
                                <div>
                                    <button className='btn btn-danger btn-full-rounded' onClick={() => closeSession()}>Cerrar sesión</button>
                                </div>
                            </div>

                            <div className="shadow p-4 my-4">

                                <div className="row row-cols-3 d-flex justify-content-center my-4">
                                    <div className="col">
                                        <Link to='/ad/message_center' className='my-3 btn btn-primary btn-full-rounded w-100'>Centro de mensajes</Link>
                                    </div>
                                    <div className="col">
                                        <Link to={'/ad/add_tags'} className='my-3  btn btn-primary  btn-full-rounded w-100'>Crear tags por lote</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/ad/tags" className='my-3 btn btn-primary btn-full-rounded w-100'>Tags</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/ad/clients" className='my-3 btn btn-primary btn-full-rounded w-100'>Clientes</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/ad/productos" className='my-3 btn btn-primary btn-full-rounded w-100'>Productos</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/ad/demos" className='my-3 btn btn-primary btn-full-rounded w-100'>DemoTags</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/ad/emails" className='my-3 btn btn-primary btn-full-rounded w-100'>Emails</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/ad/email_marketing" className='my-3 btn btn-primary btn-full-rounded w-100'>Email Marketing</Link>
                                    </div>
                                    <div className="col">
                                        <Link to="/ad/petsConfig" className='my-3 btn btn-primary btn-full-rounded w-100'>Suscripciones Mascotas</Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="p-4">
                            <div>
                                <span className='text-dark fw-bold display-6'>Pruebas activadas IDPETS QR PRO</span>
                            </div>


                            <div className="shadow p-2">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Tag</th>
                                            <th>Mascota</th>
                                            <th>Email</th>
                                            <th>Inicio</th>
                                            <th>Vencimiento</th>
                                            <th>Plan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tagsIds.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>{rowData.tagId}</td>
                                                <td>{rowData.petName}</td>
                                                <td>{rowData.email}</td>
                                                <td>{rowData?.fechaInicioSuscripcion}</td>
                                                <td>{rowData?.fechaFinSuscripcion}</td>
                                                <td>{rowData?.plan}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default DashboardAdmin;
