/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';

import emailjs from 'emailjs-com'
import app from '../../firebase';
import FileUploader from './FileUploader';
const db = app.firestore();


const Signup = () => {

    const [fullName, setFullName] = useState('');
    const [mailUser, setMailUser] = useState('');
    const [mailUserConfirm, setMailUserConfirm] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');


    const handleSingup = async () => {

        if (mailUser == mailUserConfirm) {
            if (password == passwordConfirm) {

                await app.auth().createUserWithEmailAndPassword(mailUser, password).then(async (userCredential) => {
                    console.log(userCredential.user)

                    let user = {
                        "userId": userCredential.user.uid,
                        "userName": fullName,
                        "userEmail": mailUser,
                    }


                    await db.collection("usuarios").add(user).then(() => {
                        alert("Registrado correctamente")
                        emailjs.send("service_rmfh8ym", "template_0nh9sqv", {
                            fullName: fullName,
                            mailUser: mailUser,
                        }, 'Ubj86KjsQRN7_xxFz');


                        db.collection("mail")
                            .add({
                                to: mailUser,
                                message: {
                                    subject: `${fullName} Te damos la bienvenida a IDPETSQR`,
                                    text: "Bienvenido a IDPETSQR",
                                    html: `
                                <p>Nos da mucho gusto tenerte aquí ${fullName}</p>
                                <p>&nbsp;</p>
                                <p style="padding-left: 40px;">Para comenzar a cuidar de tu mascota, <a href="https://idpetsqr.com/login" rel="noopener noreferrer" target="_blank">Ingresa aquí</a></p>
                                <p style="padding-left: 40px;">¿Ya adquiriste tu plaquita inteligente? Si aún no lo haces, <a href="https://idpetshop.com/" rel="noopener noreferrer" target="_blank">Comprala aquí</a></p>
                                <p style="padding-left: 40px;">&nbsp;</p>
                                <p style="padding-left: 40px;">Con mucho cariño, por parte del equipo de IDPETSQR</p>
                                <center>
                                <p><img src="https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/archivos%2FWhatsApp%20Image%202022-04-25%20at%209.46.14%20PM.jpeg?alt=media&amp;token=31c371e6-db33-4dd6-8319-053bd391004c" alt="" width="358" height="76"></p>
                                </center>
                                `,
                                },
                            }).then(() => console.log("::ALERT SENT SUCCESSFULLY::"));
                        window.location.href = "/login"
                    })

                }).catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;

                    console.log("-> error code: " + errorCode)
                    if (errorCode == "auth/weak-password") {
                        alert("La contraseña debe tener al menos 6 caracteres ");
                    }
                });
            } else {
                alert("Las contraseñas no coinciden")
            }
        } else {
            alert("Los correos no coinciden")
        }
    }


    return (


        <>
            <div className='row' id='row-pc'>

                <div className="col col-6 background-signup"></div>
                <div className="col col-6">

                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='m-4'>
                            <h2>Registro de Usuarios</h2>
                            <div className='mt-4'>
                                <div className='form-group'>
                                    <label className='mt-3'>Nombre completo</label>
                                    <input type="text" className="form-control mb-2 mt-2" onChange={(e) => setFullName(e.target.value)} placeholder='Ej. Esmeralda G' ></input>
                                </div>

                                <div className='form-group'>
                                    <label className='mt-3'>Correo electrónico</label>
                                    <input type="email" className="form-control mb-2 mt-2" onChange={(e) => setMailUser(e.target.value)} placeholder='mail@example.com'></input>
                                </div>
                                <div className='form-group'>
                                    <label className='mt-3'>Confirmar correo</label>
                                    <input type="email" className="form-control mb-2 mt-2" onChange={(e) => setMailUserConfirm(e.target.value)} placeholder='mail@example.com'></input>
                                </div>
                                <div className='form-group'>
                                    <label className='mt-3'>Contraseña (Mínmo 6 caracteres)</label>
                                    <input type="password" className="form-control mb-2 mt-2" onChange={(e) => setPassword(e.target.value)} placeholder='mail@example.com'></input>
                                </div>
                                <div className='form-group'>
                                    <label className='mt-3'>Vuelve a escribir tu contraseña (Mínmo 6 caracteres)</label>
                                    <input type="password" className="form-control mb-2 mt-2" onChange={(e) => setPasswordConfirm(e.target.value)} placeholder='mail@example.com'></input>
                                </div>

                                <div>
                                    <span style={{ fontSize: "10px" }}>Al registrarte, estás aceptando nuestros <a href="https://idpetsqr.com/terminos" target={"_blank"}>términos y condiciones</a></span>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                            <button onClick={() => handleSingup()} style={{ width: "300px", background: "#DA985F", color: "#fff" }} className="btn btn-main-rose mt-4">Registrarse</button>
                            <Link to={"/login"} className="linkView mt-2">Volver al inicio</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row' id='row-mb'>
                <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                    <div className='m-4'>
                        <h2 className='fw-bold'>Registro de Usuarios</h2>
                        <div className='mt-4'>
                            <div className='form-group'>
                                <label className='mt-3'>Nombre completo</label>
                                <input type="text" className="form-control mb-2 mt-2" onChange={(e) => setFullName(e.target.value)} placeholder='Ej. Esmeralda G' ></input>
                            </div>

                            <div className='form-group'>
                                <label className='mt-3'>Correo electrónico</label>
                                <input type="email" className="form-control mb-2 mt-2" onChange={(e) => setMailUser(e.target.value)} placeholder='mail@example.com'></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Confirmar correo</label>
                                <input type="email" className="form-control mb-2 mt-2" onChange={(e) => setMailUserConfirm(e.target.value)} placeholder='mail@example.com'></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Contraseña (Mínmo 6 caracteres)</label>
                                <input type="password" className="form-control mb-2 mt-2" onChange={(e) => setPassword(e.target.value)} placeholder='mail@example.com'></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Vuelve a escribir tu contraseña (Mínmo 6 caracteres)</label>
                                <input type="password" className="form-control mb-2 mt-2" onChange={(e) => setPasswordConfirm(e.target.value)} placeholder='mail@example.com'></input>
                            </div>

                            <div>
                                <span style={{ fontSize: "10px" }}>Al registrarte, estás aceptando nuestros <a href="https://idpetsqr.com/terminos" target={"_blank"}>términos y condiciones</a></span>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-row flex-column container-group mb-3'>
                        <button onClick={() => handleSingup()} className="btn btn-warning fw-bold my-4">Registrarse</button>
                        <div className="text-center">
                            <Link to={"/login"} className="linkView mt-2">Volver al inicio</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );



}


export default Signup;