/**React imports */
import React, { useEffect, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
const db = app.firestore();


const AlertConfirmSavedPet = () => {

    const [pet, setPet] = useState('');

    const recoverData = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj);

        setPet(pet_obj);
    }

    useEffect(() => {
        recoverData();
    }, [])
    return (


        <>
            <div className='new-tag-bg container-fluid minHeight-100 justify-content-center d-flex align-items-center'>
                <div className='card h-50 shadow p-4'>
                    <div className='d-flex flex-column justify-content-center mt-4'>
                        <div style={{ backgroundImage: `url(${pet.petImageLink})`, backgroundSize: "cover", backgroundPosition: "center" }} className="pet-circle-image m-4"></div>

                        <div className='container'>

                            <div className='row row-cols-1'>
                                <div className='col'>
                                    <p className="subtitulo">
                                        ¡Felicidades el tag de <strong>{pet.petName}</strong> ya se encuentra activo, termina de configurar su perfil!
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>

                            <Link to={`/cl/pet_details/${pet.tagId}`} onClick={() => localStorage.clear()} className="btn btn-warning my-4 btn-full-rounded">Continuar</Link>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );



}


export default AlertConfirmSavedPet;