/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { useSearchParams, useNavigate, useParams, Link } from 'react-router-dom';
/**Css styles */
import '../Navigation.css';
import emailjs from 'emailjs-com'

/**Transitions */
import { Circles } from 'react-loader-spinner';

import app from '../firebase';
import WaFloatButton from './WAFloatButton';

const db = app.firestore();


const Demo = () => {

    const [mensajeRecompenza, setMensajeRecompenza] = useState('')
    return (


        <div className='bg-yellow-gradient contianer-fluid p-4'>
            <WaFloatButton></WaFloatButton>
            {/* Presentación */}
            <div className="p-2">
                <div className='d-flex justify-content-center m-4'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/Idpets-demo-image.jpeg?alt=media&token=41bdd502-7c9c-4205-9a42-c961ba29d86a' className="pet-circle-image" alt="" />
                </div>
                <div className='text-center'>
                    <span className='fs-2 text-light fw-bold'>Hola, soy  Firulais</span>
                </div>

                <div className='shadow p-4 my-3 bg-light'>Si no me conoces y estoy en la calle, es porque me perdí. Ayúdame a regresar a mi casa</div>
                <div className='shadow p-4 my-3 bg-light'>
                    <div><p><span className='fw-bold fs-4'>Generales</span></p></div>


                    <div className='my-2'>
                        <span> <strong>F. Nacimiento:</strong> 01/01/2019</span>
                    </div>
                    <div className='my-2'>
                        <span><strong>Tamaño:</strong> Mediano</span>
                    </div>
                    <div className='my-2'>
                        <span><strong>Raza:</strong> Mestizo</span>
                    </div>
                    <div className='my-2'>
                        <span><strong>Castrado:</strong> No</span>
                    </div>
                    <div className='my-2'>
                        <span><strong>Género:</strong> Hembra</span>
                    </div>
                    <div className='my-2'>
                        <span><strong>Color:</strong> Blanco</span>
                    </div>


                </div>
                <div className='shadow p-4 my-3 bg-light'>
                    <div><span className='fw-bold fs-4'>Contacto: Juan Pedro</span></div>


                    <div className='my-2'>
                        <span><strong>Teléfono:</strong> 123456789</span>
                    </div>
                    <div className='my-2'>
                        <span><strong>Tel. Alternativo</strong> 987654321</span>
                    </div>
                    <div className='my-2'>
                        <span><strong>Correo:</strong> juan@micorreo.com</span>
                    </div>
                    <div className='my-2'>
                        <span><strong>Dirección:</strong> Alta monte, centro, Ciudad de México</span>
                    </div>


                </div>
                <div className='shadow p-4 my-3 bg-light'>
                    <div>
                        <span className='fw-bold fs-4'>Medicamentos:</span>
                    </div>

                    <div>
                        <span className='my-2'><strong>Medicación:</strong> MediDoc</span>
                        <div>
                            <strong>Propósito:</strong> Fiebre
                        </div>
                        <span className='my-2'><strong>Duración:</strong> 1 semana</span>
                        <span className='my-2'><strong>Fecha de Inicio:</strong> 04-05-2022</span>
                        <span><strong>Frecuencia Diaria:</strong> 1</span>
                        <span className='my-2'><strong>Notas:</strong> Cuando presente sequedad en nariz</span>
                        <span className='my-2'><strong>Alergias: </strong> A las Abeja</span>
                        <span className='my-2'><strong>Clínica:</strong> Doc. Dog</span>
                        <span className='my-2'><strong>Llamar a: </strong> Dr. Pedro</span>
                        <span className='my-2'><strong>Notas de Alergia:</strong> Bañar con agua fría</span>
                    </div>

                </div>
            </div>

            <div className="shadow p-4 bg-dark text-center">
                <div className='my-2'>
                    <span className='fw-bold text-light fs-4'>Recompensa inmediata</span>
                </div>
                <div className='my-2'>
                    <span className='fw-bold text-warning display-1'>MXN$5000</span>
                </div>
            </div>


            {/* Contacto */}
            <div className='shadow p-4 my-3 bg-light'>

                <span className='fw-bold fs-4 my-2'>Avisar al dueño</span>
                <div className='my-2'>
                    <span>Mensaje al dueño</span>
                </div>

                <div>
                    <textarea className='form-control' cols="30" rows="3" placeholder='Ej. Estoy frente al parque' onChange={(e) => setMensajeRecompenza(e.target.value)}></textarea>
                </div>
                <div className='d-flex justify-content-center'>
                    <a href={`https://api.whatsapp.com/send?phone=525584627932&text=INFORMACION DE Firulais ${mensajeRecompenza} UBICACION: ${`https://www.google.com/maps/place/`}`} className='btn button-card-info-pet shadow'>Avisar</a>
                </div>
                <i>Para informar al dueño necesitamos saber dónde estás con Firulais</i>

            </div>

            <div style={{ height: "20px" }}></div>

        </div>
    );



}


export default Demo;